/*========================= section-changepassword */

.section-changepassword {
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-changepassword .main-form {
    width: 600px;
    padding: 100px 0px;
}

.section-changepassword .main-form .title {
    text-align: center;
}

.section-changepassword .main-form .info-area {
    padding: 20px 25px;
    background-color: #eee;
    color: #000;
    margin-bottom: 75px;
    border-radius: 5px;
}

.section-changepassword .main-form .info-area span {
    display: block;
    padding: 5px 0px;
}

.section-changepassword .main-form .title p {
    color: #999;
    font-weight: 600;
    font-size: 15px;
}

.section-changepassword .main-form .inputs {
    position: relative;
}

.section-changepassword .main-form .inputs .label-with-info {
    display: flex;
    justify-content: start;
    align-items: baseline;
}

.section-changepassword .main-form .inputs .password-info {
    margin-left: 8px;
    cursor: help;
    color: #bbb;
    font-size: 19px;
    transition: .4s;
}

.section-changepassword .main-form .inputs .password-info:hover {
    color: #777;
}

.section-changepassword .main-form .inputs .password-info-area {
    position: absolute;
    animation: open-animation 0.5s;
    background-color: #eee;
    color: #444;
    left: 151px;
    bottom: 43px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px #00000047;
    padding: 10px;
    padding-bottom: 5px;
    z-index: 99;
}

.section-changepassword .main-form .inputs .password-info-area .close {
    position: absolute;
    right: -6px;
    top: -6px;
    color: #777;
    cursor: pointer;
    transition: .4s;
}

.section-changepassword .main-form .inputs .password-info-area .close:hover {
    color: #555;
}

.section-changepassword .main-form .inputs .password-info-area div {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 5px;
    font-size: 14px;
}

.section-changepassword .main-form .inputs .password-info-area div i {
    margin-right: 5px;
}

.section-changepassword .main-form .inputs .password-info-area div i.danger {
    color: var(--global-danger-color);
}

.section-changepassword .main-form .inputs .password-info-area div i.success {
    color: var(--global-success-color);
}

.section-changepassword .main-form .inputs .password-show-hide {
    position: absolute;
    right: 0px;
    bottom: 55px;
    padding-right: 4px;
    cursor: pointer;
    color: #777;
    font-size: 14px;
}

.section-changepassword .main-form .inputs .password-show-hide.first {
    bottom: 166px;
}

.section-changepassword .main-form .inputs .password-show-hide.old {
    bottom: 277px;
}

.section-changepassword .main-form .inputs .password-show-hide i {
    margin-right: 6px;
    font-size: 13px;
}

.section-changepassword .main-form .inputs:not(.verification) {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.section-changepassword .main-form .inputs.verification {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-top: 50px;
    padding-right: 3px;
}

.section-changepassword .main-form .inputs.verification input {
    width: 75px;
    height: 75px;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: #eee;
    outline: none;
    text-align: center;
    font-size: 24px;
}

.section-changepassword .main-form .inputs label {
    color: #777;
    font-weight: bold;
    margin-top: 30px;
    padding-left: 1px;
    padding-bottom: 6px;
}

.section-changepassword .main-form .inputs label:first-child {
    margin-top: 0px;
}

.section-changepassword .main-form .inputs.new-password label {
    margin-top: 30px;
}

.section-changepassword .main-form .inputs:not(.verification) input {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: #eee;
    outline: none;
    transition: .4s;
}

.section-changepassword .main-form .inputs:not(.verification) input.danger {
    box-shadow: 0px 0px 5px var(--global-danger-color);
}

.section-changepassword .main-form button {
    width: 100%;
    margin-top: 50px;
    padding: 12px;
    border-radius: 5px;
    border: 0px;
    background-color: #343434;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: .4s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-changepassword .main-form button:hover {
    background-color: #484848;
}

.section-changepassword .main-form button div div {
    height: 27px !important;
    width: 50px !important;
    display: flex;
    align-items: center;
}

.section-changepassword .main-form button.verification {
    max-width: 575px;
}

.section-changepassword .main-form .register {
    margin-top: 15px;
    font-size: 14px;
}

.section-changepassword .main-form .register label {
    color: #888;
    font-weight: bold;
}

.section-changepassword .main-form .register a {
    text-decoration: none;
    margin-left: 6px;
    color: #4c4c4c;
    font-weight: bold;
    cursor: pointer;
}

/*========================= koyu tema düzenlemeleri */

body.dark .section-changepassword .main-form .title p, body.dark .section-changepassword .main-form .register label {
    color: #888;
}

body.dark .section-changepassword .main-form .info-area {
    background-color: #292929;
    color: #eee;
}

body.dark .section-changepassword .main-form .inputs label, body.dark .section-changepassword .main-form .inputs .password-show-hide, body.dark .section-changepassword .main-form .register a {
    color: #aaa;
}

body.dark .section-changepassword .main-form .inputs input{
    background-color: #333;
    color: #ddd;
}

/* Webkit tarayıcıları için (örn. Safari ve Chrome) */
body.dark .section-changepassword .main-form .inputs input::placeholder { 
    color: #888; 
    opacity: 1; /* Safari'de opacity varsayılan olarak 0.54'tür, bu nedenle bunu eklemekte fayda var */
}

/* Mozilla Firefox için */
body.dark .section-changepassword .main-form .inputs input::-moz-placeholder {
    color: #888;
    opacity: 1;
}

/* Microsoft Edge ve Internet Explorer 10+ için */
body.dark .section-changepassword .main-form .inputs input::-ms-input-placeholder {
    color: #888;
}

/* Opera 19+ için */
body.dark .section-changepassword .main-form .inputs input:-ms-input-placeholder {
    color: #888;
}

body.dark .section-changepassword .main-form button {
    color: #ddd;
}

body.dark .section-changepassword .main-form button:hover {
    background-color: #414141;
}

body.dark .section-changepassword .inputs .password-info-area {
    background-color: #444;
    box-shadow: 0px 0px 10px #ffffff1c;
    color: #ccc;
}

body.dark .section-changepassword .inputs .password-info-area .close {
    color: #888;
}

body.dark .section-changepassword .inputs .password-info-area .close:hover {
    color: #aaa;
}

/*========================= çözünürlüğe bağlı olan düzenlemeler */

@media screen and (max-width: 900px){
    .section-changepassword .main-form {
        width: 100%;
        padding: 100px;
    }
    .section-changepassword .main-form .inputs.verification input {
        width: 60px;
        height: 60px;
        font-size: 22px;
    }
    .section-changepassword .main-form .title {
        text-align: left;
    }
    .section-changepassword .main-form .title p{
        font-size: 13px;
    }
    .section-changepassword .main-form .info-area span {
        font-size: 14px;
    }
}

@media screen and (max-width: 750px){
    .section-changepassword .main-form .inputs .password-info-area {
        bottom: 72px;
    }
    .section-changepassword .main-form .inputs .password-info-area .close {
        font-size: 14px;
    }
    .section-changepassword .main-form .inputs .password-info-area div {
        font-size: 10px;
    }
}

@media screen and (max-width: 700px){
    .section-changepassword .main-form .inputs.verification input {
        width: 50px;
        height: 50px;
        font-size: 20px;
    }
}

@media screen and (max-width: 600px){
    .section-changepassword .main-form {
        padding: 100px 50px;
    }
    .section-changepassword .main-form .inputs.verification input {
        width: calc(100% / 7);
        height: 45px;
        font-size: 18px;
    }
}

@media screen and (max-width: 500px){
    .section-changepassword .main-form {
        padding: 50px;
    }
    .section-changepassword .main-form .inputs.new-password label {
        font-size: 13px;
    }
    .section-changepassword .main-form .inputs:not(.verification) input {
        font-size: 13px;
        padding: 8px 10px;
    }
    .section-changepassword .main-form .inputs .password-info {
        font-size: 14px;
        margin-left: 5px;
    }
    .section-changepassword .main-form .inputs .password-info-area {
        left: 118px;
        bottom: 53px;
    }
    .section-changepassword .main-form button{
        font-size: 14px;
    }
    .section-changepassword .main-form .inputs .password-show-hide, .section-changepassword .main-form .inputs .password-show-hide i {
        margin-right: 5px;
        font-size: 11px;
    }
    .section-changepassword .main-form .inputs .password-show-hide {
        bottom: 40px;
        margin-right: 1px;
    }
    .section-changepassword .main-form .inputs .password-show-hide.old {
        bottom: 225px;
        margin-right: 1px;
    }
    .section-changepassword .main-form .inputs .password-show-hide.first {
        bottom: 132px;
        margin-right: 1px;
    }
}

@media screen and (max-width: 450px){
    .section-changepassword .main-form .inputs .password-info-area {
        left: 3px;
        bottom: 21px;
    }
}

@media screen and (max-width: 400px){
    .section-changepassword .main-form {
        padding: 40px 25px;
    }
    .section-changepassword .main-form .title p {
        font-size: 11px;
    }
    .section-changepassword .main-form .info-area span, .section-changepassword .main-form button {
        font-size: 12px;
    }
}
