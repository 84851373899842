/*========================= section-tvinfo */

.section-tvinfo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px;
    color: #333;
}

.section-tvinfo label{
    margin-bottom: 80px;
    font-size: 20px;
}

.section-tvinfo label:nth-child(2) {
    margin-bottom: 30px;
    font-size: 18px;
}

.section-tvinfo label a {
    color: inherit;
    font-weight: bold;
    transition: .5s;
}

.section-tvinfo label a:hover {
    color: #555;
}

.section-tvinfo a.more {
    background-color: #333;
    color: var(--global-yellow-color);
    text-decoration: none;
    padding: 12px 40px;
    border-radius: 6px;
    font-size: 18px;
    font-weight: bold;
    transition: .4s;
}

.section-tvinfo a.more:hover {
    background-color: #444;
}

/*========================= koyu tema düzenlemeleri */

body.dark .section-tvinfo {
    color: #ddd;
}

body.dark .section-tvinfo label a:hover {
    color: #aaa;
}

/*========================= çözünürlüğe bağlı olan düzenlemeler */

@media screen and (max-width: 500px){
    .section-tvinfo {
        padding: 30px;
    }
    .section-tvinfo label{
        font-size: 16px;
    }
    .section-tvinfo label:nth-child(2), .section-tvinfo a.more {
        font-size: 14px;
    }
}
