/*========================= pages-company */

.pages-company {
    display: flex;
    justify-content: center;
    align-items: normal;
    flex-direction: column;
    padding: 50px;
    color: #333;
}

.pages-company .title {
    width: 100%;
    text-align: left;
    font-weight: bold;
    font-size: 20px;
    margin-top: 50px;
    margin-bottom: 20px;
}

.pages-company .title:first-child {
    margin-top: 0px;
}

.pages-company .title.one-title {
    margin-bottom: 50px;
}

.pages-company .title.subtitle {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.pages-company.no-text-subtitle .title.subtitle {
    margin-right: 10px;
}

.pages-company .text {
    margin-bottom: 20px;
    font-size: 16px;
    width: 100%;
    text-align: left;
    margin-left: 1px;
}

.pages-company .text:last-child, .pages-company .text.inner-text {
    margin-bottom: 0px;
}

.pages-company .text i {
    margin-right: 8px;
    font-size: 10px;
    vertical-align: text-top;
    padding-top: 8px;
    opacity: .6;
}

.pages-company label a {
    color: inherit;
    font-weight: bold;
    transition: .5s;
}

.pages-company label a:hover {
    color: #555;
}

.pages-company .button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pages-company .button .join {
    background-color: #333;
    color: var(--global-yellow-color);
    padding: 12px 40px;
    border-radius: 6px;
    font-size: 18px;
    font-weight: bold;
    transition: .4s;
    cursor: pointer;
    border: 0px;
    min-width: 255px;
}

.pages-company .button .join:hover {
    background-color: #444;
}

.pages-company .button .join:disabled:hover {
    background-color: #333;
}

.pages-company .button .join:disabled {
    opacity: .7;
    cursor: default;
}

.pages-company .button .join.not-authenticated {
    color: #fff;
    margin-top: 50px;
}

.pages-company .button .join a {
    color: var(--global-yellow-color);
    text-decoration: none;
}

.pages-company .button .join a:first-child {
    margin-right: 6px;
}

.pages-company .button .join a:last-child {
    margin-left: 6px;
}

.pages-company .button .join .loading div div {
    height: 27px !important;
    width: 50px !important;
    display: flex;
    align-items: center;
}

.pages-company .contract {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    margin-top: 75px;
    margin-bottom: 30px;
}

.pages-company .contract label {
    cursor: pointer;
    display: flex;
    justify-content: start;
    align-items: center;
    position: relative;
}

.pages-company .contract label:first-child {
    margin-bottom: 10px;
}

.pages-company .contract input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
}

.pages-company .contract .checkmark {
    height: 20px;
    width: 22px;
    background-color: #ddd;
    display: inline-block;
    margin-right: 8px;
    transition: .4s;
    border-radius: 5px;
}

.pages-company .contract input:checked + .checkmark {
    background-color: #777;
}

.pages-company .contract .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.pages-company .contract input:checked + .checkmark:after {
    display: block;
}
  
.pages-company .contract .checkmark:after {
    left: 7px;
    top: 4px;
    width: 4px;
    height: 11px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(36deg);
    border-radius: 1px;
}

.pages-company .contract .text a {
    font-weight: bold;
    color: #000;
}

.pages-company .message-field {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;
}

.pages-company .message-field label {
    font-weight: bold;
    text-align: center;
}

.pages-company .message-field .success {
    color: var(--global-success-color);
}

.pages-company .message-field .danger {
    color: var(--global-danger-color);
}

.pages-company table {
    margin-top: 50px;
    margin-bottom: 70px;
    border: 2px solid #76767647;
    border-radius: 10px;
    padding: 0px;
}

.pages-company table td {
    text-align: center;
    padding: 10px;
    border-bottom: 2px solid #76767647;
}

.pages-company table tr:last-child td {
    border-bottom: 0px;
}

.pages-company table tr:not(.main-title) td {
    border-right: 2px solid #76767647;
}

.pages-company table tr:not(.main-title) td:last-child {
    border-right: 0px;
}

.pages-company table .main-title td {
    font-weight: bold;
    font-size: 20px;
}

.pages-company table .title td {
    font-weight: bold;
    font-size: 16px;
}

.pages-company table tr:not(.main-title, .title) td {
    font-size: 16px;
}

.pages-company .bonus-step {
    display: flex;
    justify-content: start;
    align-items: start;
    margin-bottom: 45px;
}

.pages-company .title.subtitle.bonus-step-title {
    margin-top: 40px;
}

.pages-company .bonus-step .item {
    width: 30%;
}

.pages-company .bonus-step .item:first-child {
    margin-right: 30px;
}

.pages-company .bonus-step .item-border {
    border: 2px solid #aaa;
    padding: 25px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.pages-company .bonus-step .item-border span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    font-weight: bold;
    background-color: #eee;
    width: 55px;
    height: 55px;
    border-radius: 50px;
}

.pages-company .bonus-step .item-border i {
    font-size: 44px;
    color: #f6c016;
}

.pages-company .bonus-step .item-info {
    padding-left: 2px;
    padding-top: 5px;
    margin-top: 8px;
}

/*========================= koyu tema düzenlemeleri */

body.dark .pages-company {
    color: #ddd;
}

body.dark .pages-company label a:hover {
    color: #aaa;
}

body.dark .pages-company .text {
    color: #ccc;
}

body.dark .pages-company .contract .checkmark {
    background-color: #777;
}

body.dark .pages-company .contract input:checked + .checkmark {
    background-color: #444;
}

body.dark .pages-company .contract .checkmark:after {
    border-color: #ccc;
}

body.dark .pages-company .contract .text a {
    color: #eee;
}

body.dark .pages-company .bonus-step .item-border {
    border-color: #555;
}

body.dark .pages-company .bonus-step .item-border span {
    background-color: #2b2b2b;
}

/*========================= çözünürlüğe bağlı olan düzenlemeler */

@media screen and (max-width: 1500px){
    .pages-company .bonus-step .item {
        width: 40%;
    }
}


@media screen and (max-width: 1300px){
    .pages-company .bonus-step {
        justify-content: space-between;
    }
    .pages-company .bonus-step .item, .pages-company .bonus-step .item:first-child {
        width: 49%;
        margin: 0px;
    }
}

@media screen and (max-width: 800px){
    .pages-company table td {
        padding: 10px 5px;
    }
    .pages-company table .main-title td {
        font-size: 16px;
    }
    .pages-company table .title td {
        font-size: 14px;
    }
    .pages-company table tr:not(.main-title, .title) td {
        font-size: 14px;
    }
}

@media screen and (max-width: 750px){
    .pages-company .bonus-step .item-border {
        justify-content: space-between;
    }
}

@media screen and (max-width: 500px){
    .pages-company {
        padding: 30px;
    }
    .pages-company .title {
        font-size: 16px;
    }
    .pages-company .title.subtitle {
        font-size: 14px;
    }
    .pages-company .text, .pages-company .message-field label {
        font-size: 12px;
    }
    .pages-company .text i {
        margin-right: 5px;
        font-size: 6px;
        padding-top: 0px;
        vertical-align: middle;
    }
    .pages-company .contract .checkmark {
        height: 16px;
        width: 18px;
        margin-right: 5px;
    }
    .pages-company .contract .checkmark:after {
        left: 5px;
        top: 3px;
        width: 3px;
        height: 8px;
    }
    .pages-company .button .join {
        font-size: 12px;
        padding: 8px 40px;
        min-width: 200px;
    }
    .pages-company .button .join .loading div div {
        height: 18px !important;
    }
    .pages-company table .main-title td {
        font-size: 14px;
    }
    .pages-company table .title td {
        font-size: 10px;
    }
    .pages-company table tr:not(.main-title, .title) td {
        font-size: 10px;
    }
    .pages-company .bonus-step .item-border span {
        font-size: 16px;
        width: 30px;
        height: 30px;
    }
    .pages-company .bonus-step .item-border i {
        font-size: 28px;
    }
}

@media screen and (max-width: 400px){
    .pages-company table td {
        padding: 6px 2px;
    }
    .pages-company table .main-title td {
        font-size: 12px;
    }
    .pages-company table .title td {
        font-size: 8px;
    }
    .pages-company table tr:not(.main-title, .title) td {
        font-size: 8px;
    }
}

@media screen and (max-width: 350px){
    .pages-company .contract .checkmark {
        height: 14px;
        width: 16px;
        margin-right: 3px;
    }
    .pages-company .contract .checkmark:after {
        left: 5px;
        top: 2px;
        width: 2px;
        height: 7px;
    }
    .pages-company .contract .text {
        font-size: 10px;
    }
    .pages-company .bonus-step .item-border span {
        font-size: 12px;
        width: 25px;
        height: 25px;
    }
    .pages-company .bonus-step .item-border i {
        font-size: 22px;
    }
    .pages-company .bonus-step .item-border {
        border: 2px solid #aaa;
        padding: 25px 10px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}

@media screen and (max-width: 290px){
    .pages-company .contract .checkmark:after {
        top: 10px;
    }
}
