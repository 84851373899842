/*========================= section-withdraw */

.section-withdraw {
    padding: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

.section-withdraw i.fa-solid.fa-caret-up {
    padding-top: 5px;
}

.withdraw-form-element {
    display: flex;
    align-items: start;
    justify-content: start;
    margin-top: 40px;
}

.withdraw-form-element:first-child {
    margin-top: 0px;
}

.withdraw-form-element-title {
    width: 215px;
    font-weight: 500;
    font-size: 20px;
    color: #555;
}

.withdraw-form-element-main {
    width: 600px;
    position: relative;
}

.withdraw-form-element-main-title {
    font-size: 18px;
    font-weight: 500;
    color: #777;
    margin-top: 35px;
    margin-bottom: 5px;
}

.withdraw-form-element-main .withdraw-form-element-main-title:first-child {
    margin-top: 0px;
}

.withdraw-form-element-select {
    width: 100%;
    border: 2px solid #ddd;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;
    min-height: 50px;
}

.withdraw-form-element-select .section-withdraw-loading {
    width: 100%;
    height: 27px;
}

.withdraw-form-element-select .section-withdraw-loading div div {
    height: 0px !important;
    width: 50px !important;
}

.withdraw-form-element-select-options .items {
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding: 0px;
    max-height: 236px;
    overflow: auto;
}

.withdraw-form-element-select-options .search-area {
    border: 2px solid #ddd;
    border-radius: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.withdraw-form-element-select-options input.search {
    padding-left: 45px;
    padding-top: 11px;
    border: 0px;
}

.withdraw-form-element-select-options .quick-search {
    margin-top: 4px;
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.withdraw-form-element-select-options .quick-search .item {
    background-color: #ddd;
    width: 18%;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 0px;
    transition: .4s;
}

.withdraw-form-element-select-options .quick-search .item.active, .withdraw-form-element-select-options .quick-search .item:hover {
    background-color: #ccc;
}

.withdraw-form-element-select-options .quick-search .item img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.withdraw-form-element-select-options .quick-search .item span {
    font-weight: 500;
    font-size: 14px;
    color: #444444;
}

.withdraw-form-element-select-options i {
    position: absolute;
    left: 30px;
    top: 30px;
    font-size: 18px;
}

.withdraw-form-element-select div, .withdraw-form-element-select-options .items .item {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.withdraw-form-element-select div img, .withdraw-form-element-select-options .items .item img {
    width: 25px;
    height: 25px;
}

.withdraw-form-element-select div span, .withdraw-form-element-select-options .items .item span {
    font-size: 18px;
    font-weight: 500;
    margin: 0px 10px;
}

.withdraw-form-element-select.noimg div span {
    margin-left: 0px;
}

.withdraw-form-element-select div label, .withdraw-form-element-select-options .items .item label {
    font-size: 18px;
    font-weight: 500;
    color: #777;
    cursor: pointer;
}

.withdraw-form-element-select .placeholder {
    color: #777;
}

.withdraw-form-element-select-options {
    position: absolute;
    padding: 15px;
    box-shadow: 0px 0px 15px #aaaaaaa3;
    width: calc(100% - 3px);
    border-radius: 5px;
    margin-left: 2px;
    margin-top: 10px;
    animation-duration: .5s;
    animation-name: open-animation;
    background-color: #fff;
    z-index: 1;
    max-height: 350px;
}

.withdraw-form-element-select-options .items .item {
    padding: 15px 10px;
}

.withdraw-form-element-select-options .items .item span, .withdraw-form-element-select-options .items .item label{
    transition: .4s;
}

.withdraw-form-element-select-options .items .item:hover span, .withdraw-form-element-select-options .items .item.active span {
    color: #ce812b;
}

.withdraw-form-element-select-options .items .item:hover label, .withdraw-form-element-select-options .items .item.active label {
    color: #e7a458;
}

.withdraw-form-element-main input {
    width: 100%;
    border: 2px solid #ddd;
    border-radius: 5px;
    padding: 10px 15px;
    padding-right: 45px;
    outline: none;
    transition: .4s;
}

.withdraw-form-element-main input.danger {
    border-color: #ffafaf;
    box-shadow: 0px 0px 10px #ffc2c2;
}

.withdraw-form-element-main input.amount{
    padding-right: 60px;
}

.withdraw-form-element-main .amount-max-button {
    position: absolute;
    right: 14px;
    margin-top: -36px;
    font-weight: 500;
    color: #777;
    transition: .4s;
    cursor: pointer;
}

.withdraw-form-element-main .amount-max-button.active, .withdraw-form-element-main .amount-max-button:hover{
    color: #e7a458;
}

.withdraw-form-element-main .address-icon {
    position: absolute;
    font-size: 20px;
    margin-left: -35px;
    margin-top: 15px;
}

.section-withdraw-dialog {
    width: 600px;
}

.section-withdraw-dialog.p-dialog .p-dialog-header {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}

.section-withdraw-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
    padding: 0px;
    overflow: hidden;
}

.section-withdraw-dialog.transfer .p-dialog-content:last-of-type {
    padding: 0px;
}

.section-withdraw-dialog .p-dialog-header .p-dialog-header-icon:focus{
    box-shadow: none !important;
}

.section-withdraw-dialog-body {
    max-height: 585px;
    padding: 10px 25px;
    padding-bottom: 25px;
    overflow: auto;
}

.section-withdraw-dialog .info {
    background-color: #ddd;
    padding: 20px;
    border-radius: 5px;
    font-size: 17px;
    margin-bottom: 30px;
}

.section-withdraw-dialog .element {
    margin-top: 20px;
    padding: 5px;
    border: 2px solid transparent;
    border-radius: 5px;
    transition: .4s;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section-withdraw-dialog .element.active, .section-withdraw-dialog .element:hover {
    background-color: #eee;
    box-shadow: 0px 0px 5px #bbb;
}

.section-withdraw-dialog .elements .element:first-child {
    margin-top: 0px;
}

.section-withdraw-dialog .element label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: start;
}

.section-withdraw-dialog .element label p {
    margin: 0px;
    font-weight: 500;
    margin: 0px 5px;
    color: #555;
}

.section-withdraw-dialog .element-right label:last-child p:last-child{
    margin-right: 0px;
}

.section-withdraw-dialog .element-left {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
}

.section-withdraw-dialog .element-left span {
    font-weight: bold;
}

.section-withdraw-dialog .element-left span:last-child {
    font-weight: 500;
    color: #555;
}

.section-withdraw-dialog .element-right {
    display: flex;
    align-items: end;
    justify-content: center;
    flex-direction: column;
}

.section-withdraw-dialog-footer {
    height: 75px;
    position: relative;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0px 25px;
}

.section-withdraw-dialog-footer button {
    background-color: transparent;
    border: 0px;
    text-decoration: underline;
    color: #555;
    cursor: pointer;
    transition: .4s;
}

.section-withdraw-dialog-footer button:hover {
    color: #e7a458;
}

.withdraw-form-element-result {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5px;
}

.withdraw-form-element-result.net-amount {
    justify-content: center;
}

.withdraw-form-element-result .result-left, .withdraw-form-element-result .result-right {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    font-weight: 500;
}

.withdraw-form-element-result.net-amount .result-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 500;
}

.withdraw-form-element-result label {
    color: #777;
    margin-top: 25px;
}

.withdraw-form-element-result span {
    font-size: 18px;
    color: #555;
}

.withdraw-form-element-result span.fee {
    display: flex;
    justify-content: start;
    align-items: center;
}

.withdraw-form-element-result span.fee p {
    margin: 0px 5px;
}

.withdraw-form-element-result span.fee p:first-child {
    margin: 0px;
    margin-right: 5px;
}

.withdraw-form-element-amount-error {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    color: var(--global-danger-color);
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    animation: open-animation 1s;
}

.withdraw-form-element-button {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.withdraw-form-element-button button {
    height: 47px;
    width: 250px;
    border: 0px;
    background-color: #ddd;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 500;
    color: #aaa;
    cursor: default;
    transition: .4s;
}

.withdraw-form-element-button button.active {
    background-color: var(--global-success-color);
    color: #fff;
    cursor: pointer;
}

.withdraw-form-element-button button.active:hover {
    background-color: var(--global-success-hover-color);
}

.withdraw-form-element-button button div div {
    position: absolute;
    width: 85px !important;
    height: 85px !important;
    fill: #aaa !important;
}

.withdraw-form-element-button button.active div div {
    fill: #fff !important;
}

.section-withdraw.requestInfo {
    padding: 0px 50px;
}

.section-withdraw .request-info {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    width: 650px;
    padding: 25px 10px;
    margin: 25px 0px;
    box-shadow: 0px 0px 10px #00000025;
    border-radius: 12px;
}

.section-withdraw .request-info .info-area {
    padding: 0px 20px;
    background-color: transparent;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.section-withdraw .request-info .info-area .timer-date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.section-withdraw .request-info .info-area .timer {
    font-weight: bold;
    font-size: 22px;
    color: var(--global-danger-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-withdraw .request-info .info-area .timer i {
    margin-right: 15px;
}

.section-withdraw .request-info .info-area .date {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    color: #555;
}

.section-withdraw .request-info .info-area .info {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.section-withdraw .request-info .info-area .price {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}

.section-withdraw .request-info .info-area .price div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.section-withdraw .request-info .info-area .price div:first-child {
    margin-bottom: 10px;
}

.section-withdraw .request-info .info-area .price label {
    font-weight: 600;
    color: #555;
    font-size: 16px;
}

.section-withdraw .request-info .info-area .price span {
    font-weight: 500;
    color: #333;
    font-size: 20px;
}

.section-withdraw .request-info .info-area .network-address {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
    padding: 15px 25px;
    background-color: #ddd;
    border-radius: 10px;
}

.section-withdraw .request-info .info-area .network-address label {
    font-weight: 600;
    font-size: 22px;
    text-align: center;
}

.section-withdraw .request-info .info-area .network-address span {
    font-weight: 400;
    font-size: 18px;
}

.section-withdraw .request-info .verification-area {
    padding: 10px 20px;
    width: 100%;
}

.section-withdraw .request-info .verification-area label {
    font-weight: 600;
    font-size: 20px;
}

.section-withdraw .request-info .verification-area .inputs {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.section-withdraw .request-info .verification-area .inputs.verification {
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: row;
    margin: 10px 0px;
}

.section-withdraw .request-info .verification-area .inputs.verification input {
    width: 75px;
    height: 75px;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: #eee;
    outline: none;
    text-align: center;
    font-size: 24px;
}

.section-withdraw .request-info .verification-area .register {
    font-size: 14px;
    display: inline-flex;
    justify-content: start;
    align-items: center;
    width: fit-content;
    border: 2px solid #86868675;
    border-radius: 5px;
    padding: 3px 8px;
    transition: .4s;
    cursor: pointer;
}

.section-withdraw .request-info .verification-area .register.locked {
    width: 125px;
    padding-right: 0px;
    cursor: default;
}

.section-withdraw .request-info .verification-area .register:not(.locked):hover {
    background-color: #86868626;
}

.section-withdraw .request-info .verification-area .register a {
    text-decoration: none;
    color: #4c4c4c;
    font-weight: 600;
}

.section-withdraw .request-info .verification-area .register.locked a {
    opacity: .7;
}

.section-withdraw .request-info .verification-area .message-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section-withdraw .request-info .verification-area .message-area .info {
    display: inline-flex;
    align-items: center;
    justify-content: end;
    font-size: 14px;
    font-weight: 500;
    color: #555;
}

.section-withdraw .request-info .verification-area .message-area .info i {
    margin-right: 6px;
    padding-top: 3px;
}

.section-withdraw .request-info .verification-area .timer {
    color: var(--global-success-color);
    font-weight: 600;
    margin-left: 6px;
}

.section-withdraw .request-info .withdraw-form-element-button {
    padding-top: 35px;
    justify-content: center;
    width: 100%;
    margin: 0px;
}

/*========================= koyu tema düzenlemeleri */

body.dark .section-withdraw .request-info {
    box-shadow: 0px 0px 10px #00000060;
}

body.dark .section-withdraw-dialog .info{
    background-color: #444;
    color: #eee;
}

body.dark .section-withdraw-dialog .element.active, body.dark .section-withdraw-dialog .element:hover {
    background-color: #444;
    box-shadow: 0px 0px 5px #ffffff42;
}

body.dark .section-withdraw-dialog .element-left span:first-child{
    color: #eee;
}

body.dark .section-withdraw-dialog .element-left span:last-child{
    color: #ccc;
}

body.dark .section-withdraw-dialog .element label{
    color: #ddd;
}

body.dark .section-withdraw-dialog .element label p{
    color: #eee;
}

body.dark .withdraw-form-element-select, body.dark .withdraw-form-element-main input, body.dark .withdraw-form-element-select-options .search-area {
    border-color: #3f3f3f;
    background-color: #333;
    color: #eee;
}

body.dark .withdraw-form-element-select-options i {
    color: #999;
}

body.dark .withdraw-form-element-select-options .quick-search .item {
    background-color: #555;
}

body.dark .withdraw-form-element-select-options .quick-search .item.active, body.dark .withdraw-form-element-select-options .quick-search .item:hover {
    background-color: #666;
}

body.dark .withdraw-form-element-select-options .quick-search .item span {
    color: #ddd;
}

body.dark .withdraw-form-element-main input.danger {
    border-color: #ff7070;
    box-shadow: 0px 0px 5px #ff7070;
}

body.dark .withdraw-form-element-title{
    color: #ddd;
    border-color: #555;
}

body.dark .withdraw-form-element-main-title{
    color: #ccc;
}

body.dark .withdraw-form-element-select div label, body.dark .withdraw-form-element-select-options .items .item label{
    color: #bbb;
}

body.dark .withdraw-form-element-select-options{
    background-color: #333;
    box-shadow: 0px 0px 10px #ffffff1a;
}

body.dark .withdraw-form-element-select-options .items .item:hover label, body.dark .withdraw-form-element-select-options .items .item.active label{
    color: #e7a458;
}

body.dark .withdraw-form-element-result label{
    color: #999;
}

body.dark .withdraw-form-element-result span{
    color: #ccc;
}

body.dark .withdraw-form-element-button button {
    background-color: #444;
    color: #888;
}

body.dark .withdraw-form-element-button button.active {
    background-color: var(--global-success-color);
    color: #eee;
}

body.dark .withdraw-form-element-button button.active:hover {
    background-color: var(--global-success-hover-color);
}

body.dark .section-withdraw-dialog-footer {
    background-color: #333;
}

body.dark .section-withdraw-dialog-footer button{
    color: #ddd;
}

body.dark .section-withdraw-dialog-footer button:hover {
    color: #e7a458;
}

body.dark .section-withdraw .request-info .info-area .date {
    color: #aaa;
}

body.dark .section-withdraw .request-info .info-area .price label {
    color: #aaa;
}

body.dark .section-withdraw .request-info .info-area .price span {
    color: #eee;
}

body.dark .section-withdraw .request-info .info-area .network-address {
    background-color: #333;
}

body.dark .section-withdraw .request-info .info-area .network-address span {
    color: #bbb;
}

body.dark .section-withdraw .request-info .verification-area label {
    color: #ccc;
}

body.dark .section-withdraw .request-info .verification-area .inputs.verification input {
    background-color: #333;
    color: #ddd;
}

body.dark .section-withdraw .request-info .verification-area .register a {
    color: #aaa;
}

body.dark .section-withdraw .request-info .verification-area .message-area .info {
    color: #bbb;
}

/*========================= çözünürlüğe bağlı olan düzenlemeler */

@media screen and (max-width: 1375px) and (min-width: 1200px){
    .section-withdraw:not(.requestInfo) {
        display: block;
    }
}

@media screen and (max-width: 1050px){
    .withdraw-form, .withdraw-form-element-main{
        width: 100%;
    }
}

@media screen and (max-width: 1000px){
    .withdraw-form-element-result, .withdraw-form-element-result.net-amount .result-center {
        flex-direction: column;
        align-items: start;
    }
    .withdraw-form-element-amount-error br {
        display: none;
    }
    .withdraw-form-element-select-options .quick-search .item img {
        width: 10px;
        height: 10px;
        margin-right: 3px;
    }
    .withdraw-form-element-select-options .quick-search .item span {
        font-size: 10px;
    }
}

@media screen and (max-width: 800px){
    .section-withdraw .request-info .verification-area .message-area .info {
        font-size: 12px;
    }
    .section-withdraw .request-info .verification-area .message-area .info i {
        padding-top: 1px;
    }
}

@media screen and (max-width: 750px){
    .section-withdraw{
        padding: 50px;
    }
    .withdraw-form-element {
        flex-direction: column;
        margin-top: 60px;
    }
    .withdraw-form-element-main-title {
        font-size: 14px;
    }
    .withdraw-form-element-select-options {
        padding: 10px;
    }
    .withdraw-form-element-select {
        font-size: 12px;
    }
    .withdraw-form-element-select div img, .withdraw-form-element-select-options .items .item img{
        width: 15px;
        height: 15px;
    }
    .withdraw-form-element-main input, .withdraw-form-element-select div span, .withdraw-form-element-select-options .items .item span, .withdraw-form-element-select div label, .withdraw-form-element-select-options .items .item label{
        font-size: 12px;
    }
    .withdraw-form-element-main .address-icon{
        font-size: 14px;
        margin-top: 14px;
        margin-left: -28px;
    }
    .withdraw-form-element-main input{
        padding-right: 35px;
    }
    .withdraw-form-element-main .amount-max-button {
        margin-top: -30px;
        font-size: 12px;
    }
    .section-withdraw-dialog{
        width: 95%;
    }
    .section-withdraw-dialog-body {
        max-height: 400px;
    }
    .section-withdraw-dialog .info {
        font-size: 12px;
        padding: 10px;
        margin-bottom: 15px;
    }
    .section-withdraw-dialog .element {
        margin-top: 10px;
    }
    .section-withdraw-dialog .elements .element:first-child {
        margin-top: 0px;
    }
    .withdraw-form-element-result label {
        font-size: 12px;
    }
    .withdraw-form-element-result span {
        font-size: 14px;
    }
    .withdraw-form-element-button button {
        font-size: 14px;
    }
    .withdraw-form-element-button button div div {
        position: absolute;
        width: 40px !important;
        height: 40px !important;
    }
    .withdraw-form-element-select-options .quick-search .item img {
        width: 8px;
        height: 8px;
        margin-right: 2px;
    }
    .withdraw-form-element-select-options .quick-search .item span {
        font-size: 8px;
    }
    .section-withdraw.requestInfo {
        padding: 0px;
    }
    .section-withdraw .request-info {
        width: 100%;
        margin: 0px;
        padding: 0px;
    }
    .section-withdraw .request-info .info-area {
        padding: 15px 30px;
        padding-bottom: 0px;
    }
    .section-withdraw .request-info .info-area .timer {
        font-size: 16px;
    }
    .section-withdraw .request-info .info-area .timer i {
        margin-right: 10px;
    }
    .section-withdraw .request-info .info-area .date {
        font-size: 12px;
        margin: 10px 0px;
    }
    .section-withdraw .request-info .info-area .info {
        font-size: 12px;
    }
    .section-withdraw .request-info .info-area .info label {
        margin-top: 15px;
    }
    .section-withdraw .request-info .info-area .price {
        font-size: 12px;
    }
    .section-withdraw .request-info .info-area .price label {
        font-size: 12px;
    }
    .section-withdraw .request-info .info-area .price span {
        font-size: 16px;
    }
    .section-withdraw .request-info .info-area .network-address {
        padding: 10px;
    }
    .section-withdraw .request-info .info-area .network-address label {
        font-size: 14px;
    }
    .section-withdraw .request-info .info-area .network-address span {
        font-size: 10px;
    }
    .section-withdraw .request-info .verification-area {
        padding: 10px 35px;
    }
    .section-withdraw .request-info .verification-area label {
        font-size: 16px;
    }
    .section-withdraw .request-info .verification-area .register {
        font-size: 10px;
    }
    .section-withdraw .request-info .verification-area .register.locked {
        width: 100px;
    }
    .section-withdraw .request-info .withdraw-form-element-button {
        padding-top: 15px;
        margin-bottom: 25px;
    }
    .section-withdraw .request-info .verification-area .message-area .info {
        font-size: 10px;
    }
    .section-withdraw .request-info .verification-area .message-area .info i {
        margin-right: 5px;
    }
    .section-withdraw .request-info .verification-area .inputs.verification input {
        width: 60px;
        height: 60px;
        font-size: 20px;
    }
    .withdraw-form-element-select-options input.search {
        padding-left: 40px;
    }
    .withdraw-form-element-select-options i {
        left: 25px;
        top: 25px;
        font-size: 12px;
    }
}

@media screen and (max-width: 700px){
    .section-withdraw-dialog .element {
        flex-direction: column;
        align-items: start;
    }
    .section-withdraw-dialog .element-right {
        align-items: start;
    }
    .withdraw-form-element-amount-error{
        font-size: 12px;
    }
    .section-withdraw .request-info .verification-area .message-area {
        justify-content: start;
        flex-direction: column;
        align-items: start;
    }
    .section-withdraw .request-info .verification-area .message-area .info {
        margin-left: 2px;
        margin-top: 5px;
    }
}

@media screen and (max-width: 650px){
    .section-withdraw .request-info .verification-area .inputs.verification {
        margin: 5px 0px;
    }
    .section-withdraw .request-info .verification-area .inputs.verification input {
        width: 50px;
        height: 50px;
        font-size: 18px;
    }
    .section-withdraw .request-info .verification-area .message-area .info {
        margin-top: 2px;
    }
}

@media screen and (max-width: 600px){
    .section-withdraw-dialog-body{
        padding: 10px 15px;
        padding-bottom: 15px;
    }
    .section-withdraw-dialog .info{
        font-size: 14px;
    }
    .section-withdraw-dialog .element{
        font-size: 14px;
    }
    .section-withdraw{
        padding: 40px;
    }
    .withdraw-form-element-select div img, .withdraw-form-element-select-options .items .item img{
        width: 20px;
        height: 20px;
    }
    .withdraw-form-element-title{
        font-size: 18px;
    }
    .withdraw-form-element-main-title{
        font-size: 16px;
    }
    .withdraw-form-element-main input, .withdraw-form-element-select div span, .withdraw-form-element-select-options .items .item span, .withdraw-form-element-select div label, .withdraw-form-element-select-options .items .item label{
        font-size: 14px;
    }
    .withdraw-form-element-main .address-icon{
        font-size: 16px;
    }
    .withdraw-form-element-result label {
        font-size: 14px;
    }
    .withdraw-form-element-result span {
        font-size: 16px;
    }
    .withdraw-form-element-main .amount-max-button {
        margin-top: -34px;
        font-size: 14px;
    }
}

@media screen and (max-width: 500px){
    .section-withdraw{
        padding: 30px;
    }
    .withdraw-form-element-amount-error{
        font-size: 10px;
    }
}

@media screen and (max-width: 450px){
    .section-withdraw .request-info .verification-area .inputs.verification input {
        width: 40px;
        height: 40px;
        font-size: 14px;
    }
}

@media screen and (max-width: 400px){
    .section-withdraw-dialog-body {
        max-height: 300px;
    }
    .section-withdraw .request-info .info-area {
        padding: 15px 20px;
        padding-bottom: 0px;
    }
    .section-withdraw .request-info .verification-area {
        padding: 10px 25px;
    }
    .section-withdraw .request-info .verification-area label {
        font-size: 14px;
    }
    .section-withdraw .request-info .verification-area .inputs.verification input {
        width: 30px;
        height: 30px;
        font-size: 12px;
    }
    .section-withdraw .request-info .withdraw-form-element-button {
        padding: 0px 20px;
        padding-top: 15px;
        margin-bottom: 20px;
    }
    .withdraw-form-element-button button {
        width: 100%;
    }
}
