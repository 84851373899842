/*========================= primereact plugin for confirm dialog */
@import '~primereact/resources/themes/saga-blue/theme.css';
@import '~primereact/resources/primereact.min.css';
@import '~primeicons/primeicons.css';

.p-dialog-mask {
    z-index: 99999999 !important;
}

.p-dialog-mask .p-dialog {
    border-radius: 12px;
}

.trade-sections .section-tradeorderhistory-list .p-dialog-footer{
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
}

.p-dialog-mask .p-dialog .p-dialog-footer .p-confirm-dialog-reject {
    color: var(--global-danger-color);
    font-weight: 600;
}

.p-dialog-mask .p-dialog .p-dialog-footer .p-confirm-dialog-reject:hover {
    border-color: var(--global-danger-color);
}

.p-dialog-mask .p-dialog .p-dialog-footer .p-confirm-dialog-reject:focus {
    border-color: var(--global-danger-color);
    box-shadow: 0px 0px 5px var(--global-danger-color);
}

.p-dialog-mask .p-dialog .p-dialog-footer .p-confirm-dialog-accept {
    background: var(--global-danger-color);
    border-color: transparent;
    font-weight: 600;
}

.p-dialog-mask .p-dialog .p-dialog-footer .p-confirm-dialog-accept:hover {
    background: var(--global-danger-hover-color);
}

.p-dialog-mask .p-dialog .p-dialog-footer .p-confirm-dialog-accept:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #f7a2a2, 0 1px 2px 0 rgb(0, 0, 0);
}

body.dark .p-dialog-mask .p-dialog .p-dialog-footer .p-confirm-dialog-accept:focus {
    box-shadow: 0 0 0 2px var(--global-danger-hover-color), 0 0 0 4px #f7a2a2, 0 1px 2px 0 rgb(0, 0, 0);
}

.future-confirm-dialog .p-confirm-dialog-message {
    width: 480px;
    padding-right: 30px;
}

/*========================= PAGE - graph */

.graph-section {
    height: 100vh;
}

.graph-section .resolution-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--global-dark2-color);
    color: #ddd;
    padding: 5px;
}

.graph-section .resolution-options .options {
    display: flex;
    justify-content: start;
    align-items: center;
}

.graph-section .resolution-options .item {
    width: 25px;
    cursor: pointer;
    font-weight: bold;
    font-size: 9px;
    text-align: center;
    transition: .4s;
}

.graph-section .resolution-options .item.submenu-arrow.active {
    width: 30px;
}

.graph-section .resolution-options .item.submenu-arrow label {
    margin-right: 5px;
}

.graph-section .resolution-options .item.submenu-arrow i {
    vertical-align: text-top;
    transition: 0.4s;
}

.graph-section .resolution-options .item.submenu-arrow i.rotate {
    transform: rotate(180deg);
    vertical-align: bottom;
}

.graph-section .resolution-options .options .submenu-options {
    position: absolute;
    animation: open-animation 1s;
    top: 30px;
    left: 105px;
    display: flex;
    z-index: 999;
    background-color: var(--global-dark2-color);
    padding: 8px 5px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px #414141;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.graph-section .resolution-options .options .submenu-options .area {
    display: flex;
    justify-content: start;
    align-items: center;
}

.graph-section .resolution-options .options .submenu-options .area:nth-child(2) {
    margin: 5px 0px;
}

.graph-section .resolution-options .options .submenu-options .item {
    width: 30px;
}

.graph-section .resolution-options .full-size {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .4s;
    cursor: pointer;
    font-size: 8px;
}

.graph-section .resolution-options .item:hover, .graph-section .resolution-options .item.active, .graph-section .resolution-options .full-size:hover {
    color: var(--global-yellow-color);
}

.graph-section.mode1 .TVChartContainer iframe {
    height: calc(100vh - 30px) !important;
}

.graph-section-dark .graph-section {
    background-color: var(--global-dark2-color);
}

.graph-section-light .graph-section {
    background-color: #fafafa;
}

.graph-section-light .graph-section .resolution-options {
    background-color: #fff;
}

.graph-section-light .graph-section .resolution-options .item, .graph-section-light .graph-section .resolution-options .full-size {
    color: #999;
}

.graph-section-light .graph-section .resolution-options .item:hover, .graph-section-light .graph-section .resolution-options .item.active, .graph-section-light .graph-section .resolution-options .full-size:hover {
    color: #000;
}

.graph-section-light .graph-section .resolution-options .options .submenu-options {
    background-color: #ffffff;
    box-shadow: 0px 0px 5px #cccccc;
}

/*========================= PAGE - trade */

.trade-sections-top {
    display: flex;
    justify-content: space-between;
}

.trade-sections-top1 {
    width: 80%;
    margin-right: 8px;
}

.trade-sections-top1 .bottom {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
}

.trade-sections-top2 {
    width: 20%;
}

.trade-sections-bottom {
    display: flex;
    justify-content: space-between;
}

.trade-sections .back-to {
    padding-left: 17px;
    margin-top: 47px;
    display: flex;
    justify-content: start;
    align-items: center;
    text-decoration: none;
    color: #555;
    font-size: 20px;
    font-weight: 600;
    transition: .4s;
    position: absolute;
    z-index: 999;
}

.trade-sections .back-to:hover {
    color: inherit !important;
}

.trade-sections .back-to span {
    margin-left: 3px;
    padding-bottom: 1px;
}

.trade-sections .section-tradeorderhistory{
    min-height: 1000px;
    max-height: 1000px;
}

.trade-sections .section-tradecryptocurrencylist {
    height: 1000px !important;
}

.trade-sections .section-tradecryptocurrencylist .section-tradecryptocurrencylist-table {
    height: 864px;
}

.trade-sections .section-tradecryptocurrencylist .section-tradecryptocurrencylist-table .MuiTableContainer-root {
    height: 864px;
}

/*========================= section-tradecryptocurrencylist */

.section-tradecryptocurrencylist {
    height: 1438px;
    overflow: hidden !important;
    font-family: 'Manrope';
}

.future-sections .section-tradecryptocurrencylist {
    height: 1475px;
}

.future-sections .section-tradecryptocurrencylist .section-tradecryptocurrencylist-table .MuiTableContainer-root {
    height: 1339px;
}

.section-tradecryptocurrencylist-dialog{
    width: 600px;
}

.section-tradecryptocurrencylist-dialog i.fa-solid.fa-caret-up {
    padding-top: 5px;
}

.section-tradecryptocurrencylist-dialog .p-dialog-header {
    border-top-right-radius: 12px !important;
    border-top-left-radius: 12px !important;
}

.section-tradecryptocurrencylist-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
}

.section-tradecryptocurrencylist-dialog.transfer .p-dialog-content:last-of-type {
    padding: 0px;
}

.section-tradecryptocurrencylist-dialog .p-dialog-header .p-dialog-header-icon:focus{
    box-shadow: none !important;
}

.section-tradecryptocurrencylist-dialog label {
    font-weight: 600;
    font-size: 16px;
}

.section-tradecryptocurrencylist-dialog .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}

.section-tradecryptocurrencylist-dialog .buttons a {
    height: 44px;
    box-shadow: none !important;
    background-color: transparent;
    color: #333;
    border: 2px solid #777;
    z-index: 1;
    position: relative;
    letter-spacing: normal;
    font-size: 18px;
    font-weight: 600;
    font-family: inherit !important;
    padding: 0px;
    border-radius: 6px;
    width: 49%;
    cursor: pointer;
    text-decoration: none;
    transition: .5s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-tradecryptocurrencylist-dialog .buttons a:hover {
    background-color: #77777725;
}

.section-tradecryptocurrencylist-search {
    display: flex;
    align-items: center;
    padding: 20px;
}

.section-tradecryptocurrencylist-search i {
    position: absolute;
    margin-left: 10px;
}

.section-tradecryptocurrencylist-search input {
    height: 40px;
    width: 100%;
    border-radius: 12px;
    border: 1px solid #ccc;
    padding-left: 35px;
    outline: none;
    font-size: 16px;
}

.section-tradecryptocurrencylist-options {
    background-color: #00000012;
    padding: 15px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section-tradecryptocurrencylist-options .active {
    color: #000;
    font-weight: bold;
}

.section-tradecryptocurrencylist-options i, .section-tradecryptocurrencylist-options span {
    color: #444;
    cursor: pointer;
    transition: .5s;
    font-size: 12px;
}

.section-tradecryptocurrencylist-options i:hover, .section-tradecryptocurrencylist-options span:hover{
    color: #000;
    font-weight: bold;
}

.section-tradecryptocurrencylist-options i {
    color: #888;
}

.section-tradecryptocurrencylist .MuiPaper-root {
    border: 0px;
    box-shadow: none;
    background-color: transparent;
}

.section-tradecryptocurrencylist .MuiToolbar-root, .section-tradecryptocurrencylist .MuiIconButton-root {
    display: none;
}

.section-tradecryptocurrencylist-table {
    animation-duration: .5s;
    height: 1303px;
}

.section-tradecryptocurrencylist .section-tradecryptocurrencylist-table .MuiTableContainer-root {
    max-height: max-content;
    height: 1303px;
}

.section-tradecryptocurrencylist-table thead tr {
    box-shadow: none;
}

.section-tradecryptocurrencylist .Mui-TableHeadCell-Content-Wrapper {
    font-weight: 600;
    font-size: 11px;
    color: #333;
    font-family: 'Manrope';
}

.section-tradecryptocurrencylist .MuiTableBody-root tr:last-child td {
    border-bottom: 0;
}

.section-tradecryptocurrencylist table td {
    padding: 0px;
}

.section-tradecryptocurrencylist .section-tradecryptocurrencylist-element {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 16px;
    width: 100%;
}

.section-tradecryptocurrencylist table td:first-child .section-tradecryptocurrencylist-element {
    padding: 0px;
    padding-left: 16px;
}

.section-tradecryptocurrencylist-element i {
    color: #ccc;
    font-size: 10px;
    margin-right: 2px;
    cursor: pointer;
    transition: .4s;
}

.section-tradecryptocurrencylist-element i.active, .section-tradecryptocurrencylist-element i:hover {
    color: #000;
}

.section-tradecryptocurrencylist-element a, a.section-tradecryptocurrencylist-element {
    text-decoration: none;
    font-family: 'Manrope';
    color: #777;
    font-weight: 600;
    width: 100%;
    font-size: 10px;
    padding-left: 3px;
}

.section-tradecryptocurrencylist table td:first-child .section-tradecryptocurrencylist-element a {
    display: flex;
    justify-content: start;
    align-items: center;
    height: 47px;
    padding-top: 2px;
    margin-top: -2px;
}

.section-tradecryptocurrencylist-element.price.success{
    animation: fadeInSuccess 4s ease-in-out;
}

.section-tradecryptocurrencylist-element.price.danger{
    animation: fadeInDanger 4s ease-in-out;
}

.section-tradecryptocurrencylist-element .success{
    color: var(--global-success-color);
}

.section-tradecryptocurrencylist-element .danger{
    color: var(--global-danger-color);
}

/*========================= section-mainchartohlc */

.section-mainchartohlc{
    display: none;
    justify-content: start;
    align-items: center;
    position: absolute;
    z-index: 9;
}

.section-mainchartohlc i{
    cursor: pointer;
}

.section-mainchartohlc-element {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 10px;
    font-size: 12px;
    width: 100%;
    animation-duration: 1s;
    animation-name: open-animation;
}

.section-mainchartohlc-element1, .section-mainchartohlc-element2 {
    display: flex;
}

.section-mainchartohlc-element .section-mainchartohlc-element-div {
    margin-right: 8px;
}

.section-mainchartohlc-element .section-mainchartohlc-element1 div:first-child{
    margin: 0px;
    width: 108px;
}

.section-mainchartohlc-element .section-mainchartohlc-element-div.success {
    color: var(--global-success-color);
}

.section-mainchartohlc-element .section-mainchartohlc-element-div.danger {
    color: var(--global-danger-color);
}

.section-mainchartohlc-element .section-mainchartohlc-element1 div:first-child.success, .section-mainchartohlc-element .section-mainchartohlc-element1 div:first-child.danger{
    color: #555;
}

.section-mainchartohlc-element .section-mainchartohlc-element-div label {
    margin-right: 2px;
    font-weight: bold;
    color: #555;
}

.section-mainchartohlc-element .section-mainchartohlc-element-div span {
    font-weight: bold;
}

#mainchartohlc-element-change{
    margin-right: 8px;
}

/*========================= section-mainchart */

.section-mainchart {
    height: 682px;
    overflow: hidden !important;
}

.section-maincharttop-top {
    padding: 10px 20px;
    display: flex;
    justify-content: start;
    align-items: center;
    border-bottom: 1px solid #ddd;
}

.future-sections .section-maincharttop-top {
    padding: 10px;
}

.section-maincharttop-top-title {
    font-size: 22px;
    font-weight: bold;
    padding: 20px 0px;
    padding-right: 40px;
    border-right: 1px solid #ccc;
    margin-right: 40px;
}

.future-sections .section-maincharttop-top-title {
    padding: 20px 30px;
    margin-right: 30px;
}

.section-maincharttop-top-title span {
    transition: .4s;
    cursor: pointer;
}

.section-maincharttop-top-title span:hover {
    opacity: .8;
}

.section-maincharttop-top-title .star-icon {
    position: absolute;
    right: 0px;
    top: 18px;
    font-size: 16px;
    color: #ccc;
    transition: .4s;
    cursor: pointer;
}

.section-maincharttop-top-title .star-icon:hover{
    color: #000;
}

.section-maincharttop-top-title .star-icon.active{
    color: #000;
}

.section-maincharttop-top-data {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
}

.section-maincharttop-top-data label {
    font-size: 22px;
    font-weight: bold;
    width: 20%;
}

.section-maincharttop-top-data label.success{
    color: var(--global-success-color);
}

.section-maincharttop-top-data label.danger{
    color: var(--global-danger-color);
}

.section-maincharttop-top-data div {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    width: 20%;
}

.section-maincharttop-top-data div span:first-child {
    font-size: 11px;
    font-weight: bold;
    color: #555;
    letter-spacing: 1px;
}

.section-maincharttop-top-data div span:last-child {
    display: flex;
    justify-content: start;
    width: 100%;
    align-items: center;
}

.section-maincharttop-top-data div span:last-child p {
    margin: 0px;
}

.section-maincharttop-top-data div span:last-child p:first-child {
    margin-right: 10px;
}

.section-maincharttop-top-data div span:last-child p.success {
    color: var(--global-success-color);
}

.section-maincharttop-top-data div span:last-child p.danger {
    color: var(--global-danger-color);
}

.section-maincharttop-bottom {
    padding: 12px 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
}

.section-maincharttop-bottom button {
    width: calc(100% / 12);
    margin-right: 15px;
    cursor: pointer;
    padding: 5px 0px;
    border: 1px solid transparent;
    border-radius: 6px;
    background-color: transparent;
    font-size: 15px;
    font-weight: 600;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    color: #444;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.section-maincharttop-bottom button:last-child{
    margin-right: 0px;
}

.section-maincharttop-bottom button:hover{
    background: #2121210a;
    color: #333;
}

.section-maincharttop-bottom button.active{
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #777, 0 1px 2px 0 #fff;
    background: #2121210a;
    color: #333;
}

.section-mainchartbottom {
    height: 586px;
}

.section-mainchartbottom .TVChartContainer {
    height: 586px !important;
    width: calc(100% + 20px);
}

/*========================= section-tradebuysell */

.section-tradebuysell {
    padding: 20px;
    height: 748px;
    overflow: hidden !important;
    width: calc(35% - 8px);
}

.future-sections .section-tradebuysell {
    height: 785px;
}

.section-tradebuysell-options {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px;
}

.section-tradebuysell-options span {
    color: #333;
    margin-right: 50px;
    cursor: pointer;
}

.section-tradebuysell-options span.active, .section-tradebuysell-options span:hover {
    color: #000;
    font-weight: bold;
}

.section-tradebuysell-tab-area {
    animation: open-animation 1s;
}

.section-tradebuysell-tab-area .buy-sell-tab-main-area {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
}

.section-tradebuysell-tab-area .buy-sell-tab-area {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 47px;
    background-color: #eee;
    position: relative;
    margin-bottom: 20px;
    border-radius: 5px;
}
  
.section-tradebuysell-tab-area .buy-sell-tab-area .buy, .section-tradebuysell-tab-area .buy-sell-tab-area .sell {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    border-radius: 5px;
}
  
.section-tradebuysell-tab-area .buy-sell-tab-area .buy.active {
    clip-path: polygon(0 0, 91% 0, 100% 50%, 91% 100%, 0 100%);
    background-color: var(--global-success-color);
    z-index: 1;
    color: #eee;
    width: 55%;
    padding-right: 15px;
}
  
.section-tradebuysell-tab-area .buy-sell-tab-area .sell.active {
    clip-path: polygon(9% 0, 100% 0, 100% 100%, 9% 100%, 0% 50%);
    background-color: var(--global-danger-color);
    z-index: 1;
    color: #eee;
    width: 55%;
    padding-left: 15px;
}

.section-tradebuysell-tab-area .buy-sell-tab-area .buy:not(.active):hover {
    color: var(--global-success-color);
    transition: .4s;
}

.section-tradebuysell-tab-area .buy-sell-tab-area .sell:not(.active):hover {
    color: var(--global-danger-color);
    transition: .4s;
}

.section-tradebuysell-inner {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    position: relative;
    animation: open-animation 1s;
}

.future-sections .section-tradebuysell-inner {
    width: 100%;
}

.section-tradebuysell-inner-mainsegment {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    min-height: 595px;
}

.future-sections .section-tradebuysell-inner-mainsegment {
    min-height: 690px;
}

.section-tradebuysell-inner-input {
    margin-bottom: 15px;
    display: flex;
    justify-content: end;
    align-items: center;
}

.section-tradebuysell-inner-input input {
    width: 100%;
    outline: none;
    padding: 10px 0px;
    padding-left: 110px;
    padding-right: 85px;
    border: 2px solid transparent;
    border-radius: 5px;
    transition: .4s;
    text-align: right;
    background-color: #eeeeee;
}

.section-tradebuysell-inner-input input:not(.locked, .danger):hover, .section-tradebuysell-inner-input input:not(.locked, .danger):focus{
    border-color: #bbb;
}

.section-tradebuysell-inner-input input.locked {
    background-color: #ddd;
    cursor: not-allowed;
}

.section-tradebuysell-inner-range {
    width: calc(100% - 22px);
    padding-top: 5px;
    margin-left: 11px;
}

.section-tradebuysell-inner-range.load-funds {
    width: calc(100% - 16px);
    padding-top: 30px;
    margin-left: 8px;
}

.section-tradebuysell-inner-range.withTitle {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 25px 0px;
    margin-left: 4px;
}

.section-tradebuysell-inner-range .rc-slider-mark-text{
    font-size: 14px;
}

.section-tradebuysell-inner-range .rc-slider{
    height: 35px;
}

.rc-slider-disabled {
    background-color: transparent;
}

.section-tradebuysell-inner-range.withTitle .rc-slider {
    margin-left: 25px;
}

.section-tradebuysell-inner-range .rc-slider-rail, .section-tradebuysell-inner-range .rc-slider-track{
    height: 10px;
}

.section-tradebuysell-inner-range .rc-slider-track{
    background-color: #aaaaaa;
}

.section-tradebuysell-inner-range .rc-slider-dot {
    width: 15px;
    height: 15px;
    bottom: -8px;
    transition: .4s;
}

.section-tradebuysell-inner-range .rc-slider-disabled .rc-slider-dot, .section-tradebuysell-inner-range .rc-slider-disabled .rc-slider-handle, .section-tradebuysell-inner-range .rc-slider-disabled .rc-slider-mark-text {
    cursor: default !important;
}

.section-tradebuysell-inner-range .rc-slider-dot-active, .section-tradebuysell-inner-range .rc-slider-dot:hover {
    border-color: #eee;
    background-color: #aaa;
}

.section-tradebuysell-inner-range .rc-slider-handle{
    width: 20px;
    height: 20px;
    opacity: 1;
    border-color: #999;
}

.section-tradebuysell-inner-range .rc-slider-handle:focus-visible, .section-tradebuysell-inner-range .rc-slider-handle:active, .section-tradebuysell-inner-range .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging{
    box-shadow: 0 0 0 3px #99999999;
    border-color: #999;
}

.section-tradebuysell-inner-input-title{
    position: absolute;
    left: 20px;
    color: #777;
}

.section-tradebuysell-inner-input-label {
    position: absolute;
    right: 40px;
    z-index: 2;
}

.section-tradebuysell-inner-input2 {
    margin: 15px 0px;
    display: flex;
    align-items: center;
}

.section-tradebuysell-inner-input2 i {
    position: absolute;
    margin-left: 15px;
    font-size: 18px;
    cursor: pointer;
    transition: .4s;
    z-index: 2;
}

.section-tradebuysell-inner-input2 i:hover {
    color: #888;
}

.section-tradebuysell-inner-input2 label {
    position: absolute;
    margin-left: 50px;
    color: #aaa;
    transition: .4s;
}

.section-tradebuysell-inner-input2 label.active {
    background-color: #eee;
    color: #777;
    margin-left: 20px;
    z-index: 2;
}

.section-tradebuysell-inner-input2 input {
    width: 100%;
    outline: none;
    padding: 10px 0px;
    padding-left: 110px;
    padding-right: 85px;
    border: 2px solid transparent;
    border-radius: 5px;
    transition: .4s;
    z-index: 1;
    text-align: right;
    background-color: #eee;
}

.section-tradebuysell-inner-input2 input:hover, .section-tradebuysell-inner-input2 input:focus{
    border-color: #bbb;
}

.section-tradebuysell-inner-input2 input.locked {
    background-color: #0000001f;
    border-color: transparent;
}

.section-tradebuysell-inner-input input.danger, .section-tradebuysell-inner-input2 input.danger{
    border-color: #ffafaf;
    box-shadow: 0px 0px 10px #ffc2c2;
}

.potential-info {
    position: absolute;
    font-size: 14px;
    margin-top: -10px;
    margin-right: 87px;
    right: 0;
    transition: .4s;
}

.potential-info.success {
    color: var(--global-success-color);
}

.potential-info.danger {
    color: var(--global-danger-color);
}

.future-sections .section-tradebuysell-inner-input2 {
    transition: .4s;
}

.future-sections .section-tradebuysell-inner-input2.take-profit {
    margin-top: 29px;
}

.section-tradebuysell-inner-options span {
    width: 70px;
    padding: 5px 0px;
    background-color: #e8e8e8;
    margin-right: 7px;
    transition: .4s;
    border-radius: 12px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
}

.section-tradebuysell-buy .section-tradebuysell-inner-options span:hover, .section-tradebuysell-buy .section-tradebuysell-inner-options span.active{
    background-color: var(--global-success-color);
    color: #fff;
}

.section-tradebuysell-sell .section-tradebuysell-inner-options span:hover, .section-tradebuysell-sell .section-tradebuysell-inner-options span.active{
    background-color: var(--global-danger-color);
    color: #fff;
}

.section-tradebuysell-inner-results {
    margin-bottom: 35px;
}

.section-tradebuysell-inner-results .danger{
    color: var(--global-danger-color);
}

.section-tradebuysell-inner-results div {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}

.section-tradebuysell-inner-button {
    width: 100%;
    font-weight: bold;
    padding: 10px;
    border: 0px;
    color: #fff;
    cursor: pointer;
    border-radius: 12px;
    transition: .4s;
    letter-spacing: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-tradebuysell-inner-button:disabled {
    opacity: .7;
}

.section-tradebuysell-inner-button div div {
    height: 24px !important;
    width: 50px !important;
    display: flex;
    align-items: center;
    fill: #fff !important;
}

.section-tradebuysell-inner-button.with-link {
    font-weight: normal;
}

.section-tradebuysell-inner-button.with-link a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

.section-tradebuysell-inner-button.with-link a:first-child {
    margin-right: 5px;
}

.section-tradebuysell-inner-button.with-link a:last-child {
    margin-left: 5px;
}

.section-tradebuysell-buy .section-tradebuysell-inner-button, .section-tradebuysell-inner-button.buy{
    background-color: var(--global-success-color);
}

.section-tradebuysell-buy .section-tradebuysell-inner-button:hover, .section-tradebuysell-inner-button.buy:hover{
    background-color: var(--global-success-hover-color);
}

.section-tradebuysell-sell .section-tradebuysell-inner-button, .section-tradebuysell-inner-button.sell{
    background-color: var(--global-danger-color);
}

.section-tradebuysell-sell .section-tradebuysell-inner-button:hover, .section-tradebuysell-inner-button.sell:hover{
    background-color: var(--global-danger-hover-color);
}

.section-tradebuysell-inner-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section-tradebuysell-inner-buttons .section-tradebuysell-inner-button {
    width: 49%;
}

.section-tradebuysell-inner .leverage-area {
    display: none;
    position: absolute;
    width: 100%;
    background-color: #fdfdfd;
    z-index: 9;
    padding: 15px;
    box-shadow: 0px 0px 10px #00000050;
    border-radius: 5px;
    animation-duration: .5s;
    animation-name: open-animation;
}

.section-tradebuysell-inner .leverage-area.show {
    display: block;
}

.section-tradebuysell-inner .leverage-area label {
    display: block;
    padding-top: 15px;
    padding-left: 5px;
    font-size: 14px;
}

/*========================= section-tradecurrencyinfo */

.section-tradecurrencyinfo {
    padding: 20px;
    height: 748px;
    overflow: hidden !important;
    width: calc(35% - 8px);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    position: relative;
    margin-right: 8px;
    margin-left: 8px;
}

.future-sections .section-tradecurrencyinfo {
    height: 785px;
}

.future-sections .section-tradecurrencyinfo .section-walletlist-dialog-transaction-history .info-area .items {
    height: 550px;
}

.section-tradecurrencyinfo .section-walletlist-dialog-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 55px;
    height: -webkit-fill-available;
    width: -moz-available;
}

.section-tradecurrencyinfo .section-walletlist-dialog-transaction-history {
    padding: 0px 5px;
    margin-top: 75px;
}

.section-tradecurrencyinfo .section-walletlist-dialog-back-to {
    left: 15px;
}

.section-tradecurrencyinfo .section-walletlist-dialog-transaction-history .transaction-detail .back-to-main {
    margin-left: -19px;
}

.section-tradecurrencyinfo-header {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 0;
    top: 25px;
    left: 0px;
}

.section-tradecurrencyinfo-header img {
    width: 25px;
    height: 25px;
}

.section-tradecurrencyinfo-header label {
    font-size: 20px;
    font-weight: 600;
    margin: 0px 10px;
}

.section-tradecurrencyinfo-header span {
    font-size: 16px;
    font-weight: 500;
    color: #888;
    padding-top: 4px;
}

.section-tradecurrencyinfo .section-walletlist-dialog-transaction-history .info-area .items {
    height: 515px;
}

/*========================= section-tradeorderhistory */

.edit-order-dialog{
    width: 600px;
}

.edit-order-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
}

.edit-order-dialog .p-dialog-header {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}

.edit-order-dialog .p-dialog-header .p-dialog-header-icon:focus {
    box-shadow: none;
}

.edit-process-dialog .p-dialog-content {
    padding-left: 0px;
    padding-right: 0px;
}

.edit-process-dialog .close-button {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 40px 25px 0px 0px;
}

.edit-process-dialog .close-button button {
    border-radius: 12px;
    border: 0px;
    cursor: pointer;
    background-color: var(--global-danger-color);
    box-shadow: 0px 0px 10px var(--global-danger-color);
    font-family: 'Manrope' !important;
    color: #fff;
    padding: 10px;
    font-weight: bold;
    width: 150px;
    transition: .4s;
}

.edit-process-dialog .close-button button:hover {
    box-shadow: 0px 0px 25px var(--global-danger-color);
}

.edit-process-dialog .edit-finish-process-form {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.edit-process-dialog .finish-process-form {
    margin-top: 50px;
    margin-bottom: 15px;
    padding-top: 35px;
    border-top: 2px solid #ccc;
}

.edit-process-dialog .edit-process-form .market-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: -4px;
    margin-bottom: 30px;
}

.edit-process-dialog .edit-process-form .market-info div {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 50%;
}

.edit-process-dialog .edit-process-form .market-info div:last-child {
    justify-content: end;
    font-size: 17px;
    font-weight: 600;
    padding-right: 8px;
}

.edit-process-dialog .edit-process-form .market-info img {
    width: 40px !important;
    height: 40px !important;
}

.edit-process-dialog .edit-process-form .market-info span {
    padding: 0px;
}

.edit-process-dialog .edit-process-form .sl-tp {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.edit-process-dialog .edit-process-form .sl-tp div {
    width: 49%;
    position: relative;
}

.edit-process-dialog .edit-process-form .sl-tp div.potential-info {
    position: absolute;
    margin: 0px;
    margin-top: -18px;
    margin-left: 16px;
    left: 0;
    font-weight: 500;
}

.edit-order-dialog .edit-order-form.finish-process-form label:not(.editProcessButtonText) {
    margin-right: 45px;
}

.edit-order-form {
    position: relative;
}

.edit-order-dialog .edit-order-form .title-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.edit-order-dialog .edit-order-form .title-area .potential-info {
    position: relative;
    margin: 0px;
    margin-bottom: 5px;
    margin-left: 15px;
    font-size: 14px;
    font-weight: 500;
}

.edit-order-dialog .edit-order-form span {
    display: block;
    padding-bottom: 5px;
    font-weight: 500;
}

.edit-order-dialog .edit-order-form input {
    width: 100%;
    outline: none;
    padding: 10px 15px;
    padding-right: 70px;
    border: 1px solid #ccc;
    border-radius: 12px;
    transition: .4s;
    margin-bottom: 20px;
}

.edit-order-dialog .edit-order-form input.danger{
    border-color: #ffafaf;
    box-shadow: 0px 0px 10px #ffc2c2;
}

.edit-order-dialog .edit-order-form input.locked{
    background-color: #eee;
}

.edit-order-dialog .edit-order-form label:not(.editProcessButtonText) {
    position: absolute;
    right: 0;
    margin-top: 11px;
    margin-right: 20px;
    font-weight: 600;
}

.edit-order-dialog .edit-order-form .plusicon {
    position: absolute;
    margin-left: 15px;
    font-size: 18px;
    cursor: pointer;
    transition: .4s;
    margin-top: 14px;
}

.edit-order-dialog .edit-order-form .plusicon:hover{
    color: #888;
}

.edit-order-dialog .edit-order-form button {
    width: 100%;
    font-weight: bold;
    padding: 10px;
    border: 0px;
    color: #fff;
    cursor: pointer;
    border-radius: 12px;
    transition: .4s;
    letter-spacing: 2px;
    background-color: var(--global-success-color);
    box-shadow: 0px 0px 10px var(--global-success-color);
    margin-top: 20px;
    font-family: 'Manrope' !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-order-dialog .edit-order-form button:hover {
    box-shadow: 0px 0px 25px var(--global-success-color);
}

.edit-order-dialog .edit-order-form button div div {
    height: 24px !important;
    width: 50px !important;
    display: flex;
    align-items: center;
    fill: #fff !important;
}

.edit-order-dialog .edit-finish-process-form button {
    height: 44px;
    box-shadow: none !important;
    background-color: transparent;
    color: #333;
    border: 2px solid #777;
    z-index: 1;
    position: relative;
    letter-spacing: normal;
    font-size: 18px;
    font-weight: 600;
    font-family: inherit !important;
    padding: 0px;
}

.edit-order-dialog .edit-finish-process-form button:hover {
    background-color: #77777725;
}

body:not(.dark) .edit-order-dialog .edit-finish-process-form button div div {
    fill: #333 !important;
}

.edit-order-dialog .edit-order-form .editProcessButtonText {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: -33px;
    font-weight: 500;
}

.edit-order-dialog .edit-order-form .editProcessButtonText.success {
    color: var(--global-success-color);
}

.edit-order-dialog .edit-order-form .editProcessButtonText.danger {
    color: var(--global-danger-color);
}

.edit-order-dialog .edit-order-form .input-check {
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    margin-bottom: 40px;
}

.edit-order-dialog .edit-order-form .input-check input {
    margin: 0px;
    margin-right: 8px;
    cursor: pointer;
}

.edit-order-dialog .edit-order-form .input-check label {
    cursor: pointer;
    padding-bottom: 1px;
}

.section-tradeorderhistory {
    width: 100%;
    max-height: 905px;
    overflow: hidden !important;
}

.section-tradeorderhistory-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #ddd;
    position: relative;
}

.section-tradeorderhistory-options div:first-child {
    display: flex;
    justify-content: start;
    align-items: start;
}

.trade-sections .section-tradeorderhistory .general-values {
    min-height: 166px;
}

.trade-sections .section-tradeorderhistory-options {
    height: 73px;
}

.trade-sections .section-tradeorderhistory-list {
    height: 759px;
}

.trade-sections .section-tradeorderhistory-list .processesTable, .trade-sections .section-tradeorderhistory-list .processesHistoryTable {
    position: relative;
}

.trade-sections .section-tradeorderhistory .tables.with-verify-info .MuiTableContainer-root {
    max-height: 560px;
}

.trade-sections .section-tradeorderhistory.future-demo .tables.with-verify-info .MuiTableContainer-root {
    max-height: 582px;
}

.trade-sections .section-tradeorderhistory .MuiTableContainer-root {
    max-height: none;
    height: 682px;
    overflow-x: hidden;
}

.trade-sections .section-tradeorderhistory.future-demo .MuiTableContainer-root {
    height: 704px;
}

.section-tradeorderhistory .withExpandMenu table tbody, .section-tradeorderhistory .rowVirtualization table tbody {
    display: table-row-group;
}

.section-tradeorderhistory .MuiPaper-root .MuiToolbar-root:last-child {
    display: none;
}

.section-tradeorderhistory-options span {
    margin-right: 50px;
    cursor: pointer;
}

.section-tradeorderhistory-options span:hover, .section-tradeorderhistory-options span.active{
    font-weight: bold;
}

.section-tradeorderhistory-options-select {
    width: 125px;
    padding: 3px 10px;
    border-radius: 10px;
    border: 1px solid #aaa;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section-tradeorderhistory-options-select-menu{
    position: absolute;
    background-color: #f9f9f9;
    right: 20px;
    top: 60px;
    z-index: 9;
    width: 125px;
    border-radius: 10px;
    padding: 5px;
    box-shadow: 0px 0px 5px #00000059;
    animation: open-animation .5s;
}

.section-tradeorderhistory-options-select-menu label{
    display: block;
    cursor: pointer;
    transition: .4s;
    color: #333;
    padding: 2px 10px;
    border-radius: 10px;
    margin-bottom: 4px;
}

.section-tradeorderhistory-options-select-menu label:last-child{
    margin-bottom: 0px;
}

.section-tradeorderhistory-options-select-menu label.active, .section-tradeorderhistory-options-select-menu label:hover{
    background-color: #e8e8e8;
}

.section-tradeorderhistory .general-values {
    border-bottom: 1px solid #ddd;
    padding: 20px;
}

.section-tradeorderhistory .general-values:not(.loading) .general-values-inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.section-tradeorderhistory .general-values .general-values-inner .values {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 400px;
}

.section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 550px;
    margin: 0px;
}

.section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-buttons-inner {
    width: 100%;
    justify-content: space-between;
    margin: 0px;
}

.section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-button1 {
    width: 48%;
    justify-content: flex-start;
    align-items: center;
}

.section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-button1 i {
    margin-right: 10px;
    font-size: 16px;
}

.section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-button1 span {
    font-size: 15px;
}

.section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-button1 .loading {
    width: 100%;
}

.section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-button1 .loading div {
    justify-content: center;
    width: 100%;
}

.section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-button1 .loading div div {
    height: 22px !important;
    width: 50px !important;
    display: flex;
    align-items: center;
}

.section-tradeorderhistory .general-values .credit-info {
    width: 250px !important;
    height: 128px !important;
    background-color: #eee;
    box-shadow: 0px 0px 10px #aaa;
    position: absolute;
    margin-left: 47px;
    margin-top: 160px;
    border-radius: 10px;
    display: flex;
    justify-content: start !important;
    align-items: start !important;
    flex-direction: column;
    padding: 15px;
    font-size: 14px;
    animation: open-animation 0.75s;
}

.section-tradeorderhistory .general-values .credit-info div b {
    margin-left: 5px;
}

.section-tradeorderhistory .general-values:not(.loading) div {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 5px;
    width: max-content;
}

.section-tradeorderhistory .general-values:not(.loading) div:last-child {
    margin-bottom: 0px;
}

.section-tradeorderhistory .general-values:not(.loading) div.danger span, .section-tradeorderhistory .general-values:not(.loading) div.danger label {
    color: var(--global-danger-color) !important;
}

.section-tradeorderhistory .general-values span, .section-tradeorderhistory-list .tables .submenu-values span {
    font-size: 13px;
    font-weight: bold;
    color: #555;
}

.section-tradeorderhistory .general-values label {
    font-size: 17px;
    font-weight: bold;
    margin-left: 5px;
}

.section-tradeorderhistory .general-values .info-icon {
    margin-left: 10px;
    cursor: pointer;
    opacity: .5;
    transition: .4s;
}

.section-tradeorderhistory .general-values .info-icon:hover {
    opacity: .3;
}

.section-tradeorderhistory-list .tables .total-profit {
    position: absolute;
    padding: 10px 0px 0px 15px;
    width: 100%;
    font-size: 22px;
    font-weight: bold;
    display: flex;
    justify-content: center;
}

.section-tradeorderhistory-list .tables.with-verify-info .total-profit {
    position: relative;
}

.section-tradeorderhistory-list .tables.with-verify-info .verify-info {
    padding: 8px 10px;
    width: 100%;
    font-size: 18px;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.section-tradeorderhistory-list .tables.with-verify-info .verify-info a {
    border: 0px;
    padding: 10px 75px;
    margin-top: 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    color: var(--global-yellow-color);
    background-color: #333;
    cursor: pointer;
    transition: .4s;
    text-decoration: none;
}

.section-tradeorderhistory-list .tables.with-verify-info .verify-info a:hover{
    background-color: var(--global-dark1-color);
}

.section-tradeorderhistory .tables .total-profit label {
    position: absolute;
    z-index: 2;
}

.section-tradeorderhistory .tables .total-profit label.success {
    color: var(--global-success-color);
}

.section-tradeorderhistory .tables .total-profit label.danger {
    color: var(--global-danger-color);
}

.section-tradeorderhistory-list .tables .submenu-values {
    display: flex;
    justify-content: start;
    align-items: start;
}

.section-tradeorderhistory-list .tables .submenu-values1 {
    width: 350px;
    height: 150px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.section-tradeorderhistory-list .tables .submenu-values1 div {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 5px;
    line-height: 18px;
}

.section-tradeorderhistory-list .tables .submenu-values1 div:last-child {
    margin-bottom: 0px;
}

.section-tradeorderhistory-list .tables .submenu-values1 div.success {
    color: var(--global-success-color);
}

.section-tradeorderhistory-list .tables .submenu-values1 div label{
    margin-left: 5px;
}

.section-tradeorderhistory-list .tables .submenu-values div label {
    font-size: 15px;
}

.section-tradeorderhistory-list .tables .submenu-values2 {
    border-left: 2px solid #ccc;
    min-height: 150px;
    max-height: 300px;
    overflow: auto;
    display: flex;
    justify-content: start;
    align-items: start;
    width: -webkit-fill-available;
    width: -moz-available;
}

.section-tradeorderhistory-list .tables .submenu-values2 .empty-details {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: .6;
}

.section-tradeorderhistory-list .tables .submenu-values2 .empty-details i {
    font-size: 26px;
    margin-bottom: 10px;
}

.section-tradeorderhistory-list .tables .submenu-values2 .empty-details span {
    font-weight: 600;
}

.section-tradeorderhistory-list .tables .submenu-values2 .details {
    width: 100%;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.section-tradeorderhistory-list .tables .submenu-values2 .details .details-item {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    padding: 15px;
    border-bottom: 2px solid #ddd;
}

.section-tradeorderhistory-list .tables .submenu-values2 .details .details-item:last-child {
    border: 0px;
}

.section-tradeorderhistory-list .tables .submenu-values2 .details .details-item div {
    width: 20%;
    margin-right: 10px;
}

.section-tradeorderhistory-list .tables .submenu-values2 .details .details-item div:last-child {
    margin-right: 0px;
}

.section-tradeorderhistory-list .tables .submenu-values2 .details .details-item div:nth-child(1), .section-tradeorderhistory-list .tables .submenu-values2 .details .details-item div:nth-child(3) {
    width: 30%;
}

.section-tradeorderhistory-list thead tr.MuiTableRow-root {
    box-shadow: none;
}

.section-tradeorderhistory span.priceForTable {
    display: flex;
    justify-content: start;
    align-items: center;
}

.section-tradeorderhistory span.priceForTable i {
    margin: 0px 5px;
}

.section-tradeorderhistory td[colspan] {
    width: 100% !important;
}

.section-tradeorderhistory .MuiPaper-root {
    border: 0px;
    box-shadow: none;
}

.section-tradeorderhistory .MuiTableContainer-root {
    overflow: auto;
    max-height: 776px;
}

.section-tradeorderhistory tr.MuiTableRow-root {
    font-size: 16px;
    position: relative;
}

.section-tradeorderhistory .processesTable tbody tr.MuiTableRow-root {
    margin-bottom: -72px;
}

.section-tradeorderhistory .processesTable tbody tr.Mui-TableBodyCell-DetailPanel {
    margin-bottom: 0px;
}

.section-tradeorderhistory .processesHistoryTable tbody tr.MuiTableRow-root {
    margin-bottom: -59px;
}

.section-tradeorderhistory .processesHistoryTable tbody tr.Mui-TableBodyCell-DetailPanel {
    margin-bottom: 0px;
}

.section-tradeorderhistory tr.Mui-TableBodyCell-DetailPanel, .section-tradeorderhistory tr.Mui-TableBodyCell-DetailPanel td {
    background-color: #f9f9f9;
    padding: 0px;
}

.section-tradeorderhistory tr.Mui-TableBodyCell-DetailPanel td .MuiCollapse-root {
    width: 100%;
}

.section-tradeorderhistory tr.MuiTableRow-root th, .section-tradeorderhistory tr.MuiTableRow-root td {
    min-width: min-content;
    font-weight: bold;
    font-family: 'Manrope' !important;
    color: #333;
}

.section-tradeorderhistory tr.MuiTableRow-root td .total-value button {
    padding: 0px;
    vertical-align: text-top;
    margin-left: 5px;
}

.section-tradeorderhistory tr.MuiTableRow-root td .total-value svg {
    width: 20px;
    height: 20px;
}

.section-tradeorderhistory tr.MuiTableRow-root:last-child td {
    border-bottom: 0px;
}

.section-tradeorderhistory .withExpandMenu tr.MuiTableRow-root:not(.Mui-TableBodyCell-DetailPanel) {
    cursor: pointer;
}

.section-tradeorderhistory .withExpandMenu tr:not(.Mui-TableBodyCell-DetailPanel) th:first-child, .section-tradeorderhistory .withExpandMenu tr:not(.Mui-TableBodyCell-DetailPanel) td:first-child {
    display: none;
}

.section-tradeorderhistory .column3 tr th, .section-tradeorderhistory .column3 tr td {
    width: calc(100% / 3);
}

.section-tradeorderhistory .column4 tr th, .section-tradeorderhistory .column4 tr td {
    width: calc(100% / 4);
}

.section-tradeorderhistory .column5 tr th, .section-tradeorderhistory .column5 tr td {
    width: calc(100% / 5);
}

.section-tradeorderhistory .column6 tr th, .section-tradeorderhistory .column6 tr td {
    width: calc(100% / 6);
}

.section-tradeorderhistory .column7 tr th, .section-tradeorderhistory .column7 tr td {
    width: calc(100% / 7);
}

.section-tradeorderhistory .column8 tr th, .section-tradeorderhistory .column8 tr td {
    width: calc(100% / 8);
}

.section-tradeorderhistory .column9 tr th, .section-tradeorderhistory .column9 tr td {
    width: calc(100% / 9);
}

.section-tradeorderhistory .column10 tr th, .section-tradeorderhistory .column10 tr td {
    width: calc(100% / 10);
}

.section-tradeorderhistory tr.MuiTableRow-root td .market {
    display: flex;
    align-items: center;
}

.section-tradeorderhistory tr.MuiTableRow-root td .market img, .edit-process-dialog .edit-process-form .market-info img {
    width: 27px;
    height: 27px;
    margin-right: 10px;
    border-radius: 10px;
    border: 1px solid #ddd;
    padding: 4px;
}

.section-tradeorderhistory-list.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 460px;
}

.section-tradeorderhistory tr.MuiTableRow-root form:not(.canceliconform) {
    width: 100%;
    display: flex;
    align-items: center;
}

.section-tradeorderhistory tr.MuiTableRow-root .success, .section-tradeorderhistory tr.MuiTableRow-root .success span{
    color: var(--global-success-color);
}

.section-tradeorderhistory tr.MuiTableRow-root .danger, .section-tradeorderhistory tr.MuiTableRow-root .danger span{
    color: var(--global-danger-color);
}

.section-tradeorderhistory tr.MuiTableRow-root span{
    display: flex;
}

.section-tradeorderhistory .openClosedOrders tr.MuiTableRow-root td:last-child span {
    align-items: center;
}

.section-tradeorderhistory tr.MuiTableRow-root .openOrderState {
    align-items: center;
    justify-content: center;
}

.section-tradeorderhistory tr.MuiTableRow-root span label{
    border-radius: 6px;
    width: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 10px 0px;
    letter-spacing: 0.5px;
    font-weight: 700;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.section-tradeorderhistory tr.MuiTableRow-root span label.success{
    background-color: #22c55e0a;
    color: #22c55e;
}

.section-tradeorderhistory tr.MuiTableRow-root span label.danger{
    background-color: #ef44440a;
    color: #ef4444;
}

.section-tradeorderhistory tr.MuiTableRow-root span label.warning{
    background-color: #f59e0b0a;
    color: #ffc100;
}

.section-tradeorderhistory tr.MuiTableRow-root span label.noedit {
    margin-left: 40px;
}

.section-tradeorderhistory tr.MuiTableRow-root span label.nobuttons {
    margin: 0px 40px;
}

.section-tradeorderhistory tr.MuiTableRow-root .plusicon{
    position: absolute;
    margin-left: 8px;
    cursor: pointer;
    color: #666;
    transition: .4s;
}

.section-tradeorderhistory tr.MuiTableRow-root .plusicon:hover{
    color: #000;
}

.section-tradeorderhistory tr.MuiTableRow-root input{
    width: 90%;
    border-radius: 5px;
    border: 1px solid #aaa;
    padding: 3px 0px;
    padding-left: 5px;
    outline: none;
    font-size: 14px;
}

.section-tradeorderhistory tr.MuiTableRow-root input::-webkit-outer-spin-button,
.section-tradeorderhistory tr.MuiTableRow-root input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.section-tradeorderhistory tr.MuiTableRow-root input {
  -moz-appearance: textfield;
}

.section-tradeorderhistory tr.MuiTableRow-root input.danger {
    border-color: #ffafaf;
    box-shadow: 0px 0px 10px #ffc2c2;
}

.section-tradeorderhistory tr.MuiTableRow-root .checkicon{
    display: none;
    margin-left: -26px;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    background-color: #000000;
    padding: 5px 5px 3px 5px;
    border-radius: 50px;
    transition: .4s;
}

.section-tradeorderhistory tr.MuiTableRow-root .checkicon:hover{
    background-color: var(--global-success-color);
}

.mobile-table tr.MuiTableRow-root:not(.Mui-TableBodyCell-DetailPanel) th:first-child, .mobile-table tr.MuiTableRow-root:not(.Mui-TableBodyCell-DetailPanel) td:first-child, .mobile-table .MuiToolbar-root button:nth-child(3) {
    display: none;
}

.withExpandMenu .mobile-table tr.MuiTableRow-root th:nth-child(2), .withExpandMenu .mobile-table tr.MuiTableRow-root td:nth-child(2) {
    display: none;
}

.mobile-column-market {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}

.mobile-column-market .market img {
    width: 25px !important;
    height: 25px !important;
    padding: 3px !important;
}

.mobile-column-market .market span {
    font-size: 10px;
}

.mobile-column-market .date {
    padding-left: 3px;
    padding-top: 3px;
    opacity: .8;
}

.mobile-column-price .price, .mobile-column-price .count, .mobile-column-price .total {
    display: flex;
    justify-content: start;
    align-items: center;
}

.mobile-column-price .price {
    margin-bottom: 5px;
}

.mobile-column-price .total {
    margin-top: 5px;
}

.mobile-column-price .price label, .mobile-column-price .count label, .mobile-column-price .total label {
    margin-right: 5px;
    font-weight: bold;
    opacity: .9;
}

.mobile-column-price .price span, .mobile-column-price .count span, .mobile-column-price .total span {
    font-weight: 600;
}

.mobile-column-state i {
    font-size: 16px;
    margin: 0px 5px;
}

.mobile-column-state .success{
    color: #22c55e;
}

.mobile-column-state .danger{
    color: #ef4444;
}

.mobile-column-state .warning{
    color: #ffc100;
}

.mobile-column-state .noedit {
    margin-left: 40px;
}

.mobile-column-state .nobuttons {
    margin: 0px 40px;
}

/*========================= section-lasttrades */

.section-lasttrades {
    padding: 20px;
    padding-right: 15px;
    height: 748px;
    overflow: hidden !important;
    width: 30%;
    position: relative;
}

.future-sections .section-tradecurrencyinfo, .future-sections .section-lasttrades {
    height: 785px;
}

.section-lasttrades .title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 40px;
}

.section-lasttrades .items-title {
    margin-bottom: 5px;
    padding-right: 5px;
}

.section-lasttrades .items {
    height: 620px;
    overflow: auto;
    padding-right: 5px;
}

.section-lasttrades .items .item:first-child {
    padding-top: 0px;
}

.section-lasttrades .items .item:last-child {
    padding-bottom: 0px;
}

.section-lasttrades .item {
    padding: 3px 0px;
}

.section-lasttrades .items-title, .section-lasttrades .item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.section-lasttrades .items-title span, .section-lasttrades .item span {
    width: 30%;
    text-align: right;
}

.section-lasttrades .items-title span:first-child, .section-lasttrades .item span:first-child {
    width: 40%;
    text-align: left;
}

.section-lasttrades .items-title span {
    font-weight: bold;
    font-size: 13px;
    color: #777;
}

.section-lasttrades .item span {
    font-size: 12px;
    font-weight: 600;
    color: #333;
}

.section-lasttrades .item span.success {
    color: var(--global-success-color);
}

.section-lasttrades .item span.danger {
    color: var(--global-danger-color);
}

.section-lasttrades .loading {
    margin-top: 50px;
}

/*========================= koyu tema düzenlemeleri */

body.dark .p-dialog-mask .p-dialog{
    box-shadow: 0px 0px 15px #ffffff24;
}

body.dark .p-dialog-mask .p-dialog .p-dialog-header .p-dialog-header-icon{
    color: #ccc;
}

body.dark .p-dialog-mask .p-dialog .p-dialog-header .p-dialog-header-icon:hover {
    color: #ccc;
    background-color: #555;
}

body.dark .p-dialog-mask .p-dialog .p-dialog-header, body.dark .p-dialog-mask .p-dialog .p-dialog-content, body.dark .p-dialog-mask .p-dialog .p-dialog-footer {
    background-color: #333;
    color: #fff;
}

body.dark .trade-sections .back-to {
    color: #ccc;
}

body.dark .section-maincharttop-top-title .star-icon {
    color: #777;
}

body.dark .section-maincharttop-top-title .star-icon:hover, body.dark .section-maincharttop-top-title .star-icon.active{
    color: var(--global-yellow-color);
}

body.dark .section-maincharttop-top{
    border-color: #555;
}

body.dark .section-maincharttop-top-title, body.dark .section-maincharttop-bottom{
    border-color: #555;
}

body.dark .section-maincharttop-top-data div span:first-child{
    color: #999;
}

body.dark .section-maincharttop-bottom button{
    background: #3333336e;
    color: #aaa;
}

body.dark .section-maincharttop-bottom button:hover{
    background: #4444446e;
    color: #bbb;
}

body.dark .section-maincharttop-bottom button.active{
    background: #4444446e;
    box-shadow: 0 0 0 2px #212121, 0 0 0 4px #777777, 0 1px 2px 0 #212121;
    color: #bbb;
}

body.dark .section-mainchartohlc i {
    color: #aaa;
}

body.dark .section-mainchartohlc-element .section-mainchartohlc-element1 div:first-child.success, body.dark .section-mainchartohlc-element .section-mainchartohlc-element1 div:first-child.danger{
    color: #999;
}

body.dark .section-mainchartohlc-element .section-mainchartohlc-element-div label {
    color: #999;
}

body.dark .section-mainchartbottom section {
    background-color: #212121;
}

body.dark .section-mainchartbottom text.react-financial-charts-tooltip tspan:nth-child(1), body.dark .section-mainchartbottom text.react-financial-charts-tooltip tspan:nth-child(3), body.dark .section-mainchartbottom text.react-financial-charts-tooltip tspan:nth-child(5), body.dark .section-mainchartbottom text.react-financial-charts-tooltip tspan:nth-child(7) {
    fill: #56a1ff;
}

body.dark .section-mainchartbottom text.react-financial-charts-tooltip tspan:nth-child(2), body.dark .section-mainchartbottom text.react-financial-charts-tooltip tspan:nth-child(4), body.dark .section-mainchartbottom text.react-financial-charts-tooltip tspan:nth-child(6), body.dark .section-mainchartbottom text.react-financial-charts-tooltip tspan:nth-child(8), body.dark .section-mainchartbottom text.react-financial-charts-tooltip tspan:nth-child(9) {
    fill: #fff;
}

body.dark .section-mainchartbottom:not(.loading) {
    background-color: #1c1c1c;
}

body.dark .section-orderbook-listtitle{
    border-color: #555;
}

body.dark .section-orderbook-list-element:hover{
    background-color: #ffffff12;
}

body.dark .section-orderbook-listtitlecenter{
    background-color: #333;
    color: #eee;
    border-color: #444;
}

body.dark .section-orderbook-list-element div{
    color: #ddd;
}

body.dark .section-tradecryptocurrencylist-dialog .buttons a {
    color: #ddd;
}

body.dark .section-tradecryptocurrencylist-search input{
    background-color: #333;
    border-color: #333;
    color: #fff;
}

body.dark .section-tradecryptocurrencylist-options {
    background-color: #00000025;
}

body.dark .section-tradecryptocurrencylist-options i{
    color: #999;
}

body.dark .section-tradecryptocurrencylist-options span{
    color: #bbb;
}

body.dark .section-tradecryptocurrencylist-options .active, body.dark .section-tradecryptocurrencylist-options i:hover, body.dark .section-tradecryptocurrencylist-options span:hover{
    color: #fff;
}

body.dark .section-tradecryptocurrencylist tr.MuiTableRow-root, body.dark .section-tradecryptocurrencylist .MuiTableBody-root, body.dark .section-tradecryptocurrencylist .section-tradecryptocurrencylist-table table {
    background-color: #212121;
}

body.dark .section-tradecryptocurrencylist .MuiTypography-root {
    color: #ccc;
}

body.dark .section-tradecryptocurrencylist tbody tr.MuiTableRow-root:hover, body.dark .section-tradecryptocurrencylist tbody tr.MuiTableRow-root:hover td {
    background-color: #272727;
}

body.dark .section-tradecryptocurrencylist tr.MuiTableRow-root td, body.dark .section-tradecryptocurrencylist tr.MuiTableRow-root th {
    border-color: #444;
}

body.dark .section-tradecryptocurrencylist .Mui-TableHeadCell-Content-Wrapper, body.dark .section-tradecryptocurrencylist .MuiSvgIcon-root {
    color: #ccc;
}

body.dark .section-tradecryptocurrencylist-element a, body.dark a.section-tradecryptocurrencylist-element {
    color: #bbb;
}

body.dark .section-tradecryptocurrencylist-element i {
    color: #777;
}

body.dark .section-tradecryptocurrencylist-element i.active, body.dark .section-tradecryptocurrencylist-element i:hover {
    color: var(--global-yellow-color);
}

body.dark .section-tradecryptocurrencylist-element.price.success{
    animation: fadeInSuccessForDark 4s ease-in-out;
}

body.dark .section-tradecryptocurrencylist-element.price.danger{
    animation: fadeInDangerForDark 4s ease-in-out;
}

body.dark .section-traderecenttrades-listtitle{
    color: #ddd;
    border-color: #555;
}

body.dark .section-traderecenttrades-list-element{
    color: #ddd;
    border-color: #555;
}

body.dark .section-traderecenttrades-list-element:hover{
    background-color: #ffffff12;
}

body.dark .section-tradebuysell-options span{
    color: #ccc;
}

body.dark .section-tradebuysell-options span.active, body.dark .section-tradebuysell-options span:hover{
    color: #fff;
}

body.dark .section-tradebuysell-inner-input-title{
    color: #aaa;
}

body.dark .section-tradebuysell-inner-input input{
    background-color: #333;
    color: #fff;
}

body.dark .section-tradebuysell-inner-input input.locked {
    background-color: #444;
}

body.dark .section-tradebuysell-inner-input input:not(.locked, .danger):hover, body.dark .section-tradebuysell-inner-input input:not(.locked, .danger):focus{
    border-color: #666;
}

body.dark .section-tradebuysell-inner-input-label{
    color: #fff;
}

body.dark .rc-slider-mark-text{
    color: #999;
}

body.dark .rc-slider-mark-text-active{
    color: #ccc;
}

body.dark .section-tradebuysell-inner-range .rc-slider-rail {
    background-color: #555;
}

body.dark .section-tradebuysell-inner-range .rc-slider-track {
    background-color: #333;
}

body.dark .section-tradebuysell-inner-range.load-funds .rc-slider-track {
    background-color: #444;
}

body.dark .section-tradebuysell-inner-range .rc-slider-dot{
    background-color: #888;
    border-color: #ccc;
}

body.dark .section-tradebuysell-inner-range .rc-slider-dot-active, body.dark .section-tradebuysell-inner-range .rc-slider-dot:hover {
    border-color: #888;
    background-color: #333;
}

body.dark .section-tradebuysell-inner-range .rc-slider-handle{
    border-color: #777;
    background-color: #222;
}

body.dark .section-tradebuysell-inner-range .rc-slider-handle:focus-visible, body.dark .section-tradebuysell-inner-range .rc-slider-handle:active, body.dark .section-tradebuysell-inner-range .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging{
    box-shadow: 0 0 0 3px #888;
    border-color: #ccc;
}

body.dark .section-tradebuysell-inner-options span{
    background-color: #555;
    color: #fff;
}

body.dark .section-tradebuysell-inner-input2 input {
    background-color: #333;
    color: #fff;
}

body.dark .section-tradebuysell-inner-input2 input:hover, body.dark .section-tradebuysell-inner-input2 input:focus{
    border-color: #666;
}

body.dark .section-tradebuysell-inner-input2 input.locked {
    background-color: #ffffff29;
    border-color: transparent;
}

body.dark .section-tradebuysell-inner-input input.danger, body.dark .section-tradebuysell-inner-input2 input.danger{
    border-color: #ff7070;
    box-shadow: 0px 0px 5px #ff7070;
}

body.dark .section-tradebuysell-inner-input2 label{
    color: #888;
}

body.dark .section-tradebuysell-inner-input2 label.active{
    background-color: #333;
    color: #aaa;
}

body.dark .section-tradebuysell-inner .leverage-area {
    background-color: #444;
}

body.dark .section-tradebuysell-tab-area .buy-sell-tab-area {
    background-color: #333;
}

body.dark .section-tradecurrencyinfo .section-walletlist-dialog-transaction-history .transaction-detail .back-to-main {
    background-color: var(--global-dark1-color);
}

body.dark .section-tradecurrencyinfo .section-walletlist-dialog-body .transaction-history, body.dark .section-tradecurrencyinfo .section-walletlist-dialog-body .currencies .items .item {
    background-color: #333;
}

body.dark .section-tradecurrencyinfo .section-walletlist-dialog-body .transaction-history:hover, body.dark .section-tradecurrencyinfo .section-walletlist-dialog-body .currencies .items .item:hover {
    background-color: #444;
}

body.dark .edit-order-dialog .edit-order-form input{
    background-color: #444;
    border-color: #555;
    color: #fff;
}

body.dark .edit-order-dialog .edit-order-form input.danger {
    border-color: #ff7070;
    box-shadow: 0px 0px 5px #ff7070;
}

body.dark .edit-order-dialog .edit-order-form input.locked {
    background-color: #5a5a5a !important;
}

body.dark .edit-order-dialog .edit-finish-process-form button {
    color: #ddd;
}

body.dark .section-tradeorderhistory-options{
    border-color: #555;
}

body.dark .section-tradeorderhistory-options span{
    color: #ccc;
}

body.dark .section-tradeorderhistory-options span:hover, body.dark .section-tradeorderhistory-options span.active{
    color: #fff;
}

body.dark .section-tradeorderhistory-options-select{
    background-color: #333;
    color: #ccc;
    border-color: #555;
}

body.dark .section-tradeorderhistory-options-select-menu{
    background-color: #333;
    box-shadow: 0px 0px 5px #dcdcdc36;
}

body.dark .section-tradeorderhistory-options-select-menu label {
    color: #ccc;
}

body.dark .section-tradeorderhistory-options-select-menu label.active, body.dark .section-tradeorderhistory-options-select-menu label:hover{
    background-color: #444;
}

body.dark .section-tradeorderhistory .general-values {
    border-color: #555;
}

body.dark .section-tradeorderhistory .general-values span, body.dark .section-tradeorderhistory-list .tables .submenu-values span {
    color: #eee;
}

body.dark .section-tradeorderhistory .general-values .titles span {
    color: #999;
}

body.dark .section-tradeorderhistory .general-values .credit-info {
    background-color: #2b2b2b;
    box-shadow: 0px 0px 10px #777;
}

body.dark .section-tradeorderhistory-list .tables .submenu-values div label{
    font-weight: 500;
    color: #fff;
}

body.dark .section-tradeorderhistory-list .tables .submenu-values div label.success{
    font-weight: bold;
    color: var(--global-success-color);
}

body.dark .section-tradeorderhistory-list .tables .submenu-values div label.danger{
    font-weight: bold;
    color: var(--global-danger-color);
}

body.dark .section-tradeorderhistory-list .tables .submenu-values div.success label {
    font-weight: bold;
    color: var(--global-success-color);
}

body.dark .section-tradeorderhistory-list .tables .submenu-values div.success span {
    color: var(--global-success-color);
}

body.dark .section-tradeorderhistory-list .tables .submenu-values div.danger label {
    font-weight: bold;
    color: var(--global-danger-color);
}

body.dark .section-tradeorderhistory-list .tables .submenu-values div.danger span {
    color: var(--global-danger-color);
}

body.dark .section-tradeorderhistory-list .tables .submenu-values2 .empty-details {
    opacity: .4;
}

body.dark .section-tradeorderhistory-list .tables .submenu-values2, body.dark .section-tradeorderhistory-list .tables .submenu-values2 .details .details-item {
    border-color: #555;
}

body.dark .section-tradeorderhistory p, body.dark .section-tradeorderhistory .MuiSelect-select{
    color: #ccc;
}

body.dark .section-tradeorderhistory .section-tradeorderhistory-list:not(.loading) svg {
    fill: #ddd;
}

body.dark .section-tradeorderhistory-list .tables.with-verify-info .verify-info {
    border-color: #555;
}

body.dark .section-tradeorderhistory-list .tables.with-verify-info .verify-info a:hover{
    background-color: #444;
}

body.dark .section-tradeorderhistory .MuiPaper-root {
    background-color: #212121;
}

body.dark .section-tradeorderhistory .MuiToolbar-root, body.dark .section-tradeorderhistory table.MuiTable-root {
    background-color: #212121;
}

body.dark .section-tradeorderhistory .MuiToolbar-root button {
    color: #ccc;
}

body.dark .section-tradeorderhistory tr.MuiTableRow-root{
    background-color: #212121;
}

body.dark .section-tradeorderhistory tr.Mui-TableBodyCell-DetailPanel, body.dark .section-tradeorderhistory tr.Mui-TableBodyCell-DetailPanel td {
    background-color: #2c2c2c !important;
}

body.dark .section-tradeorderhistory tr.MuiTableRow-root td, body.dark .section-tradeorderhistory tr.MuiTableRow-root th{
    color: #ccc;
    border-color: #656565;
}

body.dark .section-tradeorderhistory tr.MuiTableRow-root td .market img, body.dark .edit-process-dialog .edit-process-form .market-info img {
    border-color: #aaaaaa00;
    background-color: #505050bd;
}

body.dark .edit-process-dialog .finish-process-form {
    border-color: #4c4c4c;
}

body.dark .section-tradeorderhistory tr.MuiTableRow-root{
    color: #ddd;
    border-color: #555;
}

body.dark .section-tradeorderhistory tbody tr.MuiTableRow-root:hover, body.dark .section-tradeorderhistory tbody tr.MuiTableRow-root:hover td{
    background-color: #44444424;
}

body.dark .section-tradeorderhistory tr.MuiTableRow-root span label.warning {
    background-color: #f59e0b0a;
    color: var(--global-yellow-color);
}

body.dark .section-tradeorderhistory tr.MuiTableRow-root span label.danger{
    background-color: #ef444414;
}

body.dark .section-tradeorderhistory tr.MuiTableRow-root span label.success{
    background-color: #22c55e14;
}

body.dark .section-tradeorderhistory tr.MuiTableRow-root .plusicon{
    color: #bbb;
}

body.dark .section-tradeorderhistory tr.MuiTableRow-root .plusicon:hover{
    color: #fff;
}

body.dark .section-tradeorderhistory tr.MuiTableRow-root .checkicon {
    color: #000;
    background-color: #ccc;
}

body.dark .section-tradeorderhistory tr.MuiTableRow-root .checkicon:hover {
    background-color: #228b22;
}

body.dark .section-tradeorderhistory tr.MuiTableRow-root .editicon:hover, body.dark .section-tradeorderhistory tr.MuiTableRow-root .cancelicon:hover {
    background-color: #333;
}

body.dark .section-tradeorderhistory tr.MuiTableRow-root input{
    background-color: #333;
    color: #fff;
}

body.dark .section-tradeorderhistory tr.MuiTableRow-root input.danger {
    border-color: #ff7070;
    box-shadow: 0px 0px 5px #ff7070;
}

body.dark .section-tradeorderhistory tr.MuiTableRow-root input:disabled{
    background-color: #555;
}

body.dark .section-lasttrades .items-title span {
    color: #bbb
}

body.dark .section-lasttrades .item span {
    color: #ddd;
}

body.dark .section-lasttrades .item span.success {
    color: var(--global-success-color);
}

body.dark .section-lasttrades .item span.danger {
    color: var(--global-danger-color);
}

/*========================= çözünürlüğe bağlı olan düzenlemeler */

@media screen and (min-width: 800px){
    .section-tradeorderhistory .processesTable tr th:nth-child(2), .section-tradeorderhistory .processesTable tr td:nth-child(2), .section-tradeorderhistory .processesTable tr th:nth-child(4), .section-tradeorderhistory .processesTable tr td:nth-child(4) {
        width: 8%;
    }
    .section-tradeorderhistory .processesTable tr th:nth-child(3), .section-tradeorderhistory .processesTable tr td:nth-child(3), .section-tradeorderhistory .processesTable tr th:nth-child(8), .section-tradeorderhistory .processesTable tr td:nth-child(8) {
        width: 1%;
    }
    .section-tradeorderhistory .processesTable tr th:nth-child(5), .section-tradeorderhistory .processesTable tr td:nth-child(5) {
        width: 18%;
    }
    .section-tradeorderhistory .processesTable tr th:nth-child(6), .section-tradeorderhistory .processesTable tr td:nth-child(6), .section-tradeorderhistory .processesTable tr th:nth-child(7), .section-tradeorderhistory .processesTable tr td:nth-child(7) {
        width: 10%;
    }
}

@media screen and (max-width: 2415px) and (min-width: 1900px){
    .mobile-column-market .market span {
        font-size: 14px;
    }
}

@media screen and (max-width: 2200px){
    .section-tradecurrencyinfo .section-walletlist-dialog-body .currencies .items .item .item-info {
        flex-direction: column;
        justify-content: start;
        align-items: baseline;
    }
}

@media screen and (max-width: 2000px){
    .section-tradecryptocurrencylist-options i, .section-tradecryptocurrencylist-options span {
        font-size: 12px;
    }
}

@media screen and (max-width: 1900px){
    .section-maincharttop-top-data div{
        margin-left: 4%;
    }
    .trade-sections-top, .trade-sections-bottom{
        display: block;
    }
    .trade-sections-top1, .trade-sections-top2{
        width: 100%;
        margin-right: 0px;
    }
    .section-tradecryptocurrencylist {
        height: auto;
        max-height: 635px;
    }
    .section-tradecryptocurrencylist-table {
        height: auto;
        max-height: 500px;
    }
    .section-tradecryptocurrencylist .section-tradecryptocurrencylist-table .MuiTableContainer-root, .trade-sections .section-tradecryptocurrencylist .section-tradecryptocurrencylist-table .MuiTableContainer-root, .future-sections .section-tradecryptocurrencylist .section-tradecryptocurrencylist-table .MuiTableContainer-root {
        height: 500px;
    }
    .section-tradecryptocurrencylist tr.MuiTableRow-root:last-child td {
        border-bottom: 0px;
    }
}

@media screen and (max-width: 1775px){
    .section-maincharttop-top-title{
        font-size: 20px;
        padding-right: 20px;
        margin-right: 20px;
    }
    .section-maincharttop-top-data label{
        font-size: 20px;
    }
    .section-maincharttop-top-data div span:last-child{
        font-size: 14px;
    }
    .section-tradecurrencyinfo .section-walletlist-dialog-body .button div, .section-tradecurrencyinfo .section-walletlist-dialog-body .button div div {
        min-width: 100%;
        width: 100%;
    }
    .section-tradecurrencyinfo .section-walletlist-dialog-body .button div div {
        justify-content: space-evenly;
    }
    .section-tradecurrencyinfo .section-walletlist-dialog-body .button .button-element {
        width: 135px;
    }
}

@media screen and (max-width: 1600px){
    .section-tradecurrencyinfo .section-walletlist-dialog-body .currencies .items .item {
        width: 49%;
        margin-bottom: 2%;
        margin-right: 2%;
    }
    .section-tradecurrencyinfo .section-walletlist-dialog-body .currencies .items .item:nth-child(3n) {
        margin-right: 2%;
    }
    .section-tradecurrencyinfo .section-walletlist-dialog-body .currencies .items .item:nth-child(2n) {
        margin-right: 0px;
    }
    .section-tradebuysell-inner .leverage-area {
        padding: 15px 5px;
    }
}

@media screen and (max-width: 1550px){
    .section-tradeorderhistory tr.MuiTableRow-root th, .section-tradeorderhistory tr.MuiTableRow-root td {
        font-size: 12px;
        padding: 8px;
    }
    .section-tradeorderhistory tr.Mui-TableBodyCell-DetailPanel, .section-tradeorderhistory tr.Mui-TableBodyCell-DetailPanel td {
        padding: 0px;
    }
    .section-tradeorderhistory .MuiButtonBase-root svg {
        width: 20px;
        height: 20px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root span label {
        width: 90px;
        font-size: 12px;
        padding: 6px 0px;
    }
    .section-tradeorderhistory .processesTable tbody tr.MuiTableRow-root {
        margin-bottom: -52px;
    }
    .section-tradeorderhistory .processesTable tbody tr.Mui-TableBodyCell-DetailPanel {
        margin-bottom: 0px;
    }
    .section-tradeorderhistory .processesHistoryTable tbody tr.MuiTableRow-root {
        margin-bottom: -43px;
    }
    .section-tradeorderhistory .processesHistoryTable tbody tr.Mui-TableBodyCell-DetailPanel {
        margin-bottom: 0px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root td .total-value button {
        margin-left: 3px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root td .total-value svg {
        width: 15px;
        height: 15px;
    }
}

@media screen and (max-width: 1500px){
    .section-tradecurrencyinfo .section-walletlist-dialog-body .button div div {
        justify-content: space-around;
    }
    .section-tradecurrencyinfo .section-walletlist-dialog-body .button .button-element {
        width: 120px;
        font-size: 14px;
    }
    .trade-sections-top1 .bottom {
        display: block;
    }
    .section-tradebuysell {
        width: calc(50% - 4px);
        display: inline-block;
    }
    .section-tradecurrencyinfo {
        width: calc(50% - 4px);
        margin-right: 0px;
        display: inline-block;
    }
    .section-lasttrades {
        width: 100%;
        margin-top: 2px !important;
        height: 605px;
    }
    .section-lasttrades .items {
        height: 480px;
    }
}

@media screen and (max-width: 1350px){
    .section-mainchart{
        height: auto;
    }
    .section-mainchartbottom{
        padding-bottom: 10px;
    }
    .section-tradebuysell, .future-sections .section-tradebuysell {
        height: auto;
    }
    .section-tradecurrencyinfo .section-walletlist-dialog-body .button div div {
        justify-content: space-evenly;
    }
    .section-tradeorderhistory {
        width: 100%;
        margin-right: 0px;
    }
    .section-tradeorderhistory .section-tradeorderhistory-list.loading div div, .submenu-values .loading div div, .section-tradeorderhistory .general-values.loading div div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .section-tradeorderhistory .section-tradeorderhistory-list.loading svg, .section-traderecenttrades-list.loading svg, .section-tradeorderhistory .general-values.loading svg, .submenu-values .loading svg {
        width: 50px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root th, .section-tradeorderhistory tr.MuiTableRow-root td {
        font-size: 10px;
        padding: 8px 5px;
    }
    .section-tradeorderhistory tr.Mui-TableBodyCell-DetailPanel, .section-tradeorderhistory tr.Mui-TableBodyCell-DetailPanel td {
        padding: 0px;
    }
    .section-tradeorderhistory .MuiButtonBase-root svg {
        width: 15px;
        height: 15px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root span label {
        width: 90px;
        font-size: 12px;
        padding: 6px 0px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root td .market img, .edit-process-dialog .edit-process-form .market-info img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
        border-radius: 5px;
        padding: 1px;
    }
    .section-tradeorderhistory .processesTable tbody tr.MuiTableRow-root {
        margin-bottom: -47px;
    }
    .section-tradeorderhistory .processesTable tbody tr.Mui-TableBodyCell-DetailPanel {
        margin-bottom: 0px;
    }
    .section-tradeorderhistory .processesHistoryTable tbody tr.MuiTableRow-root {
        margin-bottom: -36px;
    }
    .section-tradeorderhistory .processesHistoryTable tbody tr.Mui-TableBodyCell-DetailPanel {
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 1200px){
    .section-tradeorderhistory tr.MuiTableRow-root input{
        width: 95%;
    }
}

@media screen and (max-width: 1100px){
    .section-maincharttop-top-data div span:first-child{
        font-size: 10px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root input{
        font-size: 12px;
        padding: 2.5px 0px;
        padding-left: 5px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root .checkicon{
        padding: 3px 4px 3px 4px;
    }
}

@media screen and (max-width: 1000px){
    .trade-sections-top1 .bottom {
        flex-direction: column;
    }
    .section-tradebuysell, .future-sections .section-tradebuysell, .section-tradecurrencyinfo, .section-lasttrades {
        width: 75%;
    }
    .trade-sections .section-tradeorderhistory .MuiTableContainer-root {
        height: 712px;
    }
    .trade-sections .section-tradeorderhistory .tables.with-verify-info .MuiTableContainer-root {
        max-height: 610px;
    }
    .trade-sections .section-tradeorderhistory.future-demo .MuiTableContainer-root {
        height: 731px;
    }
    .trade-sections .section-tradeorderhistory.future-demo .tables.with-verify-info .MuiTableContainer-root {
        max-height: 629px;
    }
    .section-tradecurrencyinfo, .future-sections .section-tradecurrencyinfo {
        height: auto;
        margin-left: 0px;
        margin-top: 2px !important;
    }
    .section-tradebuysell-inner-mainsegment {
        height: auto;
        min-height: 638px;
    }
    .section-tradebuysell-inner-results div{
        flex-direction: column;
        margin-top: 10px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root td .market img, .edit-process-dialog .edit-process-form .market-info img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        border-radius: 7px;
        padding: 3px;
    }
    .section-tradeorderhistory .MuiTableSortLabel-root svg {
        width: 12px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root span label {
        width: 75px;
        font-size: 10px;
        padding: 5px 0px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root span label.noedit {
        margin-left: 26px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root span label.nobuttons{
        margin: 0px 26px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root .editicon, .section-tradeorderhistory tr.MuiTableRow-root .cancelicon{
        padding: 2px 5px;
        margin-top: -3px;
    }
    .section-maincharttop-bottom button{
        font-size: 12px;
    }
    .trade-sections .section-tradeorderhistory .general-values {
        min-height: auto;
    }
    .section-tradeorderhistory .general-values .general-values-inner .values {
        width: 40%;
    }
    .section-tradeorderhistory .general-values span, .section-tradeorderhistory-list .tables .submenu-values span {
        font-size: 9px;
    }
    .section-tradeorderhistory .general-values label {
        font-size: 13px;
    }
    .section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons {
        width: 60%;
    }
    .section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-button1 {
        padding: 10px 15px;
    }
    .section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-button1 i {
        font-size: 12px;
    }
    .section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-button1 span {
        font-size: 11px;
    }
    .section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-button1 .loading div div {
        height: 16px !important;
    }
    .section-tradeorderhistory-list .tables .submenu-values div label {
        font-size: 11px;
    }
    .section-tradeorderhistory-list .tables.with-verify-info .verify-info {
        font-size: 14px;
    }
    .section-tradeorderhistory-list .tables.with-verify-info .verify-info a {
        padding: 8px 50px;
        font-size: 12px;
    }
    .section-tradeorderhistory .processesTable tbody tr.MuiTableRow-root {
        margin-bottom: -36px;
    }
    .section-tradeorderhistory .processesTable tbody tr.Mui-TableBodyCell-DetailPanel {
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 900px){
    .section-tradeorderhistory .general-values label, .section-tradeorderhistory .general-values .info-icon {
        font-size: 14px;
    }
    .section-tradeorderhistory .general-values .credit-info {
        font-size: 10px;
        padding: 10px;
        width: 175px !important;
        height: 95px !important;
        margin-top: 125px;
    }
    .section-tradeorderhistory-options {
        padding: 20px 10px;
    }
    .section-tradeorderhistory-options span {
        font-size: 12px;
        margin-right: 35px;
    }
    .section-tradeorderhistory-list .tables .total-profit {
        font-size: 20px;
    }
    .section-tradeorderhistory-options-select {
        font-size: 12px;
        width: 100px;
        border-radius: 7px;
    }
    .section-tradeorderhistory-options-select-menu {
        right: 10px;
        top: 55px;
        width: 100px;
        border-radius: 7px;
        padding: 5px;
    }
    .section-tradeorderhistory-options-select-menu label {
        border-radius: 7px;
        font-size: 12px;
    }
    .trade-sections .section-tradeorderhistory .MuiTableContainer-root {
        height: 704px;
    }
    .trade-sections .section-tradeorderhistory .tables.with-verify-info .MuiTableContainer-root {
        max-height: 601px;
    }
    .trade-sections .section-tradeorderhistory.future-demo .MuiTableContainer-root {
        height: 725px;
    }
    .trade-sections .section-tradeorderhistory.future-demo .tables.with-verify-info .MuiTableContainer-root {
        max-height: 624px;
    }
}

@media screen and (max-width: 875px){
    .trade-sections-top2{
        width: 100%;
        display: block;
    }
    .trade-sections .back-to {
        margin-top: 44px;
        font-size: 18px;
    }
    .section-maincharttop-top-title{
        font-size: 18px;
        padding-right: 15px;
        margin-right: 15px;
    }
    .section-maincharttop-top-data label{
        font-size: 18px;
    }
    .section-maincharttop-top-data div span:first-child{
        font-size: 9px;
    }
    .section-maincharttop-top-data div span:last-child{
        font-size: 12px;
    }
    .section-mainchartohlc-element {
        justify-content: center;
        align-items: start;
        flex-direction: column;
    }
    .section-mainchartohlc .fa-sort-down{
        margin-top: -20px !important;
    }
    .section-tradebuysell-inner-options {
        display: flex;
        justify-content: start;
    }
    .section-tradebuysell-inner-options span{
        width: 50px;
        font-size: 12px;
    }
    .section-tradebuysell-inner-results div{
        font-size: 13px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root .editicon, .section-tradeorderhistory tr.MuiTableRow-root .cancelicon {
        padding: 0px 4px;
        margin-top: 0px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root span label.nobuttons{
        margin: 0px;
    }
    .section-maincharttop-bottom button {
        margin-right: 10px;
    }
    .section-maincharttop-bottom button:last-child {
        margin-right: 0px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root th, .section-tradeorderhistory tr.MuiTableRow-root td {
        font-size: 9px;
        padding: 8px 3px;
    }
    .section-tradeorderhistory tr.Mui-TableBodyCell-DetailPanel, .section-tradeorderhistory tr.Mui-TableBodyCell-DetailPanel td {
        padding: 0px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root td .total-value button {
        margin-left: 2px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root td .total-value svg {
        width: 12px;
        height: 12px;
    }
}

@media screen and (max-width: 800px){
    .mobile-table tr.MuiTableRow-root th:nth-child(2), .mobile-table tr.MuiTableRow-root td:nth-child(2) {
        padding-left: 10px;
    }
    .withExpandMenu .mobile-table tr.MuiTableRow-root th:nth-child(3), .withExpandMenu .mobile-table tr.MuiTableRow-root td:nth-child(3) {
        padding-left: 10px;
    }
    .section-tradeorderhistory td[colspan] p.MuiTypography-root {
        font-size: 10px;
    }
    .section-tradeorderhistory .processesTable tbody tr.MuiTableRow-root {
        margin-bottom: -82px;
    }
    .section-tradeorderhistory .processesTable tbody tr.Mui-TableBodyCell-DetailPanel {
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 750px){
    .edit-order-dialog{
        width: 95%;
    }
    .section-tradebuysell, .future-sections .section-tradebuysell, .section-tradecurrencyinfo, .section-lasttrades {
        width: 100%;
    }
    .section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-button1 {
        padding: 10px 12px;
    }
}

@media screen and (max-width: 725px){
    .trade-sections .back-to {
        margin-top: 43px;
        font-size: 16px;
        padding-left: 14px;
    }
    .section-maincharttop-top-title{
        font-size: 16px;
    }
    .future-sections .section-maincharttop-top-title {
        padding: 20px 20px;
        margin-right: 20px;
    }
    .section-maincharttop-top-data label{
        font-size: 16px;
    }
    .section-maincharttop-top-data div span:first-child{
        font-size: 8px;
    }
    .section-maincharttop-top-data div span:last-child{
        font-size: 10px;
    }
    .section-maincharttop-bottom button{
        font-size: 10px;
    }
    .section-mainchartohlc-element{
        font-size: 10px;
    }
    .section-mainchartohlc-element .section-mainchartohlc-element1 div:first-child{
        width: 90px;
    }
    .section-tradebuysell-inner-options span{
        width: 45px;
        font-size: 11px;
    }
    .section-tradebuysell-inner-results div{
        font-size: 12px;
    }
    .section-tradeorderhistory-options span{
        margin-right: 25px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root{
        font-size: 12px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root input{
        font-size: 10px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root .checkicon {
        margin-left: -19px;
        font-size: 10px;
        padding: 3px 3px 2px 3px;
    }
    .section-tradeorderhistory .MuiTablePagination-root, .section-tradeorderhistory .MuiTablePagination-selectLabel, .section-tradeorderhistory .MuiTablePagination-displayedRows {
        font-size: 12px;
    }
}

@media screen and (max-width: 675px){
    .section-tradeorderhistory .MuiTableSortLabel-root svg {
        width: 10px;
    }
    .section-maincharttop-bottom button {
        margin-right: 7px;
        font-size: 9px;
    }
    .section-maincharttop-bottom button:last-child {
        margin-right: 0px;
    }
}

@media screen and (max-width: 650px){
    .trade-sections .back-to {
        margin-top: 46px;
    }
    .future-sections .section-maincharttop-top-title .star-icon {
        top: 26px !important;
    }
    .section-tradeorderhistory .general-values label, .section-tradeorderhistory .general-values .info-icon {
        font-size: 12px;
    }
    .section-tradeorderhistory-options {
        height: auto;
        padding: 15px 10px;
    }
    .section-tradeorderhistory-options span{
        font-size: 10px;
        margin-right: 25px;
    }
    .section-tradeorderhistory-options span:last-child {
        margin-right: 0px;
    }
    .section-tradeorderhistory-list .tables .total-profit {
        font-size: 16px;
        padding-top: 15px;
    }
    .section-maincharttop-top{
        justify-content: center;
        align-items: start;
        flex-direction: column;
        padding-left: 15px;
    }
    .section-maincharttop-top-title{
        padding: 10px;
        border-right: 0px;
        width: 100%;
        text-align: center;
        font-size: 20px;
        position: relative;
    }
    .section-maincharttop-top-data label{
        display: block;
    }
    .section-maincharttop-top-data div{
        display: inline-flex;
        margin: 0px;
    }
    .section-tradebuysell-tab-area .buy-sell-tab-area {
        height: 35px;
        font-size: 14px;
    }
    .section-tradebuysell-tab-area .buy-sell-tab-area .buy.active {
        padding-right: 25px;
    }
    .section-tradebuysell-tab-area .buy-sell-tab-area .sell.active {
        padding-left: 25px;
    }
    .section-tradebuysell-inner-mainsegment {
        height: auto;
        min-height: 502px;
    }
    .section-tradebuysell-options span{
        font-size: 12px;
        margin-right: 25px;
    }
    .section-tradebuysell-inner-input input{
        padding: 5px 15px;
        padding-right: 65px;
        font-size: 14px;
    }
    .section-tradebuysell-inner-input-label{
        font-size: 10px;
        right: 20px;
    }
    .section-tradebuysell-inner-input-title {
        font-size: 14px;
    }
    .section-tradebuysell-inner-input2 input{
        padding: 5px 15px;
        padding-right: 65px;
        font-size: 14px;
    }
    .section-tradebuysell-inner-input2 i{
        font-size: 14px;
    }
    .section-tradebuysell-inner-input2 label{
        font-size: 14px;
        margin-left: 40px;
    }
    .section-tradebuysell-inner-input2 label.active {
        margin-top: -32px;
        margin-left: 13px;
        font-size: 10px;
    }
    .section-tradebuysell-inner-options{
        justify-content: space-between;
    }
    .section-tradebuysell-inner-options span{
        width: 23%;
        font-size: 9px;
        margin: 0px;
    }
    .section-tradebuysell-inner-results {
        margin-bottom: 25px;
    }
    .section-tradebuysell-inner-results div{
        font-size: 10px;
    }
    .section-tradebuysell-inner-button{
        font-size: 14px;
        padding: 7px 0px;
    }
    .potential-info {
        font-size: 12px;
        margin-right: 67px;
    }
    .section-orderbook {
        height: auto;
    }
    .section-orderbook-listtitle, .section-orderbook-listtitlecenter, .section-traderecenttrades-listtitle, .section-tradecryptocurrencylist-listtitle, .section-tradecryptocurrencylist-list-element div:first-child i, .section-tradecryptocurrencylist-options i{
        font-size: 10px;
    }
    .section-orderbook-list-element, body.dark .section-traderecenttrades-list-element, .section-tradecryptocurrencylist-list-element, .section-tradecryptocurrencylist-options span{
        font-size: 12px;
    }
    .section-tradecryptocurrencylist-list{
        height: 834px;
    }
    .section-tradeorderhistory-options-select{
        width: 90px;
        font-size: 12px;
        padding: 3px 7px;
    }
    .section-tradeorderhistory-options-select-menu{
        width: 90px;
        font-size: 12px;
        padding: 2px;
        top: 52px;
    }
    .section-tradeorderhistory .section-tradeorderhistory-list.loading svg, .section-traderecenttrades-list.loading svg, .section-tradeorderhistory .general-values.loading svg, .submenu-values .loading svg {
        width: 30px;
    }
    .section-tradeorderhistory .general-values .general-values-inner .values {
        width: 70%;
    }
    .section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons {
        width: 30%;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0px;
    }
    .section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-buttons-inner {
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-button1 {
        width: 100%;
        margin-bottom: 8px;
        padding: 8px 12px;
        border-radius: 10px;
    }
    .section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-buttons-inner:last-child .section-quickactions-button1:last-child {
        margin-bottom: 0px;
    }
    .section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-button1 i {
        margin-right: 8px;
        font-size: 9px;
    }
    .section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-button1 span {
        font-size: 8px;
    }
    .section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-button1 .loading div div {
        height: 9px !important;
        width: 30px !important;
    }
    .section-tradeorderhistory tr.MuiTableRow-root{
        font-size: 10px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root .plusicon{
        margin-left: 5px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root .checkicon {
        margin-left: -15px;
        font-size: 8px;
        padding: 3px 2.5px 1px 2.5px;
    }
    .section-traderecenttrades-list {
        height: 495px;
    }
    .section-maincharttop-top-data {
        justify-content: space-between;
        align-items: start;
    }
    .section-maincharttop-top-data .price {
        display: flex;
        justify-content: start;
        align-items: start;
        flex-direction: column;
        width: 40%;
    }
    .section-maincharttop-top-data .price label, .section-maincharttop-top-data .price div {
        width: 100%;
        margin: 0px;
    }
    .section-maincharttop-top-data .price label {
        font-size: 28px;
        padding-top: 2px;
        padding-bottom: 10px;
    }
    .section-maincharttop-top-data .info {
        display: block;
        width: 50%;
    }
    .section-maincharttop-top-data .info div {
        width: 50%;
    }
    .section-maincharttop-top-data .price span {
        margin-left: 3px;
    }
    .section-maincharttop-top-data .price span p {
        font-size: 12px;
    }
    .edit-process-dialog .edit-process-form .sl-tp div.potential-info {
        margin-left: 12px;
    }
    .edit-order-dialog .edit-order-form .title-area .potential-info {
        margin-left: 10px;
        font-size: 12px;
    }
    .edit-order-dialog .edit-order-form span {
        font-size: 12px;
    }
    .edit-order-dialog .edit-order-form input {
        font-size: 12px;
        padding: 6px 10px;
        padding-right: 55px;
        border-radius: 8px;
    }
    .edit-order-dialog .edit-order-form label:not(.editProcessButtonText) {
        font-size: 12px;
        margin-top: 7px;
        margin-right: 15px;
    }
    .edit-order-dialog .edit-order-form.finish-process-form label:not(.editProcessButtonText) {
        margin-right: 40px;
    }
    .edit-order-dialog .edit-order-form .plusicon {
        margin-left: 10px;
        font-size: 12px;
        margin-top: 10px;
    }
    .edit-order-dialog .edit-order-form button {
        font-size: 14px;
        padding: 7px;
    }
    .p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
        font-size: 16px;
    }
    .future-confirm-dialog {
        width: 95%;
    }
    .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
        font-size: 12px;
    }
    .future-confirm-dialog .p-confirm-dialog-message {
        width: auto;
    }
    .p-dialog .p-dialog-footer button {
        font-size: 12px;
        padding: 5px 10px;
    }
    .section-tradeorderhistory-list .tables .submenu-values2 .details .details-item {
        flex-direction: column;
        align-items: start;
    }
    .section-tradeorderhistory-list .tables .submenu-values2 .details .details-item div {
        margin: 0px;
        margin-bottom: 10px;
        width: 100% !important;
    }
    .section-tradeorderhistory-list .tables .submenu-values2 .details .details-item div:last-child {
        margin: 0px;
    }
    .section-tradecryptocurrencylist-dialog label {
        font-size: 14px;
    }
    .section-tradecryptocurrencylist-dialog .buttons a {
        height: 36px;
        font-size: 14px;
    }
    .trade-sections .section-tradecryptocurrencylist .section-tradecryptocurrencylist-table {
        max-height: 506px;
    }
    .trade-sections .section-tradecryptocurrencylist .section-tradecryptocurrencylist-table .MuiTableContainer-root {
        height: 506px;
    }
    .trade-sections .section-tradeorderhistory .MuiTableContainer-root {
        height: 719px;
    }
    .trade-sections .section-tradeorderhistory.future-demo .MuiTableContainer-root {
        height: 688px;
    }
    .trade-sections .section-tradeorderhistory.future-demo .tables.with-verify-info .MuiTableContainer-root {
        max-height: 580px;
    }
    .future-sections .section-tradecryptocurrencylist .section-tradecryptocurrencylist-table .MuiTableContainer-root {
        height: 505px;
    }
}

@media screen and (max-width: 500px){
    .section-tradeorderhistory-list .tables .total-profit {
        justify-content: start;
    }
    .section-tradeorderhistory-options {
        padding: 10px;
    }
    .section-tradeorderhistory-list .tables .total-profit {
        font-size: 14px;
    }
    .section-mainchartohlc-element{
        font-size: 8px;
    }
    .section-mainchartohlc-element .section-mainchartohlc-element1 div:first-child{
        width: 74px;
    }
    .section-tradecurrencyinfo .section-walletlist-dialog-body .button div div {
        flex-direction: row;
    }
    .section-tradecurrencyinfo .section-walletlist-dialog-body .button .button-element {
        width: 110px;
        font-size: 12px;
    }
    .section-tradecurrencyinfo-header {
        top: 28px;
    }
    .section-tradecurrencyinfo-header img {
        width: 20px;
        height: 20px;
    }
    .section-tradecurrencyinfo-header label {
        font-size: 16px;
    }
    .section-tradecurrencyinfo-header span {
        font-size: 12px;
    }
    .section-tradebuysell-tab-area .buy-sell-tab-area {
        font-size: 10px;
        height: 28px;
    }
    .section-tradebuysell-inner-mainsegment {
        height: auto;
        min-height: 446px;
    }
    .section-tradebuysell-options span {
        font-size: 10px;
        margin-right: 10px;
    }
    .section-tradebuysell-inner-input input {
        padding: 3px 15px;
        padding-right: 45px;
        font-size: 12px;
    }
    .section-tradebuysell-inner-input-label{
        right: 10px;
    }
    .section-tradebuysell-inner-input-title {
        font-size: 12px;
    }
    .section-tradebuysell-inner-input2 input{
        padding: 3px 15px;
        padding-right: 45px;
        font-size: 12px;
    }
    .section-tradebuysell-inner-input2 i{
        font-size: 10px;
    }
    .section-tradebuysell-inner-input2 label{
        font-size: 10px;
        margin-left: 35px;
    }
    .section-tradebuysell-inner-input2 label.active {
        margin-top: -26px;
        margin-left: 12px;
        font-size: 8px;
    }
    .section-tradebuysell-inner-options span {
        width: 22%;
        font-size: 7px;
    }
    .section-tradebuysell-inner-results div {
        font-size: 8px;
    }
    .section-tradebuysell-inner-button {
        font-size: 10px;
        padding: 5px 0px;
    }
    .section-tradebuysell-inner-range .rc-slider-handle {
        width: 14px;
        height: 14px;
        margin-top: -2px;
    }
    .section-tradebuysell-inner-range .rc-slider-dot {
        width: 12px;
        height: 12px;
        bottom: -7px;
    }
    .section-tradebuysell-inner-range .rc-slider-mark-text {
        font-size: 10px;
    }
    .potential-info {
        font-size: 10px;
        margin-right: 47px;
    }
    .section-orderbook-listtitle, .section-orderbook-listtitlecenter, .section-traderecenttrades-listtitle, .section-tradecryptocurrencylist-listtitle, .section-tradecryptocurrencylist-list-element div:first-child i, .section-tradecryptocurrencylist-options i {
        font-size: 9px;
    }
    .section-orderbook-list-element, body.dark .section-traderecenttrades-list-element, .section-tradecryptocurrencylist-list-element, .section-tradecryptocurrencylist-options span {
        font-size: 10px;
    }
    .section-traderecenttrades-list {
        height: 497px;
    }
    .section-tradecryptocurrencylist-search input{
        font-size: 12px;
    }
    .section-tradecryptocurrencylist-search i {
        font-size: 12px;
    }
    .section-tradecryptocurrencylist-options{
        padding: 15px 20px;
    }
    .section-tradecryptocurrencylist-list {
        height: 1125px;
    }
    .section-tradecryptocurrencylist .Mui-TableHeadCell-Content-Wrapper {
        font-size: 10px;
    }
    .section-tradecryptocurrencylist .MuiTableSortLabel-icon {
        width: 15px;
        height: 15px;
    }
    .section-tradecryptocurrencylist-element i {
        font-size: 10px;
    }
    .section-tradeorderhistory-options{
        padding: 20px 10px;
    }
    .section-tradeorderhistory-options span {
        font-size: 8px;
        margin-right: 10px;
    }
    .section-tradeorderhistory-options span:last-child{
        margin: 0px;
    }
    .section-tradeorderhistory-options-select{
        width: 75px;
        font-size: 10px;
    }
    .section-tradeorderhistory-options-select-menu{
        width: 75px;
        font-size: 10px;
        right: 10px;
        top: 50px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root{
        font-size: 8px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root .plusicon{
        margin-left: 4px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root input{
        font-size: 7px;
        padding: 2.5px 0px;
        padding-left: 4px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root .checkicon {
        margin-left: -12px;
        font-size: 6px;
        padding: 2px 2.5px 2px 2.5px;
    }
    .section-tradeorderhistory tr.MuiTableRow-root .cancelicon {
        margin-left: 2px;
    }
    .section-tradeorderhistory .MuiTablePagination-root, .section-tradeorderhistory .MuiTablePagination-selectLabel, .section-tradeorderhistory .MuiTablePagination-displayedRows {
        font-size: 8px;
    }
    .section-tradeorderhistory-list .tables.with-verify-info .verify-info {
        font-size: 12px;
    }
    .section-tradeorderhistory-list .tables.with-verify-info .verify-info a {
        padding: 6px 40px;
        font-size: 10px;
    }
    .section-maincharttop-top-data .info {
        width: 60%;
    }
    .section-maincharttop-top-data .price span p {
        font-size: 11px;
    }
    .section-maincharttop-top-title {
        font-size: 18px;
    }
    .section-maincharttop-top-title .star-icon {
        font-size: 14px;
    }
    .section-tradeorderhistory .general-values .general-values-inner .values {
        width: 60%;
    }
    .section-tradeorderhistory .general-values span, .section-tradeorderhistory-list .tables .submenu-values span {
        font-size: 8px;
    }
    .section-tradeorderhistory .general-values label, .section-tradeorderhistory .general-values .info-icon {
        font-size: 10px;
        margin-left: 3px;
    }
    .section-tradeorderhistory .general-values .credit-info {
        font-size: 8px;
        padding: 7px;
        width: 130px !important;
        height: 78px !important;
        margin-top: 105px;
        margin-left: 27px;
        border-radius: 7px;
    }
    .section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons {
        width: 40%;
    }
    .section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-button1 i {
        margin-right: 5px;
        font-size: 8px;
    }
    .section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-button1 span {
        font-size: 7px;
    }
    .section-tradeorderhistory-list .tables .submenu-values1 {
        width: 275px;
        height: 135px;
        padding-left: 15px;
    }
    .section-tradeorderhistory-list .tables .submenu-values2 {
        width: 100%;
    }
    .section-tradeorderhistory-list .tables .submenu-values2 .details {
        align-items: start;
        justify-content: start;
    }
    .section-tradeorderhistory-list .tables .submenu-values2 .details .details-item {
        padding: 13px 10px;
    }
    .section-tradeorderhistory-list .tables .submenu-values div label {
        font-size: 9px;
    }
    .section-tradeorderhistory-list .tables .submenu-values1 div label {
        margin-left: 3px;
    }
    .edit-order-dialog .edit-order-form button {
        font-size: 12px;
    }
    .edit-order-dialog .edit-order-form .editProcessButtonText {
        margin-top: -30px;
        font-size: 12px;
    }
    .edit-process-dialog .edit-process-form .market-info div:last-child {
        font-size: 15px;
    }
    .section-tradecryptocurrencylist-dialog label {
        font-size: 12px;
    }
    .section-tradecryptocurrencylist-dialog .buttons a {
        height: 32px;
        font-size: 12px;
    }
    .trade-sections .section-tradecryptocurrencylist .section-tradecryptocurrencylist-table {
        max-height: 509px;
    }
    .trade-sections .section-tradecryptocurrencylist .section-tradecryptocurrencylist-table .MuiTableContainer-root {
        height: 509px;
    }
    .trade-sections .section-tradeorderhistory .MuiTableContainer-root {
        height: 733px;
    }
    .trade-sections .section-tradeorderhistory.future-demo .MuiTableContainer-root {
        height: 693px;
    }
    .future-sections .section-tradecryptocurrencylist .section-tradecryptocurrencylist-table .MuiTableContainer-root {
        height: 509px;
    }
    .section-lasttrades {
        height: 440px;
        padding: 15px;
    }
    .section-lasttrades .items {
        height: 350px;
    }
    .section-lasttrades .title {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .section-lasttrades .items-title {
        margin-bottom: 2px;
    }
    .section-lasttrades .items-title span {
        font-size: 10px;
    }
    .section-lasttrades .item {
        padding: 2px 0px;
    }
    .section-lasttrades .items .item:first-child {
        padding-top: 0px;
    }
    .section-lasttrades .items .item:last-child {
        padding-bottom: 0px;
    }
    .section-lasttrades .item span {
        font-size: 9px;
    }
}

@media screen and (max-width: 400px){
    .section-tradeorderhistory tr.MuiTableRow-root .cancelicon {
        margin-left: 1px;
    }
    .section-maincharttop-bottom button {
        font-size: 7px;
    }
    .section-tradecryptocurrencylist .Mui-TableHeadCell-Content-Wrapper {
        font-size: 8px;
    }
    .section-tradecryptocurrencylist-element a, a.section-tradecryptocurrencylist-element {
        font-size: 8px;
    }
    .section-tradecryptocurrencylist-element i {
        font-size: 8px;
    }
    .section-maincharttop-top-data div span:first-child{
        font-size: 6px;
    }
    .section-maincharttop-top-data div span:last-child{
        font-size: 8px;
    }
    .section-maincharttop-top-data .price span p {
        font-size: 9px;
    }
    .section-maincharttop-top-data .price label {
        font-size: 20px;
        padding-top: 6px;
        padding-bottom: 5px;
    }
    .section-maincharttop-top-title {
        font-size: 16px;
    }
    .section-maincharttop-top-title .star-icon {
        font-size: 12px;
        top: 16px;
    }
    .trade-sections .section-tradeorderhistory-options div:first-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 60px);
        padding-right: 10px;
    }
    .section-tradeorderhistory-options span {
        font-size: 7px;
    }
    .section-tradeorderhistory-options-select {
        width: 60px;
        font-size: 8px;
    }
    .section-tradeorderhistory-options-select-menu {
        width: 60px;
    }
    .section-tradeorderhistory-options-select-menu label {
        border-radius: 4px;
        font-size: 8px;
    }
    .section-tradeorderhistory-list .tables .submenu-values1 {
        width: 200px;
        height: 100px;
        padding-left: 10px;
    }
    .section-tradeorderhistory-list .tables .submenu-values2 {
        width: 100%;
    }
    .section-tradeorderhistory-list .tables .submenu-values2 .details .details-item {
        padding: 10px;
    }
    .section-tradeorderhistory-list .tables .submenu-values1 div {
        margin-bottom: 0px;
    }
    .section-tradeorderhistory-list .tables .submenu-values span {
        font-size: 6px;
    }
    .section-tradeorderhistory-list .tables .submenu-values div label {
        font-size: 8px;
    }
    .section-tradeorderhistory-list .tables .submenu-values1 div label {
        margin-left: 2px;
    }
    .mobile-column-market .market img {
        width: 20px !important;
        height: 20px !important;
        padding: 2px !important;
    }
    .mobile-column-market .market span, .mobile-column-price .price, .mobile-column-price .count, .mobile-column-price .total {
        font-size: 8px;
    }
    .mobile-column-market .date {
        font-size: 7px;
    }
    .mobile-column-price .price {
        margin-bottom: 1px;
    }
    .mobile-column-price .total {
        margin-top: 1px;
    }
    .section-tradecryptocurrencylist-options {
        justify-content: space-between;
    }
    .section-tradecryptocurrencylist-options i, .section-tradecryptocurrencylist-options span {
        margin-right: 0px;
    }
    .section-tradeorderhistory .processesTable tbody tr.MuiTableRow-root {
        margin-bottom: -65px;
    }
    .section-tradeorderhistory .processesTable tbody tr.Mui-TableBodyCell-DetailPanel {
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 375px){
    .trade-sections .section-tradeorderhistory.future-demo .MuiTableContainer-root {
        height: 723px;
    }
    .section-tradeorderhistory .general-values .general-values-inner .values {
        width: 70%;
    }
    .section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons {
        width: 30%;
    }
    .section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-button1 {
        padding: 5px;
        border-radius: 8px;
    }
    .section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-button1 i {
        margin-right: 5px;
        font-size: 7px;
    }
    .section-tradeorderhistory .general-values .general-values-inner .section-quickactions-buttons .section-quickactions-button1 span {
        font-size: 6px;
    }
}

@media screen and (max-width: 355px){
    .trade-sections .back-to {
        margin-top: 42px;
    }
    .future-sections .section-maincharttop-top-title .star-icon {
        top: 24px !important;
    }
    .section-tradecurrencyinfo .section-walletlist-dialog-body .button div div {
        justify-content: space-between;
    }
    .section-tradecurrencyinfo .section-walletlist-dialog-body .button .button-element {
        width: 48%;
        font-size: 10px;
        padding: 10px 0px;
    }
    .section-tradebuysell-inner-mainsegment {
        min-height: 452px;
    }
    .section-maincharttop-top-data .price span p {
        font-size: 7px;
    }
    .section-maincharttop-top-data .price label {
        font-size: 16px;
        padding-top: 7px;
    }
    .section-maincharttop-top-title {
        font-size: 14px;
    }
}

@media screen and (max-width: 355px){
    .section-tradebuysell-inner-mainsegment {
        min-height: 467px;
    }
}

@media screen and (max-width: 355px){
    .trade-sections .section-tradeorderhistory.future-demo .MuiTableContainer-root {
        height: 715px;
    }
}
