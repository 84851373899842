/*========================= section-deposit */

.section-deposit{
    padding: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

.section-deposit .must-verify {
    font-family: 'Manrope' !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section-deposit .must-verify .title {
    text-align: center;
    width: 100%;
}

.section-deposit .must-verify .title p {
    color: #999;
    font-weight: 600;
    font-size: 15px;
}

.section-deposit .must-verify a {
    width: 50%;
    margin-top: 50px;
    padding: 12px;
    border-radius: 5px;
    border: 0px;
    background-color: #343434;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: .4s;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.section-deposit .must-verify a:hover {
    background-color: #484848;
}

.section-deposit .tab {
    display: flex;
    justify-content: start;
    align-items: center;
    border-bottom: 3px solid #eee;
    width: 810px;
    margin-bottom: 50px;
}

.section-deposit .tab div {
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .4s;
    border-radius: 7px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-top: 8px;
    padding-bottom: 5px;
    width: 50%;
    color: #444;
}

.section-deposit .tab div:hover {
    background-color: #f7f7f7;
}

.section-deposit .tab div.active {
    background-color: #eee;
}

.section-deposit .tab div:first-child {
    margin-right: 15px;
}

.section-deposit .tab div i {
    font-size: 20px;
    margin-right: 8px;
    color: var(--global-yellow-color);
}

.section-deposit .tab div i.crypto {
    font-size: 24px;
}

.section-deposit i.fa-solid.fa-caret-up {
    padding-top: 5px;
}

.section-deposit .buttons {
    width: 750px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 10px;
}

.section-deposit .buttons a {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    transition: .4s;
    border: 0px;
    font-weight: bold;
    font-family: 'Manrope' !important;
    font-size: 18px;
    letter-spacing: 1px;
    width: 425px;
    height: 60px;
    padding: 18px 0px;
    padding-left: 15px;
    border-radius: 12px;
    cursor: pointer;
    text-decoration: none;
    color: #333;
    background-color: #eee;
    margin-bottom: 20px;
}

.section-deposit .buttons a.disabled {
    opacity: .6;
    cursor: not-allowed;
}

.section-deposit .buttons a:not(.disabled):hover {
    background-color: #ddd;
}

.section-deposit .buttons a .img-area {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
}

.section-deposit .buttons a img {
    width: 25px;
}

.section-deposit .buttons a.banxa img {
    width: 45px;
}

.section-deposit .buttons a.banxa img.banxa-dark {
    display: none;
}

.section-deposit .buttons a.mercuryo img, .section-deposit .buttons a.moonpay img {
    filter: invert(9%) sepia(5%) saturate(5%) hue-rotate(0deg) brightness(95%) contrast(101%);
}

.deposit-form-element {
    display: flex;
    align-items: start;
    justify-content: start;
    margin-top: 85px;
}

.deposit-form-element:first-child {
    margin-top: 0px;
}

.deposit-form-element-title {
    margin-right: 50px;
    font-weight: bold;
    font-size: 24px;
    color: #555;
    background-color: #ddd;
    width: 60px;
    height: 60px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.deposit-form-element-title.active {
    background-color: #f3d8bb;
    color: #935c1e;
}

.deposit-form-element-title div {
    height: 85px;
    width: 3px;
    position: absolute;
    background-color: #ddd;
    display: block;
    margin-top: 180px;
}

.deposit-form-element-title.active div {
    background-color: #f3d8bb;
}

.deposit-form-element-main {
    width: 700px;
    position: relative;
}

.deposit-form-element-main-title {
    font-size: 18px;
    font-weight: 500;
    color: #777;
    margin-top: 35px;
    margin-bottom: 5px;
    padding-top: 16px;
    padding-bottom: 5px;
}

.deposit-form-element-main-title.active {
    color: #333;
    font-size: 24px;
    padding-top: 12px;
    padding-bottom: 0px;
}

.deposit-form-element-main .deposit-form-element-main-title:first-child {
    margin-top: 0px;
}

.deposit-form-element-select {
    width: 100%;
    border: 2px solid #ddd;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;
    height: 50.6px;
}

.deposit-form-element-select .section-deposit-loading {
    width: 100%;
    height: 27px;
}

.deposit-form-element-select .section-deposit-loading div div {
    height: 0px !important;
    width: 50px !important;
}

.deposit-form-element-select-options .options {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding: 0px;
    max-height: 210px;
    overflow: auto;
}

.deposit-form-element-select-options .search-area {
    border: 2px solid #ddd;
    border-radius: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.deposit-form-element-select-options input.search {
    width: 100%;
    padding: 10px 45px;
    padding-top: 11px;
    outline: none;
    transition: .4s;
    border: 0px;
}

.deposit-form-element-select-options .quick-search {
    margin-top: 4px;
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.deposit-form-element-select-options .quick-search .item {
    background-color: #ddd;
    width: 18%;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 0px;
    transition: .4s;
}

.deposit-form-element-select-options .quick-search .item.active, .deposit-form-element-select-options .quick-search .item:hover {
    background-color: #ccc;
}

.deposit-form-element-select-options .quick-search .item img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.deposit-form-element-select-options .quick-search .item span {
    font-weight: 500;
    font-size: 14px;
    color: #444444;
}

.deposit-form-element-select-options i {
    position: absolute;
    left: 30px;
    top: 30px;
    font-size: 18px;
}

.deposit-form-element-select div, .deposit-form-element-select-options .option {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.deposit-form-element-select div img, .deposit-form-element-select-options .option img {
    width: 25px;
    height: 25px;
}

.deposit-form-element-select div span, .deposit-form-element-select-options .option span {
    font-size: 18px;
    font-weight: 500;
    margin: 0px 10px;
}

.deposit-form-element-select.noimg div span {
    margin-left: 0px;
}

.deposit-form-element-select div label, .deposit-form-element-select-options .option label {
    font-size: 18px;
    font-weight: 500;
    color: #777;
    cursor: pointer;
}

.deposit-form-element-select .placeholder {
    color: #777;
}

.deposit-form-element-select-options {
    position: absolute;
    padding: 15px;
    padding-bottom: 5px;
    box-shadow: 0px 0px 15px #aaaaaaa3;
    width: calc(100% - 3px);
    border-radius: 5px;
    margin-left: 2px;
    margin-top: 10px;
    animation-duration: .5s;
    animation-name: open-animation;
    background-color: #fff;
    z-index: 1;
    max-height: 325px;
    overflow: auto;
}

.deposit-form-element-select-options .info {
    padding: 15px;
    margin: 10px;
    background-color: #eee;
    border-radius: 5px;
}

.deposit-form-element-select-options .option {
    padding: 15px 10px;
}

.deposit-form-element-select-options .option span, .deposit-form-element-select-options .option label{
    transition: .4s;
}

.deposit-form-element-select-options .option:hover span, .deposit-form-element-select-options .option.active span {
    color: #ce812b;
}

.deposit-form-element-select-options .option:hover label, .deposit-form-element-select-options .option.active label {
    color: #e7a458;
}

.deposit-form-address {
    width: 100%;
    background-color: transparent;
    min-height: 225px;
    border-radius: 5px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.deposit-form-address.active {
    background-color: #eee;
}

.deposit-form-address-main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    flex-wrap: wrap;
    flex-shrink: 0;
}

.deposit-form-address-qr {
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.deposit-form-address-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0px 25px;
    flex-grow: 1;
    width: calc(100% - 226px);
    overflow: hidden;
}

.deposit-form-address-info label {
    font-weight: 500;
    font-size: 18px;
    color: #666;
}

.deposit-form-address-info span {
    font-size: 19px;
    font-weight: 500;
    color: #333;
    white-space: normal;
    word-break: break-all;
}

.deposit-form-address-main i {
    font-size: 28px;
    color: #555;
    background-color: #ccc;
    padding: 15px;
    border-radius: 5px;
    transition: .4s;
    cursor: pointer;
    flex-shrink: 0;
}

.deposit-form-address-main i:hover {
    color: #333;
    background-color: #bbb;
}

.deposit-form-result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    padding: 0px 5px;
    font-weight: 500;
    font-size: 18px;
    color: #777;
}

.deposit-form-element-button {
    margin-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.deposit-form-element-button button {
    border: 0px;
    background-color: #ddd;
    padding: 10px 75px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 500;
    color: #aaa;
    cursor: pointer;
    transition: .4s;
}

.deposit-form-element-button button.active {
    background-color: var(--global-success-color);
    color: #fff;
}

.deposit-form-element-button button.active:hover {
    background-color: var(--global-success-hover-color);
}

/*========================= koyu tema düzenlemeleri */

body.dark .section-deposit .must-verify a {
    color: #ddd;
}

body.dark .section-deposit .must-verify a:hover {
    background-color: #414141;
}

body.dark .section-deposit .tab {
    border-color: #333;
}

body.dark .section-deposit .tab div {
    color: #ddd;
}

body.dark .section-deposit .tab div:hover {
    background-color: #262626;
}

body.dark .section-deposit .tab div.active {
    background-color: #333;
}

body.dark .section-deposit .buttons a {
    background-color: #333;
    color: #eee;
}

body.dark .section-deposit .buttons a:not(.disabled):hover{
    background-color: #444;
}

body.dark .section-deposit .buttons a.mercuryo img, body.dark .section-deposit .buttons a.moonpay img {
    filter: brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(0%) hue-rotate(0deg) brightness(105%) contrast(90%);
}

body.dark .section-deposit .buttons a.banxa img.banxa-light {
    display: none;
}

body.dark .section-deposit .buttons a.banxa img.banxa-dark {
    display: block;
}

body.dark .deposit-form-element-select, body.dark .deposit-form-element-main input, body.dark .deposit-form-element-select-options .search-area {
    border-color: #444;
    background-color: #333;
    color: #eee;
}

body.dark .deposit-form-element-select-options i {
    color: #999;
}

body.dark .deposit-form-element-select-options .quick-search .item {
    background-color: #555;
}

body.dark .deposit-form-element-select-options .quick-search .item.active, body.dark .deposit-form-element-select-options .quick-search .item:hover {
    background-color: #666;
}

body.dark .deposit-form-element-select-options .quick-search .item span {
    color: #ddd;
}

body.dark .deposit-form-element-title{
    background-color: #444;
    color: #999;
}

body.dark .deposit-form-element-title div{
    background-color: #444;
}

body.dark .deposit-form-element-title.active {
    background-color: #d0ad86;
    color: #774711;
}

body.dark .deposit-form-element-title.active div{
    background-color: #d0ad86;
}

body.dark .deposit-form-element-main-title{
    color: #ccc;
}

body.dark .deposit-form-element-select div label, body.dark .deposit-form-element-select-options .option label{
    color: #bbb;
}

body.dark .deposit-form-element-select-options{
    background-color: #333;
    box-shadow: 0px 0px 10px #ffffff1a;
}

body.dark .deposit-form-element-select-options .info{
    background-color: #444;
}

body.dark .deposit-form-element-select-options .option:hover label, body.dark .deposit-form-element-select-options .option.active label{
    color: #e7a458;
}

body.dark .deposit-form-address.active{
    background-color: #333;
}

body.dark .deposit-form-address-info label{
    color: #999;
}

body.dark .deposit-form-address-info span{
    color: #ddd;
}

body.dark .deposit-form-address-main i{
    color: #999;
    background-color: #444;
}

body.dark .deposit-form-address-main i:hover{
    color: #ddd;
    background-color: #555;
}

body.dark .deposit-form-result{
    color: #aaa;
}

body.dark .deposit-form-element-button button {
    background-color: #444;
    color: #888;
}

body.dark .deposit-form-element-button button.active {
    background-color: var(--global-success-hover-color);
    color: #eee;
}

body.dark .deposit-form-element-button button.active:hover {
    background-color: var(--global-success-color);
}

/*========================= çözünürlüğe bağlı olan düzenlemeler */

@media screen and (max-width: 1375px){
    .section-deposit .tab, .section-deposit .buttons, .deposit-form, .deposit-form-element-main {
        width: 100%;
    }
    .deposit-form-element-title{
        height: 50px;
    }
}

@media screen and (max-width: 1000px){
    .deposit-form-element-result{
        flex-direction: column;
        align-items: start;
    }
    .deposit-form-address-main{
        justify-content: center;
    }
    .deposit-form-address-qr{
        margin-bottom: 40px;
    }
    .deposit-form-address-info{
        width: 85%;
        padding-left: 0px;
    }
    .deposit-form-element-select-options .quick-search .item img {
        width: 10px;
        height: 10px;
        margin-right: 3px;
    }
    .deposit-form-element-select-options .quick-search .item span {
        font-size: 10px;
    }
}

@media screen and (max-width: 800px){
    .section-deposit .must-verify a {
        width: 60%;
    }
    .deposit-form-address-info{
        width: 75%;
    }
}

@media screen and (max-width: 750px){
    .section-deposit{
        padding: 50px;
    }
    .section-deposit .tab div:first-child {
        margin-right: 10px;
    }
    .section-deposit .buttons a {
        font-size: 14px;
        width: 70%;
        height: 55px;
        padding-left: 10px;
    }
    .section-deposit .buttons a .img-area {
        width: 65px;
    }
    .section-deposit .buttons a img {
        width: 20px;
    }
    .section-deposit .buttons a.banxa img {
        width: 35px;
    }
    .deposit-form-element {
        flex-direction: column;
        margin-top: 60px;
    }
    .deposit-form-element-title{
        width: 50px;
        font-size: 20px;
    }
    .deposit-form-element-title div{
        display: none;
    }
    .deposit-form-address-info label{
        font-size: 15px;
    }
    .deposit-form-address-info span{
        font-size: 16px;
    }
    .deposit-form-address-main i{
        font-size: 20px;
    }
}

@media screen and (max-width: 650px){
    .section-deposit .buttons a {
        width: 80%;
    }
    .deposit-form-result{
        flex-direction: column;
        align-items: start;
        justify-content: center;
    }
    .deposit-form-result span:first-child{
        font-size: 17px;
        color: #888;
        margin-bottom: 5px;
    }
}

@media screen and (max-width: 600px){
    .section-deposit {
        padding: 40px;
    }
    .section-deposit .must-verify a {
        width: 75%;
    }
    .section-deposit .tab div {
        font-size: 14px;
    }
    .section-deposit .buttons {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .section-deposit .buttons a {
        width: 90%;
    }
    .deposit-form-element-select div img, .deposit-form-element-select-options .option img {
        width: 20px;
        height: 20px;
    }
    .deposit-form-element-main-title {
        font-size: 16px;
        padding-bottom: 8px;
    }
    .deposit-form-element-main input, .deposit-form-element-select div span, .deposit-form-element-select-options .option span, .deposit-form-element-select div label, .deposit-form-element-select-options .option label {
        font-size: 14px;
    }
    .deposit-form-element-main .address-icon {
        font-size: 16px;
    }
    .deposit-form-address-info {
        width: 65%;
    }
    .deposit-form-element-result label {
        font-size: 14px;
    }
    .deposit-form-element-result span {
        font-size: 16px;
    }
    .deposit-form-element-select-options .info {
        font-size: 12px;
    }
}

@media screen and (max-width: 500px){
    .section-deposit .must-verify a {
        font-size: 14px;
        width: 90%;
    }
    .section-deposit .tab div {
        font-size: 12px;
    }
    .section-deposit .tab div:first-child {
        margin-right: 5px;
    }
    .section-deposit .tab div i, .section-deposit .tab div i.crypto {
        font-size: 16px;
    }
    .section-deposit .buttons {
        margin-top: 10px;
    }
    .section-deposit .buttons a {
        font-size: 12px;
        padding: 15px 0px;
        padding-left: 5px;
        height: 45px;
        margin-bottom: 15px;
    }
    .section-deposit .buttons a .img-area {
        width: 55px;
    }
    .section-deposit .buttons a img {
        width: 15px;
    }
    .section-deposit .buttons a.banxa img {
        width: 30px;
    }
    .deposit-form-element-title {
        width: 40px;
        height: 40px;
        font-size: 16px;
    }
    .deposit-form-element-select-options {
        padding: 10px;
    }
    .deposit-form-element-select-options i {
        top: 27px;
        font-size: 14px;
    }
    .deposit-form-element-select-options .info {
        font-size: 10px;
        padding: 10px;
    }
    .deposit-form-element-select-options input.search {
        padding-left: 40px;
    }
    .withdraw-form-element-select-options .quick-search .item img {
        width: 8px;
        height: 8px;
        margin-right: 2px;
    }
    .withdraw-form-element-select-options .quick-search .item span {
        font-size: 8px;
    }
}

@media screen and (max-width: 450px){
    .section-deposit{
        padding: 30px;
    }
    .deposit-form-element-select-options i {
        font-size: 12px;
    }
    .deposit-form-element-select div img, .deposit-form-element-select-options .option img{
        width: 15px;
        height: 15px;
    }
    .deposit-form-element-main input, .deposit-form-element-select div span, .deposit-form-element-select-options .option span, .deposit-form-element-select div label, .deposit-form-element-select-options .option label{
        font-size: 12px;
    }
    .deposit-form-element-main .address-icon{
        font-size: 14px;
        margin-top: 14px;
        margin-left: -28px;
    }
    .deposit-form-address-info{
        width: 55%;
    }
    .deposit-form-element-main input{
        padding-right: 35px;
    }
    .deposit-form-result{
        font-size: 16px;
    }
    .deposit-form-result span:first-child{
        font-size: 15px;
    }
}

@media screen and (max-width: 400px){
    .section-deposit .must-verify a {
        width: 100%;
    }
    .section-deposit .tab div {
        padding-bottom: 4px;
        font-size: 10px;
    }
}

@media screen and (max-width: 350px){
    .section-deposit .tab div {
        font-size: 8px;
    }
}
