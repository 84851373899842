/*========================= section-support */

.section-support {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.section-support .main-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 600px;
    padding: 100px 0px;
}

.section-support .main-form.low-padding {
    padding-top: 30px;
}

.section-support .main-form .title {
    text-align: center;
    width: 100%;
}

.section-support .main-form .title p {
    color: #999;
    font-weight: 600;
    font-size: 15px;
}

.section-support .main-form .inputs {
    position: relative;
    width: 100%;
}

.section-support .main-form .inputs {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.section-support .main-form .inputs label {
    color: #777;
    font-weight: bold;
    margin-top: 30px;
    padding-left: 1px;
    padding-bottom: 6px;
    width: 100%;
}

.section-support .main-form .inputs input, .section-support .main-form .inputs textarea {
    width: 100% !important;
    padding: 12px 15px;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: #eee;
    outline: none;
    transition: .4s;
}

.section-support .main-form .inputs input.danger, .section-support .main-form .inputs textarea.danger {
    box-shadow: 0px 0px 5px var(--global-danger-color);
}

.section-support .main-form button, .section-support .main-form a {
    width: 100%;
    margin-top: 50px;
    padding: 12px;
    border-radius: 5px;
    border: 0px;
    background-color: #343434;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: .4s;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.section-support .main-form a {
    width: 50%;
}

.section-support .main-form button:hover, .section-support .main-form a:hover {
    background-color: #484848;
}

.section-support .main-form button div div {
    height: 27px !important;
    width: 50px !important;
    display: flex;
    align-items: center;
}

.section-support .main-form span {
    text-align: center;
    margin-top: 10px;
    font-size: 12px;
    width: 50%;
}

/*========================= koyu tema düzenlemeleri */

body.dark .section-support .main-form .title p {
    color: #888;
}

body.dark .section-support .main-form .inputs label {
    color: #aaa;
}

body.dark .section-support .main-form .inputs input, body.dark .section-support .main-form .inputs textarea {
    background-color: #333;
    color: #ddd;
}

/* Webkit tarayıcıları için (örn. Safari ve Chrome) */
body.dark .section-support .main-form .inputs input::placeholder, body.dark .section-support .main-form .inputs textarea::placeholder { 
    color: #888; 
    opacity: 1; /* Safari'de opacity varsayılan olarak 0.54'tür, bu nedenle bunu eklemekte fayda var */
}

/* Mozilla Firefox için */
body.dark .section-support .main-form .inputs input::-moz-placeholder, body.dark .section-support .main-form .inputs textarea::-moz-placeholder {
    color: #888;
    opacity: 1;
}

/* Microsoft Edge ve Internet Explorer 10+ için */
body.dark .section-support .main-form .inputs input::-ms-input-placeholder, body.dark .section-support .main-form .inputs textarea::-ms-input-placeholder {
    color: #888;
}

/* Opera 19+ için */
body.dark .section-support .main-form .inputs input:-ms-input-placeholder, body.dark .section-support .main-form .inputs textarea:-ms-input-placeholder {
    color: #888;
}

body.dark .section-support .main-form button, body.dark .section-support .main-form a {
    color: #ddd;
}

body.dark .section-support .main-form button:hover, body.dark .section-support .main-form a:hover {
    background-color: #414141;
}

/*========================= çözünürlüğe bağlı olan düzenlemeler */

@media screen and (max-width: 900px){
    .section-support .main-form {
        width: 100%;
        padding: 100px;
    }
    .section-support .main-form .title {
        text-align: left;
    }
    .section-support .main-form .title p{
        font-size: 13px;
    }
}

@media screen and (max-width: 800px){
    .section-support .main-form {
        padding: 100px 50px;
    }
    .section-support .main-form a, .section-support .main-form span {
        width: 60%;
    }
}

@media screen and (max-width: 600px){
    .section-support .main-form {
        padding: 100px 50px;
    }
    .section-support .main-form a, .section-support .main-form span {
        width: 75%;
    }
}

@media screen and (max-width: 500px){
    .section-support .main-form {
        padding: 50px;
    }
    .section-support .main-form .inputs label {
        font-size: 13px;
    }
    .section-support .main-form .inputs input, .section-support .main-form .inputs textarea {
        font-size: 13px;
        padding: 8px 10px;
    }
    .section-support .main-form button, .section-support .main-form a {
        font-size: 14px;
    }
    .section-support .main-form a, .section-support .main-form span {
        width: 90%;
    }
    .section-support .main-form span {
        font-size: 10px;
    }
}

@media screen and (max-width: 400px){
    .section-support .main-form {
        padding: 40px 25px;
    }
    .section-support .main-form a, .section-support .main-form span {
        width: 100%;
    }
}
