/*========================= genel görünüm */

.section-notifications{
    padding: 20px;
    width: 100%;
    height: 350px;
    overflow: hidden !important;
}

.section-notifications-title {
    padding-bottom: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboard-sections .section-notifications-title {
    padding-bottom: 25px;
}

.section-notifications-title label{
    font-weight: 600;
    font-size: 18px;
}

.section-notifications-title div {
    padding-right: 5px;
    font-size: 16px;
    display: flex;
    justify-content: end;
    align-items: center;
}

.section-notifications-title div i {
    cursor: pointer;
    transition: .4s;
    color: #555;
}

.section-notifications-title div i:last-child {
    margin-left: 10px;
}

.section-notifications-title div i:hover {
    opacity: .7;
}

.section-notifications .no-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 0px;
}

.section-notifications .no-notification i {
    font-size: 40px;
    opacity: 0.3;
}

.section-notifications .no-notification span {
    margin-top: 20px;
    font-size: 18px;
    opacity: .4;
}

.section-notifications-elements {
    height: 273px;
    overflow: auto;
    padding-bottom: 20px;
}

.section-notifications-date {
    color: #888;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
}

.section-notifications-element {
    border: 1px solid #e8e8e8;
    border-radius: 12px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    background-color: #fff;
    margin-bottom: 15px;
    transition: .4s;
}

.section-notifications-element.clickable, .section-notifications-element.clickable label {
    cursor: pointer;
}

.section-notifications-element.clickable:hover {
    background-color: #f3f3f3;
}

.section-notifications-elements .item:last-child .section-notifications-element{
    margin: 0px;
}

.section-notifications-element .title-area {
    font-weight: bold;
    font-size: 14px;
    color: #555;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.section-notifications-element .title-area .title-right {
    display: flex;
    justify-content: end;
    align-items: center;
}

.section-notifications-element .title-area .title-left .notice {
    width: 5px;
    height: 5px;
    border-radius: 50px;
    background-color: var(--global-danger-color);
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.section-notifications-element .title-area .title-right i {
    cursor: pointer;
    transition: .4s;
}

.section-notifications-element .title-area .title-right i:last-child {
    margin-left: 7px;
}

.section-notifications-element .title-area .title-right i:hover {
    opacity: .7;
}

.section-notifications-element label {
    font-size: 14px;
    color: #333;
}

.section-notifications-elements .loading, .section-notifications-elements .loading div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-notifications-elements .loading div {
    width: 50px;
    height: 50px;
}

/*========================= headerdaki alanın görünümü */

.topsection .topsection-right .notifications-area .section-notifications {
    height: 500px;
    max-height: 500px;
    border: 0px;
    background-color: transparent;
}

.topsection .topsection-right .notifications-area .section-notifications-title {
    padding-bottom: 40px;
}

.topsection .topsection-right .notifications-area .section-notifications-title label {
    font-size: 16px;
}

.topsection .topsection-right .notifications-area .section-notifications-title div {
    padding-right: 10px;
}

.topsection .topsection-right .notifications-area .section-notifications-title div i, .topsection .topsection-right .notifications-area .section-notifications-element .title-area .title-right i {
    font-size: 16px;
    color: #555;
    width: auto;
    height: auto;
    background-color: transparent;
    margin-right: 0px;
    transition: .4s;
}

.topsection .topsection-right .notifications-area .section-notifications-element .title-area .title-right i {
    font-size: 14px;
}

.topsection .topsection-right .notifications-area .section-notifications-elements {
    height: 408px;
    max-height: 408px;
}

.topsection .topsection-right .notifications-area .section-notifications .no-notification i {
    background-color: transparent !important;
    cursor: default;
    font-size: 30px;
}

/*========================= sayfanın görünümü */

.page-notifications .section-notifications {
    height: 730px;
    max-height: 730px;
    border: 0px;
    background-color: transparent;
    padding: 0px;
    margin: 0px;
}

.page-notifications .section-notifications-elements {
    height: 653px;
    max-height: 653px;
    padding: 0px;
}

.page-notifications .section-notifications-title div {
    font-size: 20px;
    padding-right: 10px;
}

/*========================= koyu tema düzenlemeleri */

body.dark .section-notifications-title div i, body.dark .section-notifications-element .title-area .title-right i {
    color: #aaa;
}

body.dark .section-notifications-element {
    background-color: transparent;
    border-color: #444;
}

body.dark .section-notifications-element.clickable:hover {
    background-color: #333;
}

body.dark .section-notifications-element span {
    color: #e8e8e8;
}

body.dark .section-notifications-element label {
    color: #aaa;
}

body.dark .topsection .topsection-right .notifications-area .section-notifications-date {
    color: #aaa;   
}

body.dark .topsection .topsection-right .notifications-area .section-notifications-title div i, body.dark .topsection .topsection-right .notifications-area .section-notifications-element .title-area .title-right i {
    color: #aaa !important;
}

/*========================= çözünürlüğe bağlı olan düzenlemeler */

@media screen and (max-width: 1024px){
    .section-notifications {
        height: 500px;
    }
    .section-notifications-elements {
        height: 401px;
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 820px){
    .section-notifications{
        width: 100%;
    }
}

@media screen and (max-width: 500px){
    .section-notifications-title {
        padding-bottom: 35px;
    }
    .section-notifications-title label {
        font-size: 16px;
    }
    .section-notifications-title div, .page-notifications .section-notifications-title div {
        font-size: 14px;
    }
    .section-notifications-date {
        font-size: 10px;
        margin-bottom: 5px;
    }
    .section-notifications-element {
        padding: 10px;
    }
    .section-notifications-element .title-area {
        font-size: 12px;
        margin-bottom: 5px;
    }
    .section-notifications-element .title-area .title-right i {
        font-size: 10px;
    }
    .section-notifications-element label {
        font-size: 12px;
        color: #333;
    }
    .section-notifications-elements {
        height: 420px;
    }
    .page-notifications .section-notifications-elements {
        height: 671px;
        max-height: 671px;
    }
}
