/*========================= section-verification */

.section-verification {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 75px 0px;
}

.section-verification .section-profile {
    justify-content: center;
    align-items: center;
}

.section-verification .section-verification-steps {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.section-verification .main-form.change-phone {
    width: 25%;
}

.section-verification .main-form.change-phone .button-area {
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-verification .main-form-outer {
    width: 660px;
    border-radius: 12px;
    border: 0px;
    background-color: transparent;
    margin-top: 50px;
    padding: 60px;
    box-shadow: 0px 0px 10px #00000025;
}

.section-verification .form-numbers, .section-verification .form-numbers-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section-verification .form-numbers {
    width: 660px;
    height: 110px;
    margin-top: 10px;
}

.section-verification .form-numbers-inner {
    margin-bottom: 75px;
}

.section-verification .form-numbers .item, .section-verification .form-numbers-inner .item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-weight: bold;
    background-color: var(--verification-default-color);
    color: #555;
    animation: open-animation 1.5s;
    position: relative;
    overflow: hidden;
    transition: .5s;
}

.section-verification .form-numbers .item.clickable, .section-verification .form-numbers-inner .item.clickable {
    cursor: pointer;
}

.section-verification .form-numbers .item, .section-verification .form-numbers-inner .item {
    width: 60px;
    height: 60px;
    font-size: 18px;
}

.section-verification .form-numbers .spacer {
    width: 60%;
    height: 4px;
    border-radius: 50px;
    background-color: var(--verification-default-color);
}

.section-verification .form-numbers-inner .spacer {
    width: 60%;
    height: 2px;
    border-radius: 50px;
    background-color: var(--verification-default-color);
}

.section-verification .form-numbers-inner .spacer2 {
    width: 15%;
}

.section-verification .item.approved {
    background-color: var(--verification-approved-color);
    color: #fff;
}

.section-verification .item.denied {
    background-color: var(--verification-denied-color);
    color: #fff;
}

.section-verification .item.pending {
    background-color: var(--verification-pending-color);
    color: #fff;
}

.section-verification .item:not(.approved, .denied, .pending).clickable:hover {
    background-color: #90909075;
}

.section-verification .item.approved.clickable:hover, .section-verification .item.denied.clickable:hover, .section-verification .item.pending.clickable:hover {
    opacity: 0.8;
}

.section-verification .spacer.approved-approved {
    background-color: var(--verification-approved-color);
}
.section-verification .spacer.denied-denied {
    background-color: var(--verification-denied-color);
}
.section-verification .spacer.pending-pending {
    background-color: var(--verification-pending-color);
}
.section-verification .spacer.approved-denied {
    background-image: linear-gradient(to right, var(--verification-approved-color) 0%, var(--verification-approved-color) 50%, var(--verification-denied-color) 80%, var(--verification-denied-color) 100%);
}
.section-verification .spacer.denied-approved {
    background-image: linear-gradient(to right, var(--verification-denied-color) 0%, var(--verification-denied-color) 50%, var(--verification-approved-color) 80%, var(--verification-approved-color) 100%);
}
.section-verification .spacer.approved-pending {
    background-image: linear-gradient(to right, var(--verification-approved-color) 0%, var(--verification-approved-color) 50%, var(--verification-pending-color) 80%, var(--verification-pending-color) 100%);
}
.section-verification .spacer.pending-approved {
    background-image: linear-gradient(to right, var(--verification-pending-color) 0%, var(--verification-pending-color) 50%, var(--verification-approved-color) 80%, var(--verification-approved-color) 100%);
}
.section-verification .spacer.pending-denied {
    background-image: linear-gradient(to right, var(--verification-pending-color) 0%, var(--verification-pending-color) 50%, var(--verification-denied-color) 80%, var(--verification-denied-color) 100%);
}
.section-verification .spacer.denied-pending {
    background-image: linear-gradient(to right, var(--verification-denied-color) 0%, var(--verification-denied-color) 50%, var(--verification-pending-color) 80%, var(--verification-pending-color) 100%);
}
.section-verification .spacer.approved- {
    background-image: linear-gradient(to right, var(--verification-approved-color) 0%, var(--verification-approved-color) 50%, var(--verification-default-color) 80%, var(--verification-default-color) 100%);
}
.section-verification .spacer.-approved {
    background-image: linear-gradient(to right, var(--verification-default-color) 0%, var(--verification-default-color) 50%, var(--verification-approved-color) 80%, var(--verification-approved-color) 100%);
}
.section-verification .spacer.pending- {
    background-image: linear-gradient(to right, var(--verification-pending-color) 0%, var(--verification-pending-color) 50%, var(--verification-default-color) 80%, var(--verification-default-color) 100%);
}
.section-verification .spacer.-pending {
    background-image: linear-gradient(to right, var(--verification-default-color) 0%, var(--verification-default-color) 50%, var(--verification-pending-color) 80%, var(--verification-pending-color) 100%);
}
.section-verification .spacer.denied- {
    background-image: linear-gradient(to right, var(--verification-denied-color) 0%, var(--verification-denied-color) 50%, var(--verification-default-color) 80%, var(--verification-default-color) 100%);
}
.section-verification .spacer.-denied {
    background-image: linear-gradient(to right, var(--verification-default-color) 0%, var(--verification-default-color) 50%, var(--verification-denied-color) 80%, var(--verification-denied-color) 100%);
}

.section-verification .main-form video {
    width: 100%;
    height: 100%;
    border-radius: 6px;
}

.section-verification .main-form .title {
    text-align: center;
}

.section-verification .main-form .title p {
    color: #999;
    font-weight: 600;
    font-size: 15px;
}

.section-verification .main-form .title.danger p {
    color: var(--global-danger-color);
}

.section-verification .main-form .title.result {
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-verification .main-form .title.waiting {
    color: var(--verification-pending-color);
}

.section-verification .main-form .title.success {
    color: var(--verification-approved-color);
}

.section-verification .main-form .title.result i {
    margin-right: 15px;
    font-size: 24px;
}

.section-verification .main-form .title.result p {
    margin: 0px;
    text-align: left;
    color: #555;
}

.section-verification .main-form .inputs {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.section-verification .main-form .inputs.verification {
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: row;
    margin-top: 50px;
    padding-right: 3px;
}

.section-verification .main-form .inputs.verification input {
    width: 60px;
    height: 60px;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: #eee;
    outline: none;
    text-align: center;
    font-size: 22px;
}

.section-verification .main-form .inputs label {
    color: #777;
    font-weight: bold;
    margin-top: 30px;
    padding-left: 1px;
    padding-bottom: 6px;
    display: block;
}

.section-verification .main-form .inputs .optional-title {
    display: flex;
    justify-content: start;
    align-items: baseline;
    margin-top: 30px;
}

.section-verification .main-form .inputs .optional-title label {
    margin: 0px;
}

.section-verification .main-form .inputs .optional-title span {
    margin-left: 5px;
    font-size: 13px;
}

.section-verification .main-form .inputs.new-password label {
    margin-top: 30px;
}

.section-verification .main-form .inputs:not(.verification) input, .section-verification .main-form .inputs:not(.verification) textarea, .section-verification .main-form .inputs:not(.verification) .fake-input {
    width: 100% !important;
    height: 50px !important;
    padding: 0px 15px;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: #eee;
    outline: none;
    transition: .4s;
    font-size: 18px;
}

.section-verification .main-form .inputs:not(.verification) .fake-input.camera {
    margin-top: 15px;
    margin-bottom: 5px;
}

.section-verification .main-form .inputs:not(.verification) textarea {
    padding: 10px 15px;
}

.section-verification .main-form .file-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section-verification .main-form .inputs:not(.verification) .file-buttons .fake-input, .section-verification .main-form .file-buttons .submit-button {
    margin: 0px;
    width: 49% !important;
    font-size: 16px;
}

.section-verification .main-form .inputs:not(.verification) .fake-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: #ddd;
    color: #333;
    font-weight: bold;
    font-size: 16px;
}

.section-verification .main-form .inputs:not(.verification) .fake-input:hover {
    background-color: #ccc;
}

.section-verification .main-form .inputs:not(.verification) .fake-input span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.section-verification .main-form .inputs:not(.verification) .fake-input i {
    padding-top: 1px;
    margin-left: 15px;
}

.section-verification .main-form .preview {
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 10px #aaa;
    border-radius: 6px;
    margin: 5px 0px;
}

.section-verification .main-form .inputs .other-button {
    margin: 0px;
    margin-top: 5px;
}

.section-verification .main-form .inputs:not(.verification) input.danger, .section-verification .main-form .inputs:not(.verification) textarea.danger, .section-verification .main-form .inputs:not(.verification) button.danger, .section-verification .main-form .inputs:not(.verification) .fake-input.danger {
    box-shadow: 0px 0px 5px var(--global-danger-color);
}

.section-verification .main-form .inputs2 {
    flex-direction: row;
    justify-content: space-between;
    margin: 30px 0px;
}

.section-verification .main-form .inputs2 div {
    width: 49%;
}

.section-verification .main-form .inputs3 div:last-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.section-verification .main-form .inputs3 div:last-child input {
    width: 32% !important;
}

.section-verification .main-form .inputs3 div:last-child span {
    position: absolute;
    margin-top: 1px;
    margin-left: 50%;
    opacity: .6;
    font-size: 18px;
}

.section-verification .main-form .inputs .phone-number-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.section-verification .main-form .inputs .phone-number-area span {
    position: absolute;
    left: 95px;
    font-size: 18px;
    padding-top: 1px;
}

.section-verification .main-form button.submit-button {
    width: 100%;
    height: 51px;
    margin-top: 50px;
    padding: 12px;
    border-radius: 5px;
    border: 0px;
    background-color: #343434;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: .4s;
}

.section-verification .main-form a.submit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 215px;
    height: 51px;
    margin-top: 50px;
    padding: 12px;
    border-radius: 5px;
    border: 0px;
    background-color: #343434;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: .4s;
}

.section-verification .main-form button.submit-button:hover, .section-verification .main-form a.submit-button:hover {
    background-color: #484848;
}

.section-verification .main-form button.submit-button i {
    margin-right: 10px;
}

.section-verification .main-form button.submit-button div div, .section-verification .main-form a.submit-button div div {
    position: absolute;
    width: 85px !important;
    height: 85px !important;
}

.section-verification .main-form button.verification {
    max-width: 575px;
}

.section-verification .main-form .selection-buttons {
    margin-top: 60px;
}

.section-verification .main-form .selection-buttons .item {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 20px;
    background-color: #eee;
    color: #444;
    margin-top: 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: .6s;
    min-height: 70px;
}

.section-verification .main-form .selection-buttons .item i {
    width: 50px;
    font-size: 22px;
    margin-right: 15px;
    padding-top: 1px;
    text-align: center;
}

.section-verification .main-form .selection-buttons .item label {
    font-size: 20px;
    cursor: pointer;
}

.section-verification .main-form .selection-buttons .item div {
    height: 0px;
    padding: 12px 5px;
    margin: 0px;
    display: flex;
    justify-content: start;
}

.section-verification .main-form .selection-buttons .item:not(.disabled):hover {
    background-color: #ddd;
}

.section-verification .main-form .register {
    margin-top: 15px;
    font-size: 14px;
    margin-top: 15px;
    font-size: 14px;
    display: flex;
    justify-content: start;
    align-items: center;
    width: fit-content;
    border: 2px solid #86868675;
    border-radius: 5px;
    padding: 2px 8px;
    transition: .4s;
    cursor: pointer;
}

.section-verification .main-form .register:hover {
    background-color: #86868626;
}

.section-verification .main-form .register label {
    color: #888;
    font-weight: bold;
}

.section-verification .main-form .register a {
    text-decoration: none;
    color: #4c4c4c;
    font-weight: bold;
    cursor: pointer;
}

.section-verification .main-form .register a.locked {
    opacity: .7;
}

.section-verification .main-form .register .timer {
    color: var(--global-success-color);
    font-weight: bold;
    margin-left: 6px;
}

.section-verification .main-form .react-international-phone-input-container {
    width: 100%;
}

.section-verification .main-form .react-international-phone-input-container .react-international-phone-country-selector-button, .section-verification .main-form .choose-country button {
    height: 50px;
    padding: 0px 10px;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: #eee;
    outline: none;
    transition: .4s;
    font-size: 18px;
    margin-right: 2px;
}

.section-verification .main-form .choose-country {
    width: 100%;
}

.section-verification .main-form .choose-country button {
    font-size: 18px !important;
}



.section-verification, .section-verification .main-form, .section-verification .MuiStepLabel-root .step-title, .section-verification .form-numbers .MuiStepper-root .MuiStepLabel-label div {
    font-family: 'Manrope' !important;
}

.section-verification .MuiStepLabel-root {
    transition: .4s;
}

.section-verification .main-form-outer .MuiStepLabel-root svg.MuiSvgIcon-root {
    color: #555;
}

.section-verification .main-form-outer .MuiStepLabel-root.approved svg.MuiSvgIcon-root {
    color: var(--verification-approved-color);
}
.section-verification .main-form-outer .MuiStepLabel-root.denied svg.MuiSvgIcon-root {
    color: var(--verification-denied-color);
}
.section-verification .main-form-outer .MuiStepLabel-root.pending svg.MuiSvgIcon-root {
    color: var(--verification-pending-color);
}

.section-verification .MuiStepLabel-root .step-title {
    font-weight: bold;
    font-size: 16px;
    transition: .4s;
    color: #000;
}

.section-verification .MuiStepLabel-root .step-title.clickable:not(.active) {
    cursor: pointer;
}

.section-verification .MuiStepLabel-root .step-title.clickable:not(.active):hover {
    opacity: .7;
}

.section-verification .MuiStepLabel-root.deactive .step-title, .section-verification .readonly .MuiStepLabel-root.active .step-title {
    color: #555;
}

.section-verification  .MuiStepContent-root {
    padding: 25px 50px;
}

.section-verification .form-numbers .MuiStepper-root {
    width: 100%;
}

.section-verification .form-numbers .MuiStepper-root .MuiStepLabel-label {
    display: flex;
    justify-content: center;
    align-items: start;
}

.section-verification .form-numbers .MuiStepper-root .MuiStepLabel-label div {
    font-weight: bold;
    font-size: 16px;
    width: min-content;
    color: #555;
}

.section-verification .form-numbers .MuiStep-root.active .MuiStepLabel-label div {
    color: #000;
}

.section-verification .form-numbers .MuiStepper-root .MuiStepLabel-label .level {
    position: absolute;
    width: auto;
    top: -30px;
    color: #000;
    font-size: 14px;
}

.section-verification .form-numbers .MuiStepper-root .MuiStepLabel-label .level i {
    margin-right: 6px;
    color: var(--global-yellow-color);
}

.section-verification .main-form-outer .MuiPaper-root {
    background-color: transparent;
}

.section-verification .form-numbers .MuiStep-root .MuiStepLabel-root {
    z-index: 999;
    position: relative;
    transition: .4s;
}

.section-verification .form-numbers .MuiStep-root:not(.active).clickable .MuiStepLabel-root {
    cursor: pointer;
}

.section-verification .form-numbers .MuiStep-root:not(.active).clickable:hover .MuiStepLabel-root {
    opacity: .8;
}

.section-verification .form-numbers .MuiStep-root .MuiStepConnector-root {
    left: calc(-50% + 27px);
    right: calc(50% + 27px);
    top: 27px;
}

.section-verification .form-numbers .MuiStep-root .MuiStepLabel-iconContainer div {
    transition: .4s;
    width: 55px;
    height: 55px;
}

.section-verification .form-numbers .MuiStep-root.active .MuiStepLabel-iconContainer div {
    width: 60px;
    height: 60px;
    margin-top: -2px;
}

.section-verification .form-numbers .MuiStep-root:not(.approved, .denied, .pending, .active) .MuiStepLabel-iconContainer div {
    background-color: #aaa;
}

.section-verification .form-numbers .MuiStep-root:not(.approved, .denied, .pending).active .MuiStepLabel-iconContainer div {
    background-image: linear-gradient(136deg, rgb(116 116 116) 0%, #232323c7 50%, #bbbbbb 100%);
}

.section-verification .form-numbers .MuiStep-root:not(.approved, .denied, .pending).active .MuiStepConnector-line {
    background-image: linear-gradient(136deg, rgb(138 217 136) 0%, var(--verification-approved-color) 50%, #69bd69 100%);
}

.section-verification .form-numbers .MuiStep-root.approved .MuiStepLabel-iconContainer div, .section-verification .form-numbers .MuiStep-root.approved .MuiStepConnector-line {
    background-image: linear-gradient(136deg, rgb(138 217 136) 0%, var(--verification-approved-color) 50%, #69bd69 100%);
}

.section-verification .form-numbers .MuiStep-root.denied .MuiStepLabel-iconContainer div, .section-verification .form-numbers .MuiStep-root.denied .MuiStepConnector-line {
    background-image: linear-gradient(136deg, #fbb6bc 0%, #b90000 50%, #ffa2a2 100%);
}

.section-verification .form-numbers .MuiStep-root.pending .MuiStepLabel-iconContainer div, .section-verification .form-numbers .MuiStep-root.pending .MuiStepConnector-line {
    background-image: linear-gradient(136deg, #ffe000 0%, #ffb500 50%, #ffeec3 100%);
}

.section-verification .readonly .MuiStepContent-root {
    padding: 0px !important;
}

/*========================= koyu tema düzenlemeleri */

body.dark .section-verification .main-form-outer {
    box-shadow: 0px 0px 10px #00000060;
}

body.dark .section-verification .form-numbers .item:not(.approved, .denied, .pending), body.dark .section-verification .form-numbers-inner .item:not(.approved, .denied, .pending) {
    color: #fff;
}

body.dark .section-verification .main-form .title p, body.dark .section-verification .main-form .register label {
    color: #888;
}

body.dark .section-verification .main-form .title.danger p {
    color: var(--global-danger-color);
}

body.dark .section-verification .main-form .inputs label, body.dark .section-verification .main-form .inputs .optional-title span, body.dark .section-verification .main-form .inputs .password-show-hide, body.dark .section-verification .main-form .register a {
    color: #aaa;
}

body.dark .section-verification .main-form .title.result p {
    color: #ccc;
}

body.dark .section-verification .main-form .inputs input, body.dark .section-verification .main-form .inputs textarea, body.dark .section-verification .main-form .react-international-phone-input-container .react-international-phone-country-selector-button, body.dark .section-verification .main-form .react-international-phone-country-selector-dropdown, body.dark .section-verification .main-form .choose-country button, body.dark .section-verification .main-form .choose-country ul {
    background-color: #333;
    color: #ddd;
}

body.dark .section-verification .main-form .react-international-phone-country-selector-dropdown__list-item:hover, body.dark .section-verification .main-form .react-international-phone-country-selector-dropdown__list-item--focused, body.dark .section-verification .main-form .choose-country ul li:hover {
    background-color: #444;
    color: #ddd;
}

body.dark .section-verification .main-form .inputs:not(.verification) .fake-input {
    background-color: #444;
    color: #ddd;
}

body.dark .section-verification .main-form .inputs:not(.verification) .fake-input:hover {
    background-color: #555;
}

body.dark .section-verification .main-form .preview {
    box-shadow: 0px 0px 10px #555;
}

/* Webkit tarayıcıları için (örn. Safari ve Chrome) */
body.dark .section-verification .main-form .inputs input::placeholder { 
    color: #888; 
    opacity: 1; /* Safari'de opacity varsayılan olarak 0.54'tür, bu nedenle bunu eklemekte fayda var */
}

/* Mozilla Firefox için */
body.dark .section-verification .main-form .inputs input::-moz-placeholder {
    color: #888;
    opacity: 1;
}

/* Microsoft Edge ve Internet Explorer 10+ için */
body.dark .section-verification .main-form .inputs input::-ms-input-placeholder {
    color: #888;
}

/* Opera 19+ için */
body.dark .section-verification .main-form .inputs input:-ms-input-placeholder {
    color: #888;
}

body.dark .section-verification .main-form .inputs3 div:last-child span {
    opacity: .4;
}

body.dark .section-verification .main-form button.submit-button, body.dark .section-verification .main-form a.submit-button {
    color: #ddd;
}

body.dark .section-verification .main-form button.submit-button:hover, body.dark .section-verification .main-form a.submit-button:hover {
    background-color: #414141;
}

body.dark .section-verification .main-form .selection-buttons .item {
    background-color: #333;
    color: #ddd;
}

body.dark .section-verification .main-form .selection-buttons .item:not(.disabled):hover {
    background-color: #383838;
}

body.dark .section-verification .form-numbers .MuiStepLabel-root svg.MuiSvgIcon-root {
    background-color: transparent;
}

body.dark .section-verification .MuiStepLabel-root .step-title {
    color: #fff;
}

body.dark .section-verification .MuiStepLabel-root.deactive .step-title, body.dark .section-verification .readonly .MuiStepLabel-root.active .step-title {
    color: #ccc;
}

body.dark .section-verification .form-numbers .MuiStepper-root .MuiStepLabel-label div {
    color: #bbb;
}

body.dark .section-verification .form-numbers .MuiStep-root.active .MuiStepLabel-label div, body.dark .section-verification .form-numbers .MuiStepper-root .MuiStepLabel-label .level {
    color: #ddd;
}

body.dark .section-verification .main-form .inputs .phone-number-area span {
    color: #ddd;
}

/*========================= çözünürlüğe bağlı olan düzenlemeler */

@media screen and (max-width: 1800px){
    .section-verification .main-form.change-phone {
        width: 30%;
    }
}

@media screen and (max-width: 1600px){
    .section-verification .main-form.change-phone {
        width: 35%;
    }
}

@media screen and (max-width: 1400px){
    .section-verification .main-form.change-phone {
        width: 40%;
    }
}

@media screen and (max-width: 1250px){
    .section-verification .main-form.change-phone {
        width: 45%;
    }
}

@media screen and (max-width: 1000px){
    .section-verification .main-form.change-phone {
        width: 50%;
    }
}

@media screen and (max-width: 900px){
    .section-verification {
        padding: 75px 25px 25px 25px;
    }
    .section-verification .main-form.change-phone {
        padding-bottom: 50px;
    }
    .section-verification .main-form-outer, .section-verification .main-form {
        width: 100%;
    }
    .section-verification .form-numbers {
        width: 100%;
    }
    .section-verification .main-form .inputs.verification input {
        width: 60px;
        height: 60px;
        font-size: 22px;
    }
    .section-verification .main-form.change-phone .inputs.verification input {
        width: 55px;
    }
    .section-verification .main-form .title p{
        font-size: 12px;
    }
    .section-verification .MuiStepContent-root {
        padding: 30px;
        padding-right: 10px;
    }
}

@media screen and (max-width: 820px){
    .section-verification.kyc {
        padding-top: 10px;
    }
    .section-verification .main-form.change-phone {
        width: 65%;
    }
}

@media screen and (max-width: 700px){
    .section-verification {
        padding: 50px 25px 25px 25px;
    }
    .section-verification .main-form-outer {
        padding: 60px 30px;
    }
    .section-verification .form-numbers .item, .section-verification .form-numbers-inner .item {
        width: 45px;
        height: 45px;
        font-size: 14px;
    }
    .section-verification .form-numbers .spacer {
        height: 3px;
    }
    .section-verification .main-form .inputs.verification input {
        width: 50px;
        height: 50px;
        font-size: 20px;
    }
    .section-verification .main-form .title.result p {
        font-size: 14px;
    }
    .section-verification .main-form .title.result i {
        margin-right: 10px;
        font-size: 20px;
    }
    .section-verification .MuiStepContent-root {
        padding: 25px;
        padding-right: 5px;
    }
    .section-verification .main-form.change-phone .inputs:not(.verification) span, .section-verification .main-form.change-phone .inputs:not(.verification) input {
        font-size: 14px;
    }
    .section-verification .main-form.change-phone .inputs.verification input {
        width: 50px;
    }
}

@media screen and (max-width: 600px){
    .section-verification .main-form .inputs.verification input {
        width: calc(100% / 7);
        height: 45px;
        font-size: 18px;
    }
    .section-verification .main-form .selection-buttons .item {
        padding: 10px;
        min-height: 55px;
    }
    .section-verification .main-form .selection-buttons .item i, .section-verification .main-form .selection-buttons .item label {
        font-size: 18px;
    }
    .section-verification .main-form.change-phone {
        width: 75%;
    }
}

@media screen and (max-width: 500px){
    .section-verification {
        padding: 50px 15px 15px 15px;
    }
    .section-verification .section-profile {
        justify-content: start;
        padding-top: 10px;
        min-height: 415px;
    }
    .section-verification .main-form-outer {
        padding: 25px 15px;
        margin-top: 25px;
    }
    .section-verification .form-numbers .spacer {
        width: 50%;
    }
    .section-verification .form-numbers-inner .spacer {
        width: 50%;
    }
    .section-verification .form-numbers-inner .spacer2 {
        width: 10%;
    }
    .section-verification .main-form .title {
        text-align: left;
    }
    .section-verification .main-form .inputs.new-password label {
        font-size: 14px;
    }
    .section-verification .main-form .inputs .password-info{
        left: 102px;
        bottom: 163px;
        font-size: 15px;
    }
    .section-verification .main-form button.submit-button, .section-verification .main-form a.submit-button {
        font-size: 12px;
        padding: 0px 8px;
        height: 35px;
    }
    .section-verification .main-form button.submit-button i {
        margin-right: 5px;
    }
    .section-verification .main-form .inputs:not(.verification) .file-buttons .fake-input, .section-verification .main-form .file-buttons .submit-button {
        font-size: 12px;
    }
    .section-verification .main-form .title.result p {
        font-size: 10px;
    }
    .section-verification .main-form .title.result i {
        font-size: 16px;
    }
    .section-verification .main-form .inputs label {
        font-size: 12px;
    }
    .section-verification .main-form .choose-country#info_placeofresidence {
        padding-bottom: 25px;
    }
    .section-verification .main-form .choose-country button, .section-verification .main-form .inputs:not(.verification) input, .section-verification .main-form .inputs:not(.verification) textarea, .section-verification .main-form .inputs:not(.verification) .fake-input {
        height: 35px !important;
        font-size: 12px !important;
    }
    .section-verification .main-form .inputs .optional-title span {
        font-size: 10px;
    }
    .section-verification .main-form .inputs3 div:last-child span {
        font-size: 13px;
    }
    .section-verification .main-form .react-international-phone-input-container .react-international-phone-country-selector-button {
        height: 45px;
    }
    .section-verification .main-form .register {
        margin-top: 10px;
        font-size: 12px;
    }
    .section-verification .main-form .register a {
        margin-left: 5px;
    }
    .section-verification .main-form .selection-buttons {
        margin-top: 50px;
    }
    .section-verification .main-form .selection-buttons .item {
        padding: 10px;
        min-height: 45px;
    }
    .section-verification .main-form .selection-buttons .item i, .section-verification .main-form .selection-buttons .item label {
        font-size: 14px;
    }
    .section-verification .main-form .selection-buttons .item i {
        margin-right: 10px;
    }
    .section-verification .form-numbers .MuiStep-root .MuiStepLabel-iconContainer div {
        width: 40px;
        height: 40px;
    }
    .section-verification .form-numbers .MuiStep-root.active .MuiStepLabel-iconContainer div {
        width: 45px;
        height: 45px;
        margin-top: -2px;
    }
    .section-verification .form-numbers .MuiStep-root .MuiStepConnector-root {
        left: calc(-50% + 0px);
        right: calc(50% + 0px);
        top: 19px;
    }
    .section-verification  .MuiStepContent-root {
        padding: 15px;
        padding-right: 0px;
    }
    .section-verification .form-numbers .MuiStep-root .MuiStepLabel-iconContainer div svg {
        width: 16px;
        height: 16px;
    }
    .section-verification .form-numbers .MuiStepper-root .MuiStepLabel-label {
        margin-top: 10px;
    }
    .section-verification .form-numbers .MuiStepper-root .MuiStepLabel-label div {
        font-size: 10px;
    }
    .section-verification .form-numbers .MuiStepper-root .MuiStepLabel-label .level {
        top: -18px;
        font-size: 8px;
    }
    .section-verification .form-numbers .MuiStepper-root .MuiStepLabel-label .level i {
        margin-right: 3px;
    }
    .section-verification .MuiStepLabel-root .step-title {
        font-size: 10px;
    }
    .section-verification .main-form .title p {
        font-size: 10px;
    }
    .section-verification .main-form-outer .MuiStepLabel-root svg.MuiSvgIcon-root {
        width: 16px;
        height: 16px;
    }
    .section-verification .main-form.change-phone .title {
        text-align: center;
    }
    .section-verification .main-form a.submit-button {
        width: 75%;
    }
    .section-verification .main-form.change-phone .react-international-phone-input-container .react-international-phone-country-selector-button {
        height: 35px;
    }
    .section-verification .main-form.change-phone .inputs.verification input {
        width: 40px;
    }
    .section-verification .main-form .inputs .phone-number-area span {
        left: 65px;
        font-size: 12px;
    }
    .section-verification .main-form.change-phone .inputs:not(.verification) span {
        font-size: 12px;
        padding-top: 0px;
    }
}

@media screen and (max-width: 400px){
    .section-verification .main-form-outer {
        padding: 20px 10px;
    }
    .section-verification .form-numbers .item, .section-verification .form-numbers-inner .item {
        width: 35px;
        height: 35px;
        font-size: 12px;
    }
    .section-verification .form-numbers .spacer {
        width: 45%;
    }
    .section-verification .form-numbers-inner .spacer2 {
        width: 8%;
    }
    .section-verification .main-form .selection-buttons .item i {
        width: 30px;
        margin-right: 10px;
    }
    .section-verification .main-form .selection-buttons .item i, .section-verification .main-form .selection-buttons .item label {
        font-size: 14px;
    }
    .section-verification .main-form .inputs3 div:last-child span {
        margin-left: 54%;
        font-size: 10px;
    }
    .section-verification .main-form .choose-country#info_placeofresidence {
        padding-bottom: 50px;
    }
    .section-verification .main-form.change-phone {
        width: 95%;
    }
    .section-verification .main-form.change-phone .inputs.verification input {
        width: 35px;
    }
    .section-verification .main-form .inputs .phone-number-area span {
        padding-top: 0px;
    }
}

@media screen and (max-width: 300px){
    .section-verification .main-form a.submit-button {
        width: 95%;
    }
}
