/*========================= section-profile */

.section-profile {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
    min-height: 500px;
}

.section-profile .menu {
    width: 20%;
    margin-right: 8px;
    height: 617px;
}

.section-profile .menu .user-img {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 40px 10px;
    border-bottom: 2px solid #eee;
}

.section-profile .menu .user-img .image {
    border-radius: 50px;
    width: 75px;
    height: 75px;
    margin-bottom: 5px;
}

.section-profile .menu .user-img .image img {
    width: 75px;
    display: inline-block;
    border-radius: 50px;
    border: 2px solid transparent;
}

.section-profile .menu .user-img .image.verify{
    background-color: var(--global-success-color);
}
.section-profile .menu .user-img .image.half-verify{
    background-image: linear-gradient(to left, var(--global-success-color) 50%, #bababa 50%);
}
.section-profile .menu .user-img .image.not-verify{
    background-color: var(--global-danger-color);
}

.section-profile .menu .user-img .image .icondiv {
    position: absolute;
    margin-left: 60px;
    margin-top: 58px;
    width: 10px;
    height: 10px;
    background-color: #fff;
}

.section-profile .menu .user-img .image i {
    position: absolute;
    margin-left: 55px;
    margin-top: 55px;
    font-size: 18px;
}

.section-profile .menu .user-img .image i.verify{
    color: var(--global-success-color);
}
.section-profile .menu .user-img .image i.not-verify{
    color: var(--global-danger-color);
}

.section-profile .menu .user-img .level {
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 14px;
    font-weight: 600;
    padding-top: 8px;
}

.section-profile .menu .user-img .level i {
    margin-right: 6px;
    padding-top: 1px;
    color: var(--global-yellow-color);
}

.section-profile .menu .username {
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
}

.section-profile .menu .list-element {
    padding: 15px;
    border-bottom: 2px solid #eee;
    cursor: pointer;
    transition: .4s;
    display: block;
    text-decoration: none;
    color: inherit;
}

.section-profile .menu .list-element:last-child {
    border: 0px;
}

.section-profile .menu .list-element:hover {
    background-color: #fafafa;
}

.section-profile .menu .list-element.active {
    background-color: #f3f3f3;
}

.section-profile .content {
    width: calc(80% - 8px);
    min-height: 617px;
    padding: 35px;
}

.section-profile .content-title {
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 40px;
}



.section-profile .content .informations .item {
    margin-bottom: 10px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.section-profile .content .informations .item:last-child {
    margin-bottom: 0px;
}

.section-profile .content .informations .item.br {
    margin-top: 25px;
}

.section-profile .content .informations .item label {
    margin-right: 4px;
    font-size: 14px;
    font-weight: 600;
}

.section-profile .content .informations .item span {
    display: flex;
}

.section-profile .content .informations .item span img {
    width: 22px !important;
    height: auto !important;
    border-radius: 2px;
    margin-left: 2px;
}

.section-profile .content .informations .item .status-icon {
    margin-left: 2px;
    margin-right: 6px;
    text-decoration: none;
    transition: .4s;
}

.section-profile .content .informations .item .status-icon.success {
    color: var(--verification-approved-color);
}

.section-profile .content .informations .item .status-icon.warning {
    color: var(--verification-pending-color);
}

.section-profile .content .informations .item .status-icon.danger {
    color: var(--verification-denied-color);
}

.section-profile .content .informations .item .status-icon.warning:hover, .section-profile .content .informations .item .status-icon.danger:hover {
    opacity: .8;
}



.section-profile .reference-code-title {
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: 1px solid #aaaaaa50;
    font-weight: bold;
    font-size: 14px;
}

.section-profile .reference-code-info {
    overflow: auto;
}
.section-profile .reference-code-info .item {
    margin-top: 30px;
}

.section-profile .reference-code-info .item:first-child {
    margin: 0px;
}

.section-profile .reference-code-title, .section-profile .reference-code-info .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section-profile .reference-code-info .item label {
    cursor: pointer;
    transition: .4s;
}

.section-profile .reference-code-info .item label:hover {
    opacity: .7;
}

.section-profile .reference-code-title label, .section-profile .reference-code-info .item label {
    display: block;
    text-align: center;
}

.section-profile .reference-code-title label:first-child, .section-profile .reference-code-info .item label:first-child {
    width: 25%;
}

.section-profile .reference-code-title label:last-child, .section-profile .reference-code-info .item label:last-child {
    width: 72%;
}

.section-profile .no-reference-code {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-top: 50px;
    padding-bottom: 80px;
    font-size: 20px;
    opacity: .4;
}



.section-profile .kyc-verification {
    width: 420px;
    min-height: 467px;
}

.section-profile .kyc-verification .tab {
    display: flex;
    justify-content: start;
    align-items: center;
    border-bottom: 3px solid #eee;
    width: max-content;
}

.section-profile .kyc-verification .tab div {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .4s;
    margin-right: 15px;
    border-radius: 7px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-top: 8px;
    padding-bottom: 5px;
    width: 130px;
    color: #444;
}

.section-profile .kyc-verification .tab div:hover {
    background-color: #f7f7f7;
}

.section-profile .kyc-verification .tab div.active {
    background-color: #eee;
}

.section-profile .kyc-verification .tab div:last-child {
    margin-right: 0px;
}

.section-profile .kyc-verification .tab div i {
    font-size: 20px;
    margin-right: 5px;
    color: var(--global-yellow-color);
}

.section-profile .kyc-verification .info {
    width: 100%;
    padding: 0px 5px;
    padding-top: 10px;
}

.section-profile .kyc-verification .info .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.section-profile .kyc-verification .info .item label {
    margin-right: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #555;
}

.section-profile .kyc-verification .info .item span {
    display: flex;
}

.section-profile .kyc-verification .info .needed {
    margin-top: 40px;
    font-weight: 600;
}

.section-profile .kyc-verification .info .needed-info {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
    padding-left: 2px;
}

.section-profile .kyc-verification .info .needed-info i {
    margin-right: 7px;
}

.section-profile .kyc-verification .info .buttons {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}



.section-profile .button {
    text-decoration: none;
    width: 300px;
    display: block;
    padding: 10px 0px;
    text-align: center;
    background-color: #444;
    color: var(--global-yellow-color);
    font-weight: 600;
    border-radius: 5px;
    transition: .4s;
}

.section-profile .button:hover{
    background-color: #555;
}

.section-profile .button i {
    margin-right: 10px;
}

/*========================= koyu tema düzenlemeleri */

body.dark .section-profile .menu .user-img .level, body.dark .section-profile .menu .username {
    color: #ddd;
}

body.dark .section-profile .menu .user-img, body.dark .section-profile .menu .list-element {
    border-color: #444;
}

body.dark .section-profile .menu .list-element:hover {
    background-color: #292929;
}

body.dark .section-profile .menu .list-element.active {
    background-color: #333;
}

body.dark .section-profile .button {
    background-color: #333;
}

body.dark .section-profile .button:hover {
    background-color: #3e3e3e;
}

body.dark .section-profile .content .informations .item, body.dark .section-profile .reference-code-title, body.dark .section-profile .reference-code-info .item {
    color: #ddd;
}

body.dark .section-profile .kyc-verification .tab {
    border-color: #333;
}

body.dark .section-profile .kyc-verification .tab div {
    color: #ddd;
}

body.dark .section-profile .kyc-verification .tab div:hover {
    background-color: #262626;
}

body.dark .section-profile .kyc-verification .tab div.active {
    background-color: #333;
}

body.dark .section-profile .kyc-verification .info .item label {
    color: #bbb;
}

body.dark .section-profile .kyc-verification .info .item span, body.dark .section-profile .kyc-verification .info .needed {
    color: #ddd;
}

body.dark .section-profile .kyc-verification .info .needed-info {
    color: #ccc;
}

/*========================= çözünürlüğe bağlı olan düzenlemeler */

@media screen and (max-width: 1800px){
    .section-profile .menu {
        width: 25%;
    }
    .section-profile .content {
        width: calc(75% - 8px);
    }
}

@media screen and (max-width: 1400px){
    .section-profile .menu {
        width: 30%;
    }
    .section-profile .content {
        width: calc(70% - 8px);
    }
}

@media screen and (max-width: 820px){
    .section-profile .menu {
        width: 35%;
    }
    .section-profile .content {
        width: calc(65% - 8px);
    }
    .section-profile .kyc-verification {
        width: 100%;
    }
    .section-profile .kyc-verification .tab {
        width: 100%;
        justify-content: space-between;
    }
    .section-profile .kyc-verification .tab div {
        width: 33%;
        margin: 0px;
    }
}

@media screen and (max-width: 750px){
    .section-profile {
        flex-direction: column;
    }
    .section-profile .menu {
        width: 100%;
        margin-right: 0px;
    }
    .section-profile .content {
        width: 100%;
        min-height: auto;
    }
}

@media screen and (max-width: 500px){
    .section-profile .menu {
        height: auto;
    }
    .section-profile .menu .user-img {
        padding: 30px 10px;
        border-width: 1px;
    }
    .section-profile .menu .list-element {
        font-size: 12px;
        border-width: 1px;
        padding: 10px 15px;
    }
    .section-profile .menu .user-img .image {
        width: 60px;
        height: 60px;
    }
    .section-profile .menu .user-img .image img {
        width: 60px;
    }
    .section-profile .menu .user-img .image i {
        margin-left: 44px;
        margin-top: 44px;
        font-size: 14px;
    }
    .section-profile .menu .user-img .image .icondiv {
        margin-left: 47px;
        margin-top: 47px;
        width: 8px;
        height: 8px;
    }
    .section-profile .menu .user-img .level {
        font-size: 10px;
    }
    .section-profile .menu .user-img .level i {
        padding-top: 0px;
    }
    .section-profile .menu .username {
        font-size: 14px;
    }
    .section-profile .content {
        padding: 25px;
    }
    .section-profile .content-title {
        font-size: 16px;
        padding-bottom: 30px;
    }
    .section-profile .button {
        width: 200px;
        font-size: 12px;
        padding: 6px 0px;
    }
    .section-profile .content .informations .item.br {
        margin-top: 20px;
    }
    .section-profile .content .informations .item label {
        font-size: 10px;
        margin-right: 3px;
    }
    .section-profile .content .informations .item span {
        display: flex;
        font-size: 12px;
    }
    .section-profile .content .informations .item span img {
        width: 16px !important;
    }
    .section-profile .content .informations .item .status-icon {
        margin-left: 0px;
        margin-right: 4px;
    }
    .section-profile .reference-code-title {
        font-size: 12px;
    }
    .section-profile .reference-code-title label:last-child, .section-profile .reference-code-info .item label:last-child {
        width: 65%;
    }
    .section-profile .reference-code-info .item label {
        font-size: 12px;
    }
    .section-profile .kyc-verification {
        min-height: 400px;
    }
    .section-profile .kyc-verification .tab div {
        font-size: 12px;
    }
    .section-profile .kyc-verification .tab div i {
        font-size: 16px;
    }
    .section-profile .kyc-verification .info .item label {
        font-size: 10px;
    }
    .section-profile .kyc-verification .info .item span {
        font-size: 12px;
    }
    .section-profile .kyc-verification .info .needed {
        font-size: 14px;
    }
    .section-profile .kyc-verification .info .needed-info {
        font-size: 10px;
    }
    .section-profile .kyc-verification .info .needed-info i {
        margin-right: 5px;
    }
}

@media screen and (max-width: 350px){
    .section-profile .content {
        padding: 15px;
    }
    .section-profile .content-title {
        padding-bottom: 25px;
    }
    .section-profile .reference-code-title {
        font-size: 10px;
        margin-bottom: 15px;
    }
    .section-profile .reference-code-title label:last-child, .section-profile .reference-code-info .item label:last-child {
        width: 60%;
    }
    .section-profile .reference-code-info .item {
        margin-top: 15px;
    }
    .section-profile .reference-code-info .item:first-child {
        margin: 0px;
    }
    .section-profile .reference-code-info .item label {
        font-size: 8px;
    }
    .section-profile .kyc-verification .tab div {
        font-size: 10px;
    }
    .section-profile .kyc-verification .info .item span {
        font-size: 10px;
    }
}
